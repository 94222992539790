import createAxios from "./axiosHttpMarconi";
export const errorCourierService = {
  getEnviosError,
  reencolarEnviosError,
  getErrorCourierById,
};


function getEnviosError(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/error_courier`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage
      }`;
  } else {
    urlPath += "?page=0&limit=10";
  }

  urlPath += getFiltersPath(filters);
  return axios.get(urlPath);
}
function getFiltersPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.envio) {
    urlPathFilters += `&envio_id=${filters.envio}`;
  }
  if (filters && filters.estados) {
    urlPathFilters += `&estados=${filters.estados}`;
  }
  if (filters && filters.tipo) {
    urlPathFilters += `&tipo=${filters.tipo}`;
  }
  return urlPathFilters;
}

function reencolarEnviosError(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/error_courier/reencolar`;
  return axios.put(urlPath, body);
}

function getErrorCourierById(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/error_courier/${id}`;
  return axios.get(urlPath);
}
