<template>
  <modal @hide="hideModal" class="modal-importacion">
    <template v-slot:header>
      <h5 class="modal-title">Importar</h5>
    </template>
    <template v-slot:body>
      <div class="contenedor container">
        <div class="row pt-2">
          <el-form
            class="row"
            :model="data"
            :rules="rules"
            ref="uploadForm"
            v-loading="loading"
          >
            <el-form-item prop="tipoImport" v-show="tiposImport.length > 1">
              <el-select
                v-model="data.tipoImport"
                placeholder="Selecciona un tipo de fichero"
                class="select col-12"
              >
                <el-option
                  v-for="item in tiposImport"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              prop="fecha"
              v-if="tiposConFecha.includes(data.tipoImport)"
            >
              <el-date-picker
                format="dd/MM/yyyy"
                v-model="data.fecha"
                :clearable="false"
                placeholder="Fecha inicio vigencia"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item prop="filesList">
              <el-upload
                class="upload-file size-upload mt-2"
                ref="uploaderFiles"
                drag
                :limit="1"
                :action="getUrlUpload()"
                :file-list="data.filesList"
                name="filesList"
                :data="dataForm"
                :headers="headers"
                :before-upload="beforeUpload"
                :auto-upload="false"
                :on-success="successUpload"
                :on-error="errorUpload"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  Arrastre aqu&iacute; o
                  <em>haga click para subir el fichero</em>
                </div>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="hideModal">
        Cancelar
      </button>
      <button type="button" class="btn btn-primary" @click="upload()">
        Importar
      </button>
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/ModalHotakauth.vue";
import { apiServer } from "@/globalVars";
import moment from "moment";

export default {
  name: "Importaciones",
  components: { Modal },
  props: {
    tipo: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    var checkFilesList = (rule, value, callback) => {
      if (this.$refs.uploaderFiles.uploadFiles.length == 0) {
        callback(new Error("Seleccione un archivo"));
      } else {
        callback();
      }
    };

    var checkFecha = (rule, value, callback) => {
      try {
        if (this.tiposConFecha.includes(this.data.tipoImport)) {
          if (this.data.fecha == "" || this.data.fecha == null)
            return callback(new Error("Ingrese fecha requerida."));
          else callback();
        } else callback();
      } catch (e) {
        return callback(new Error("Ingrese fecha requerida."));
      }
    };
    return {
      loading: false,
      tiposImport: [],
      tiposFranquicia: [
        { label: "Clientes", value: "clientes" },
        { label: "Direcciones", value: "direcciones" },
      ],
      tiposCliente: [
        { label: "Traspaso clientes", value: "traspaso_clientes" },
      ],
      tiposTarifas: [
        { label: "Tarifas Base", value: "tarifas_base" },
        { label: "Tarifas Personalizadas", value: "tarifas_personalizadas" },
        { label: "Tarifas Especiales", value: "tarifas_especiales" },
        { label: "Asignar Tarifas a Cliente", value: "tarifas_cliente" },
        {
          label: "Tarifas Nueva Franquicia",
          value: "tarifas_nueva_franquicia",
        },
      ],
      tiposTarifasPartner: [
        { label: "Tarifas Partner", value: "tarifas_partner" },
      ],
      tiposTransito: [{ label: "Transito", value: "transito" }],
      tiposExtraviados: [{ label: "Extraviados", value: "extraviados" }],
      tiposBC: [{ label: "Documentación BC", value: "bc_documentacion" }],
      data: { filesList: [], tipoImport: "", fecha: null },
      headers: {},
      dataForm: {
        data: null,
      },
      rules: {
        tipoImport: [
          {
            required: true,
            message: "Seleccione un tipo de archivo",
            trigger: "blur",
          },
        ],
        filesList: [
          {
            validator: checkFilesList,
            trigger: "change",
          },
          {
            validator: checkFilesList,
            trigger: "blur",
          },
        ],
        fecha: [
          {
            validator: checkFecha,
            trigger: "change",
          },
          {
            validator: checkFecha,
            trigger: "blur",
          },
        ],
      },
      tiposConFecha: [
        "tarifas_especiales",
        "tarifas_personalizadas",
        "tarifas_base",
        "tarifas_partner",
      ],
    };
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.accessToken) {
      this.headers.Authorization = `Bearer ${user.accessToken}`;
    }
    this.checkTipo();
  },
  computed: {},
  methods: {
    checkTipo() {
      switch (this.tipo) {
        case "franquicia":
          this.tiposImport = this.tiposFranquicia;
          break;
        case "tarifas":
          this.tiposImport = this.tiposTarifas;
          break;
        case "tarifas_partner":
          this.tiposImport = this.tiposTarifasPartner;
          break;
        case "transito":
          this.tiposImport = this.tiposTransito;
          break;
        case "extraviados":
          this.tiposImport = this.tiposExtraviados;
          break;
        case "clientes":
          this.tiposImport = this.tiposCliente;
          break;
        case "bc":
          this.tiposImport = this.tiposBC;
          break;
      }

      if (this.tiposImport.length == 1) {
        this.data.tipoImport = this.tiposImport[0].value;
      }
    },
    hideModal() {
      this.$emit("hide");
    },
    upload() {
      this.$refs["uploadForm"].validate((valid) => {
        if (valid) {
          this.$refs.uploaderFiles.submit();
          this.loading = true;
        }
      });
    },
    beforeUpload() {
      let body = {
        tipo: this.data.tipoImport,
      };
      if (this.tiposConFecha.includes(this.data.tipoImport))
        body.fecha = new moment(this.data.fecha).add(12, "hours");
      if (this.id != "" && this.id != null) body.id = this.id;
      this.dataForm.data = JSON.stringify(body);
    },
    successUpload() {
      this.loading = false;
      this.notificacion("Correcto", "Fichero subido correctamente.", "success");
      this.hideModal();
    },
    errorUpload(err) {
      this.loading = false;
      let error = JSON.parse(err.message);
      this.notificacion("Error", error.error, "danger");
    },
    getUrlUpload() {
      const user = JSON.parse(localStorage.getItem("user"));
      return `${apiServer}franquiciadora/${user.extraData.franquiciadoraId}/importaciones`;
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
  },
  watch: {},
};
</script>
<style lang="scss">
.modal-importacion .modal-container {
  width: 700px;
}

.el-upload-dragger {
  width: 100% !important;
}

.el-upload {
  width: 100% !important;
}
</style>
