<template>
  <div class="container-box ps-3 pe-3">
    <div class="row">
      <div class="col-12 p-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Maestros</li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ $route.name }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <actions @triggerEvent="triggerEvent" :acciones="acciones" />
    </div>
    <div class="row pt-2">
      <div class="col-md-5 col-sm-8 p-0">
        <Pagination @paginationChange="paginationChange" v-model="pagination" />
      </div>
      <div class="col"></div>
      <div class="col-md-3 col-sm-2 p-0">
        <resumen-busqueda :filtrosProp="filtersLimpios" />
      </div>
      <div class="col-md-1 col-sm-8 p-0 d-flex flex-row-reverse">
        <filters @onFilterChange="onFilterChange" v-model="filters" />
      </div>
    </div>
    <div class="row pt-2">
      <el-table
        highlight-current-row
        class="table-transito"
        @current-change="handleSelectionChange"
        :data="allTransitos"
        ref="singleTable"
        v-loading="loading"
      >
        <el-table-column label="Servicio" prop="servicio" />
        <el-table-column label="País Origen" prop="paisOrigen" />
        <el-table-column label="País Destino" prop="paisDestino" />
        <el-table-column label="Medidas" prop="medidas" />
        <el-table-column label="Peso" prop="peso" />
        <el-table-column label="Arista Máxima " prop="mediaMaximaArista" />
      </el-table>
    </div>
    <nuevo-editar-transito
      v-if="showNuevoTransito"
      :accion="accionSeleccionada"
      :transitoSeleccionado="selection"
      :idTransito="idTransito"
      @hide="closeNuevoReglaTransito"
      @guardado="getTransito"
    />
    <modal-destruccion
      v-if="showEliminarmodal"
      @accepted="aceptarEliminar"
      @hide="showEliminarmodal = false"
      :titulo="'Eliminar: ' + selection.servicio"
      :mensaje="messageEliminar"
    />
    <UploadModal
      v-if="isVisibleModalImport"
      tipo="transito"
      @hide="uploadSuccess()"
    />
  </div>
</template>
<script>
import { Pagination } from "@/components";
import ResumenBusqueda from "@/components/ResumenBusqueda.vue";
import Filters from "./components/Filters.vue";
import Actions from "@/components/Actions.vue";
import UploadModal from "../../Dashboard/Components/UploadModal.vue";
import NuevoEditarTransito from "./components/NuevoEditarTransito.vue";
import ModalDestruccion from "../../../components/ModalDestruccion.vue";
export default {
  components: {
    Pagination,
    ResumenBusqueda,
    Filters,
    Actions,
    NuevoEditarTransito,
    ModalDestruccion,
    UploadModal,
  },
  props: {},
  data() {
    return {
      accionSeleccionada: "",
      idTransito: "new",
      messageEliminar: "",
      showEliminarmodal: false,
      showNuevoTransito: false,
      isVisibleModalImport: false,
      loading: true,
      allTransitos: null,
      selection: null,
      filters: {
        servicio: "",
        paisOrigen: "",
        paisDestino: "",
      },
      pagination: {
        actualPagina: 0,
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 30, 40, 50],
        totalPages: 0,
        totalItems: 0,
      },
      acciones: [
        {
          name: "nuevo",
          label: "Nuevo",
          evento: "onNuevo",
          icon: "fa-plus-circle",
          activo: true,
        },
        {
          label: "Modificar",
          evento: "onModificar",
          icon: "fa-edit",
          activo: false,
        },
        {
          name: "eliminar",
          label: "Eliminar",
          evento: "onEliminar",
          icon: "fa-times",
          activo: false,
        },
        {
          label: "Importar",
          evento: "onImport",
          icon: "fa-file",
          activo: false,
        },
        {
          label: "Exportar",
          evento: "onDownloadExcel",
          icon: "fa-file-excel-o",
          activo: false,
        },
        {
          label: "Refrescar",
          evento: "onReloadTable",
          icon: "fa-rotate-right",
          activo: false,
        },
      ],
      events: {
        onNuevo: this.onNuevoReglaTransito,
        onReloadTable: this.getTransito,
        onModificar: this.onModificarTransito,
        onEliminar: this.onEliminarTransito,
        onImport: this.showModalImportaciones,
        onDownloadExcel: this.onDownloadExcel,
      },
    };
  },
  created() {},
  mounted() {
    this.getTransito();
  },
  computed: {
    transitoStatus() {
      return this.$store.state.transito.list.status;
    },
    deleteStatus() {
      return this.$store.state.transito.delete.status;
    },
    filtersLimpios() {
      return this.resumeFilters(JSON.parse(JSON.stringify(this.filters)));
    },
    excelStatus() {
      return this.$store.state.transito.excel.status;
    },
  },
  methods: {
    resumeFilters(filters) {
      let filtersCleaned = {};
      if (filters.servicio != "") filtersCleaned.servicio = filters.servicio;
      if (filters.paisOrigen != "")
        filtersCleaned.paisOrigen = filters.paisOrigen;
      if (filters.paisDestino != "")
        filtersCleaned.paisDestino = filters.paisDestino;
      return filtersCleaned;
    },
    onNuevoReglaTransito() {
      this.accionSeleccionada = "nuevo";
      this.idTransito = "new";
      this.showNuevoTransito = true;
    },
    closeNuevoReglaTransito() {
      this.showNuevoTransito = false;
    },
    onModificarTransito() {
      if (this.selection != null) {
        this.accionSeleccionada = "editar";
        this.idTransito = this.selection._id;
        this.showNuevoTransito = true;
      } else {
        this.notificacion("Error", "Seleccione una fila", "warning");
      }
    },
    handleSelectionChange(val) {
      this.selection = val;
    },
    onFilterChange(value) {
      this.filters = value;
      localStorage.setItem("filtersTransito", JSON.stringify(this.filters));
      this.pagination.actualPagina -= this.pagination.currentPage + 1;
      this.pagination.currentPage = 1;
      setTimeout(() => {
        this.getTransito();
      }, 250);
    },
    getTransito() {
      this.$store.dispatch("transito/getTransito", {
        pagination: this.pagination,
        query: this.resumeFilters(this.filters),
      });
      this.loading = true;
    },
    paginationChange(pagination) {
      this.pagination = pagination;
      this.getTransito();
    },
    triggerEvent(eventKey) {
      this.events[eventKey]();
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
    onEliminarTransito() {
      if (this.selection != null) {
        this.messageEliminar = `¿Estás seguro de realizar esta acción?`;
        this.showEliminarmodal = true;
      } else {
        this.notificacion(
          "Advertencia",
          "Por favor seleccione un registro.",
          "warning"
        );
      }
    },
    aceptarEliminar() {
      this.$store.dispatch("transito/deleteTransito", this.selection._id);
      this.loading = true;
    },
    showModalImportaciones() {
      this.isVisibleModalImport = true;
    },
    uploadSuccess() {
      this.isVisibleModalImport = !this.isVisibleModalImport;
      this.getTransito();
    },
    onDownloadExcel() {
      this.loading = true;
      this.$store.dispatch(
        "transito/getReglasTransitoExcel",
        this.resumeFilters(this.filters)
      );
    },
    downloadExcel(blob) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
  watch: {
    transitoStatus(val) {
      if (val.loaded) {
        if (this.$store.state.transito.list.data) {
          this.allTransitos = this.$store.state.transito.list.data.items;
          this.pagination.totalItems =
            this.$store.state.transito.list.data.total;
        }
        this.loading = false;
      }
    },
    deleteStatus(val) {
      if (val.erased) {
        this.notificacion(
          "Correcto!",
          "Se ha borrado correctamente!",
          "success"
        );
        this.showEliminarmodal = false;
        this.getTransito();
      }
      if (val.error) {
        this.notificacion("Error!", "Ha ocurrido un error!", "danger");
      }
      this.loading = false;
    },
    excelStatus(val) {
      if (val.downloaded) {
        this.loading = false;
        this.downloadExcel(this.$store.state.transito.excel.data);
        return;
      }

      if (val.error) {
        this.loading = false;
        this.notificacion("Error!", val.error.error, "danger");
      }
    },
  },
};
</script>
<style scoped>
.table-transito {
  padding-left: 0px;
  padding-right: 0px;
}

.table-transito .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.container-box {
  margin-top: -1.5rem;
  margin-bottom: -22px !important;
}
</style>