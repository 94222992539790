var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", {
    staticClass: "modal-status-cexdevolucion",
    attrs: { loading: _vm.loading },
    on: { hide: _vm.hideModal },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _vm.accion === "editar"
              ? _c("h5", { staticClass: "modal-title" }, [
                  _vm._v(
                    " Modificar: " +
                      _vm._s(_vm.transitoSeleccionado.servicio) +
                      " "
                  ),
                ])
              : _c("h5", { staticClass: "modal-title" }, [_vm._v("Nuevo")]),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "el-form",
              {
                ref: "transitoReferencia",
                attrs: {
                  model: _vm.dataTransito,
                  "label-position": "top",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col-12",
                        attrs: { label: "Servicios", prop: "servicio" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "select",
                            attrs: {
                              filterable: "",
                              placeholder: "Seleccione una opción...",
                            },
                            model: {
                              value: _vm.dataTransito.servicio,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataTransito, "servicio", $$v)
                              },
                              expression: "dataTransito.servicio",
                            },
                          },
                          _vm._l(_vm.allServicios, function (item) {
                            return _c("el-option", {
                              key: item._id,
                              attrs: {
                                label: _vm.getLabel(item),
                                value: item.codigo,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col-6",
                        attrs: { label: "País de Origen", prop: "paisOrigen" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "select",
                            attrs: {
                              filterable: "",
                              placeholder: "Seleccione una opción...",
                            },
                            model: {
                              value: _vm.dataTransito.paisOrigen,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataTransito, "paisOrigen", $$v)
                              },
                              expression: "dataTransito.paisOrigen",
                            },
                          },
                          _vm._l(_vm.allPaises, function (item) {
                            return _c("el-option", {
                              key: item._id,
                              attrs: {
                                label: item.nombre.es,
                                value: item.iso2,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col-6",
                        attrs: { label: "País Destino", prop: "paisDestino" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "select",
                            attrs: {
                              filterable: "",
                              placeholder: "Seleccione una opción...",
                            },
                            model: {
                              value: _vm.dataTransito.paisDestino,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataTransito, "paisDestino", $$v)
                              },
                              expression: "dataTransito.paisDestino",
                            },
                          },
                          _vm._l(_vm.allPaises, function (item) {
                            return _c("el-option", {
                              key: item._id,
                              attrs: {
                                label: item.nombre.es,
                                value: item.iso2,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col",
                        attrs: { label: "Medidas", prop: "medidas" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Medidas" },
                          model: {
                            value: _vm.dataTransito.medidas,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataTransito, "medidas", $$v)
                            },
                            expression: "dataTransito.medidas",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col",
                        attrs: { label: "Peso", prop: "peso" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Peso" },
                          model: {
                            value: _vm.dataTransito.peso,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataTransito, "peso", $$v)
                            },
                            expression: "dataTransito.peso",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col",
                        attrs: {
                          label: "Arista Máxima",
                          prop: "mediaMaximaArista",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Arista Máxima" },
                          model: {
                            value: _vm.dataTransito.mediaMaximaArista,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dataTransito,
                                "mediaMaximaArista",
                                $$v
                              )
                            },
                            expression: "dataTransito.mediaMaximaArista",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.hideModal },
              },
              [_vm._v(" Cerrar ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.aceptar },
              },
              [_vm._v(" Aceptar ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }