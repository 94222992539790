import { apiKeyService } from "@/_services";

export const apiKey = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    new: {
      data: [],
      status: {},
    },
    desactivar:{
      data: [],
      status: {},
    }
  },
  mutations: {
    desactivarApiKeyRequest(state) {
      state.desactivar.status = {
        deleting: true,
      };
    },
    desactivarApiKeyError(state, error) {
      state.desactivar.data = [];
      state.desactivar.status = {
        error,
      };
    },
    desactivarApiKeySuccess(state, data) {
      state.desactivar.data = data;
      state.desactivar.status = {
        deleted: true,
      };
    },
    getApiKeyRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getApiKeyError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getApiKeySuccess(state, data) {
      state.list.data = data;
      state.list.status = {
        loaded: true,
      };
    },
    newApiKeyRequest(state) {
      state.new.status = {
        creating: true,
      };
    },
    newApiKeyError(state, error) {
      state.new.data = [];
      state.new.status = {
        error,
      };
    },
    newApiKeySuccess(state, data) {
      state.new.data = data;
      state.new.status = {
        created: true,
      };
    },
  },
  actions: {
    getApiKey({ commit }, req) {
      commit("getApiKeyRequest");
      apiKeyService
        .getApiKey(req.pagination, req.filters)
        .then((api) => {
          commit("getApiKeySuccess", api);
        })
        .catch((error) => {
          commit("getApiKeyError", error);
        });
    },
    newApiKey({ commit }, body) {
      commit("newApiKeyRequest");
      apiKeyService
        .newApiKey(body)
        .then((api) => {
          commit("newApiKeySuccess", api);
        })
        .catch((error) => {
          commit("newApiKeyError", error);
        });
    },
    desactivarApiKey({ commit }, body) {
      commit("desactivarApiKeyRequest");
      apiKeyService
        .desactivarApiKey(body)
        .then((api) => {
          commit("desactivarApiKeySuccess", api);
        })
        .catch((error) => {
          commit("desactivarApiKeyError", error);
        });
    },
  },
};
