import { createAxios } from "./axiosHttpMarconi";
export const extraviadosService = { getExtraviados, repararExtraviado };

function getExtraviados(pagination, filters) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("franquiciadora"));
  let urlPath = `/franquiciadora/${franquiciadora._id}/extraviados`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters.fecha) {
    urlPath += `&fecha_inicio=${filters.fecha[0]}`;
    urlPath += `&fecha_fin=${filters.fecha[1]}`;
  }
  if (filters.localizador) {
    urlPath += `&localizador=${filters.localizador}`;
  }
  if (filters.destinatario) {
    urlPath += `&destinatario=${filters.destinatario}`;
  }
  if (filters.estados) {
    urlPath += `&estados=${filters.estados}`;
  }
  return axios.get(urlPath);
}

function repararExtraviado(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/extraviado/${request.idExtraviado}`;
  return axios.post(urlPath, request.body);
}
