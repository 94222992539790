var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("filter-base", {
    ref: "filtroBase",
    attrs: { widthFilter: 600 },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "el-form",
              {
                ref: "dataFilter",
                staticClass: "px-4 py-3",
                attrs: { "label-position": "top", model: _vm.localValue },
              },
              [
                _c(
                  "el-form-item",
                  { staticClass: "input-class", attrs: { prop: "codigo" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Código Mobile" },
                      model: {
                        value: _vm.localValue.estadoMobile,
                        callback: function ($$v) {
                          _vm.$set(_vm.localValue, "estadoMobile", $$v)
                        },
                        expression: "localValue.estadoMobile",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "input-class", attrs: { prop: "codigo" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Código Estado" },
                      model: {
                        value: _vm.localValue.estado,
                        callback: function ($$v) {
                          _vm.$set(_vm.localValue, "estado", $$v)
                        },
                        expression: "localValue.estado",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { align: "right" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.search()
                          },
                        },
                      },
                      [_vm._v("Buscar")]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clearFilters()
                          },
                        },
                      },
                      [_vm._v("Limpiar")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }