<template>
  <modal
    @hide="hideModal"
    class="modal-status-cexdevolucion"
    :loading="loading"
  >
    <template v-slot:header>
      <h5 class="modal-title">Nuevo</h5>
    </template>
    <template v-slot:body>
      <el-form
        ref="servicioReferencia"
        :model="dataSeur"
        label-position="top"
        :rules="rules"
      >
        <div class="row">
          <el-form-item label="Servicio" prop="servicio" class="col">
            <el-input
              v-model="dataSeur.servicio"
              placeholder="Servicio"
            ></el-input>
          </el-form-item>
          <el-form-item label="Producto" prop="producto" class="col">
            <el-input
              v-model="dataSeur.producto"
              placeholder="Producto"
            ></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Nombre servicio" prop="nombreServicio" class="col">
            <el-input
              v-model="dataSeur.nombreServicio"
              placeholder="Nombre servicio"
            ></el-input>
          </el-form-item>
          <el-form-item label="Nombre producto" prop="nombreProducto" class="col">
            <el-input v-model="dataSeur.nombreProducto" placeholder="Nombre producto"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="hideModal">
        Cerrar
      </button>
      <button type="button" class="btn btn-primary" @click="aceptar">
        Aceptar
      </button>
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/ModalHotakauth.vue";

export default {
  components: { Modal },
  props: {
    accion: {},
    transitoSeleccionado: {},
  },
  data() {
    return {
      loading: false,
      allServicios: [],
      dataSeur: {
        servicio: "",
        producto: "",
        nombreServicio: "",
        nombreProducto: "",
      },
      rules: {
        servicio: [
          { required: true, message: "Campo obligatorio", trigger: "blur" },
        ],
        producto: [
          { required: true, message: "Campo obligatorio", trigger: "blur" },
        ],
        nombreServicio: [
          { required: true, message: "Campo obligatorio", trigger: "blur" },
        ],
        nombreProducto: [
          { required: true, message: "Campo obligatorio", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getServicios();
  },
  computed: {
    nuevoStatus() {
      return this.$store.state.servicioSeur.nuevo.status;
    },
    servicios() {
      return this.$store.state.servicios.list.status;
    },
  },
  methods: {
    getLabel(item) {
      return item.codigo + " - " + item.descripcion;
    },
    getServicios() {
      this.$store.dispatch("servicios/getServicios", {
        pagination: null,
        query: null,
      });
    },
    hideModal() {
      this.$emit("hide");
    },
    aceptar() {
      this.$refs["servicioReferencia"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch("servicioSeur/nuevoServicioSeur", this.dataSeur);
        }
      });
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
  },
  watch: {
    nuevoStatus(val) {
      if (val.loaded) {
        this.notificacion("Correcto!", "Creado correctamente!", "success");
        this.$emit("guardado");
        this.hideModal();
        this.loading = false;
      }
      if (val.error) {
        this.notificacion("Error!", val.error.error, "danger");
        this.loading = false;
      }
    },
    servicios() {
      if (this.servicios.loaded) {
        if (this.$store.state.servicios.list.data) {
          this.allServicios = this.$store.state.servicios.list.data.items.filter(objeto => objeto.courier === "SEUR");
          this.allServicios.forEach((element) => {
            element.disabled = false;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.modal-status-cexdevolucion .modal-container {
  width: 700px !important;
}
.select {
  width: 100% !important;
}
</style>
