import { borradosService } from "@/_services";
export const borrado = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {}
    },
    delete: {
      data: [],
      status: {}
    },
    update: {
      data: [],
      status: {}
    },
    post: {
      data: [],
      status: {}
    },
  },
  mutations: {
    getBorradosRequest(state) {
      state.list.status = {
        loading: true
      }
    },
    getBorradosSuccess(state, response) {
      state.list.data = response
      state.list.status = {
        loaded: true
      }
    },
    GetBorradosError(state, error) {
      state.list.data = null
      state.list.status = {
        error
      }
    },
    deleteBorradoRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deleteBorradoError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteBorradoSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        deleted: true,
      };
    },
    postBorradoRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postBorradoError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postBorradoSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateBorradoRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateBorradoError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateBorradoSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getBorradoByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getBorradoByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getBorradoByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getBorrados({ commit }, request ) {
      commit('getBorradosRequest');
      borradosService
        .getBorrados(request.pagination,request.query)
        .then((response) => {
          commit('getBorradosSuccess', response);
        })
        .catch((error) => {
          commit('getBorradoError', error);
        });
    },
    deleteBorrado({ commit }, id) {
      commit('deleteBorradoRequest');
      borradosService.deleteBorrado(id)
        .then((Res) => {
          commit('deleteBorradoSuccess', Res);
        })
        .catch((error) => {
          commit('deleteBorradoError', error);
        });
    },
    postBorrado({ commit }, request) {
      commit('postBorradoRequest');
      borradosService.postBorrado(request)
        .then((Res) => {
          commit('postBorradoSuccess', Res);
        })
        .catch((error) => {
          commit('postBorradoError', error);
        });
    },
    updateBorrado({ commit }, request) {
      commit('updateBorradoRequest');
      borradosService.updateBorrado(request)
        .then((Res) => {
          commit('updateBorradoSuccess', Res);
        })
        .catch((error) => {
          commit('updateBorradoError', error);
        });
    },
    getBorradoById({ commit }, id) {
      commit('getBorradoByIdRequest');
      borradosService.getBorradoById(id)
        .then((Res) => {
          commit('getBorradoByIdSuccess', Res);
        })
        .catch((error) => {
          commit('getBorradoByIdError', error);
        });
    },
  },
};
