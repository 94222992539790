import Vue from "vue";
import Vuex from "vuex";

import { user } from "./user.module";
import { usuario } from "./usuario.module";
import { franquicia } from "./franquicia.module";
import { envio } from "./envio.module";
import { envios } from "./envios.module";
import { masivo } from "./masivo.module";
import { cliente } from "./cliente.module";
import { incidencia } from "./incidencia.module";
import { franquiciadora } from "./franquiciadora.module";
import { vistaOperativa } from ".//vistaOperativa.module";
import { notificacion } from "./notificacion.module";
import { parada } from "./parada.module";
import { partner } from "./partner.module";
import { servicios } from "./servicios.module";
import { envioConsultas } from "./envioConsultas.module";
import { puntoRed } from "./puntoRed.module";
import { libretaContacto } from "./libretaContacto.module";
import { rma } from "./rma.module";
import { estado } from "./estado.module";
import { vencimientos } from "./vencimientos.module";
import { formasPago } from "./formasPago.module";
import { tarifa } from "./tarifa.module";
import { valoracion } from "./valoracion.module";
import { planesCombustible } from "./planesCombustible.module";
import { estados } from "./estados.module";
import { estadosParadas } from "./estadosParadas.module";
import { extraviados } from "./extraviados.module";
import { fechaCierre } from "./fechaCierre.module";
import { errorCourier } from "./errorCourier.module";
import { apiKey } from "./apiKey.module";
import { ficherosSorter } from "./ficherosSorter.module";
import { plataforma } from "./plataformaIntegracion.module";
import { tarifaCliente } from "./tarifaCliente.module";
import { suplemento } from "./suplemento.module";
import { tarifaBase } from "./tarifaBase.module";
import { notificacionIntegracion } from "./integracion.module";
import { gestionConfig } from "./gestionConfiguracion.module";
import { evento } from "./eventosMobile.module";
import { geografico } from "./geografico.module";
import { transito } from "./transito.module";
import { pais } from "./pais.module";
import { servicioSeur } from "./serviciosSeur.module";
import { borrado } from "./borrados.module";
import { mobileQueue } from "./mobileQueue.module";
import { operativaVistas } from "./operativasControl.module";
import { enviosVistas } from "./enviosVistas.module";
import { repartidorEventos } from "./repartidores.module";
import { estadosParadaEnvio } from "./estadosParadaEnvio.module";
import { otrosMasivos } from "./otrosMasivos.module";
import { enviosAgrupados } from "./enviosAgrupados.module";
import { documentacion } from "./documentacion.module";
import { impuestos } from "./impuestos.module";
import { roles } from "./roles.module";
import { notificacionesDashboard } from "./notificacionesDashboard.module";
import { solucion } from "./soluciones.module";
import { solucionRegla } from "./solucionesReglas.module";
import { agrupacionesFranquicia } from "./agrupaciones.module";
import { agrupacionesCliente } from "./agrupacionesCliente.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    user,
    usuario,
    franquicia,
    envio,
    envios,
    masivo,
    cliente,
    incidencia,
    franquiciadora,
    vistaOperativa,
    notificacion,
    parada,
    partner,
    servicios,
    envioConsultas,
    puntoRed,
    libretaContacto,
    rma,
    vencimientos,
    formasPago,
    tarifa,
    estado,
    valoracion,
    planesCombustible,
    estados,
    estadosParadas,
    extraviados,
    fechaCierre,
    errorCourier,
    apiKey,
    ficherosSorter,
    plataforma,
    tarifaCliente,
    suplemento,
    tarifaBase,
    notificacionIntegracion,
    geografico,
    gestionConfig,
    evento,
    transito,
    pais,
    servicioSeur,
    borrado,
    mobileQueue,
    operativaVistas,
    enviosVistas,
    repartidorEventos,
    estadosParadaEnvio,
    otrosMasivos,
    enviosAgrupados,
    documentacion,
    impuestos,
    roles,
    notificacionesDashboard,
    solucion,
    solucionRegla,
    agrupacionesFranquicia,
    agrupacionesCliente,
  },
});
