import createAxios from "./axiosHttpMarconi";
export const libretaContactoService = {
  getDireccionHabitualByCliente,getDireccionHabitualByClienteExcel, newContactoDireccion
};

function newContactoDireccion(clienteId,body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/cliente/${clienteId}/contacto`;
  return axios.post(urlPath, body);
}

function getDireccionHabitualByCliente(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/cliente/${body.clienteId}/contacto`;
  if (body.pagination) {
    urlPath += `?page=${body.pagination.currentPage - 1}&limit=${
      body.pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath+=getFiltersPath(body);
  return axios.get(urlPath);
}

function getDireccionHabitualByClienteExcel(body){
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/cliente/${body.clienteId}/contacto/excel`;
  if (body.pagination) {
    urlPath += `?page=${body.pagination.currentPage - 1}&limit=${body.pagination.perPage}`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath+=getFiltersPath(body);
  return axios.get(urlPath,{
    responseType: "blob",
  });
}

function getFiltersPath(body){
  let urlPath = "";
  if (body.tipo) {
    urlPath += `&tipo=${body.tipo}`;
  }
  if (body.defecto) {
    urlPath += `&defecto=${body.defecto}`;
  }
  if (body.contacto) {
    urlPath += `&contacto=${body.contacto}`;
  }
  if (body.direccion) {
    urlPath += `&direccion=${body.direccion}`;
  }
  if (body.normalizada != null) {
    urlPath += `&normalizada=${body.normalizada}`;
  }
  return urlPath;
}
