import { gestionConfiguracionService } from "@/_services";

export const gestionConfig = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    new: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    }
  },
  mutations: {
    getGestionConfiguracionRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getGestionConfiguracionError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    getGestionConfiguracionSuccess(state, list) {
      state.list.data = list;
      state.list.status = {
        loaded: true,
      };
    },
    newGestionConfiguracionRequest(state) {
      state.new.status = {
        creating: true,
      };
    },
    newGestionConfiguracionaError(state, error) {
      state.new.data = null;
      state.new.status = {
        error,
      };
    },
    newGestionConfiguracionSuccess(state, franquiciaCreada) {
      state.new.data = franquiciaCreada;
      state.new.status = {
        created: true,
      };
    },
    updateGestionConfiguracionRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateGestionConfiguracionaError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateGestionConfiguracionSuccess(state, franquiciaCreada) {
      state.update.data = franquiciaCreada;
      state.update.status = {
        updated: true,
      };
    },
    deleteGestionConfiguracionRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deleteGestionConfiguracionaError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteGestionConfiguracionSuccess(state, franquiciaCreada) {
      state.delete.data = franquiciaCreada;
      state.delete.status = {
        deleted: true,
      };
    },
  },
  actions: {
    getGestionConfiguracion({ commit }, req) {
      commit("getGestionConfiguracionRequest");
      gestionConfiguracionService
        .getGestionConfiguracion(req.pagination, req.filters)
        .then((list) => {
          commit("getGestionConfiguracionSuccess", list);
        })
        .catch((error) => {
          commit("getGestionConfiguracionError", error);
        });
    },
    nuevaGestionConfiguracion({ commit }, body) {
      commit("newGestionConfiguracionRequest");
      gestionConfiguracionService
        .nuevaGestionConfiguracion(body)
        .then((list) => {
          commit("newGestionConfiguracionSuccess", list);
        })
        .catch((error) => {
          commit("newGestionConfiguracionaError", error);
        });
    },
    updateGestionConfiguracion({ commit }, req) {
      commit("updateGestionConfiguracionRequest");
      gestionConfiguracionService
        .updateGestionConfiguracion(req.body, req.idConfiguracion)
        .then((list) => {
          commit("updateGestionConfiguracionSuccess", list);
        })
        .catch((error) => {
          commit("updateGestionConfiguracionaError", error);
        });
    },
    deleteGestionConfiguracion({ commit }, id) {
      commit("deleteGestionConfiguracionRequest");
      gestionConfiguracionService
        .deleteGestionConfiguracion(id)
        .then((list) => {
          commit("deleteGestionConfiguracionSuccess", list);
        })
        .catch((error) => {
          commit("deleteGestionConfiguracionaError", error);
        });
    },
  },
};
