import createAxios from "./axiosHttpMarconi";
export const clienteService = {
  getAllClientes,
  loadCliente,
  getRMAFile,
  getFichaCliente,
  updateCliente,
  sendInvitaciones,
  traspasarClientes,
  getClientesAsExcel,
};

function getAllClientes(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let parametros = "";
  if (pagination) {
    parametros += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    parametros += "?page=0&limit=10";
  }
  if (filters.nombre && filters.nombre != "") {
    parametros += "&nombre=" + filters.nombre;
  }
  if (filters.alias && filters.alias != "") {
    parametros += "&alias=" + filters.alias;
  }
  if (filters.codigo && filters.codigo != "") {
    parametros += "&codigo=" + filters.codigo;
  }
  if (filters.idFiscal && filters.idFiscal != "") {
    parametros += "&idFiscal=" + filters.idFiscal;
  }
  if (filters && filters.activo) {
    parametros += `&activado=${filters.activo}`;
  }

  if (filters && filters.franquiciaId) {
    parametros += `&franquiciaId=${filters.franquiciaId}`;
  }

  if (filters.codigoPostal && filters.codigoPostal != "") {
    parametros += "&codigoPostal=" + filters.codigoPostal;
  }
  const urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/clientes${parametros}`;
  return axios.get(urlPath);
}
function getFichaCliente(clienteId) {
  return new Promise((resolve, reject) => {
    const axios = createAxios();
    const user = JSON.parse(localStorage.getItem("user"));
    let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/cliente/${clienteId}`;
    return axios
      .get(urlPath)
      .then((response) => {
        resolve(response);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}
function loadCliente(clienteId) {
  return new Promise((resolve, reject) => {
    getFichaCliente(clienteId)
      .then((cliente) => {
        localStorage.setItem("cliente", JSON.stringify(cliente));
        resolve(cliente);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getRMAFile(clienteId, fileId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  const urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/cliente/${clienteId}/rma/file/${fileId}`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function updateCliente(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/cliente/${body._id}`;
  return axios.put(urlPath, body);
}

function sendInvitaciones(req) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  const urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/cliente/user`;
  return axios.post(urlPath, req);
}

function traspasarClientes(req) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  const urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/cliente/traspaso`;
  return axios.post(urlPath, req);
}

function getClientesAsExcel(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let parametros = "?limit=2000";
  if (request.nombre && request.nombre != "") {
    parametros += "&nombre=" + request.nombre;
  }
  if (request.alias && request.alias != "") {
    parametros += "&alias=" + request.alias;
  }
  if (request.codigo && request.codigo != "") {
    parametros += "&codigo=" + request.codigo;
  }
  if (request.idFiscal && request.idFiscal != "") {
    parametros += "&idFiscal=" + request.idFiscal;
  }
  if (request && request.activo) {
    parametros += `&activado=${request.activo}`;
  }

  if (request && request.franquiciaId) {
    parametros += `&franquiciaId=${request.franquiciaId}`;
  }

  if (request.codigoPostal && request.codigoPostal != "") {
    parametros += "&codigoPostal=" + request.codigoPostal;
  }
  let urlPath = `/franquicia/${user.extraData.franquiciaId}/cliente/excel${parametros}`;
  return axios
    .get(urlPath, {
      responseType: "blob",
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}
