import createAxios from "./axiosHttpMarconi";
export const envioConsultasService = {
  getQueries,
  postQuery,
  putQuery,
  deleteQuery,
};

function getQueries() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/envio_consulta`;
  return axios.get(urlPath);
}

function postQuery(req) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/envio_consulta`;
  return axios.post(urlPath, req);
}

function putQuery(id, req) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/envio_consulta/${id}`;
  return axios.put(urlPath, req);
}

function deleteQuery(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/envio_consulta/${id}`;
  return axios.delete(urlPath);
}
