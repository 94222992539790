import { createAxios } from "./axiosHttpMarconi";

export const borradosService = {
  getBorrados,
  deleteBorrado,
  postBorrado,
  updateBorrado,
  getBorradoById
};

function getBorrados(pagination,filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/borrados`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += "?page=0";
  }
  urlPath+= getUrlPath(filters);
  return axios.get(urlPath);
}

function getUrlPath(filters) {
  let urlPath="";
  if (filters && filters.baseDeDatos) {
    urlPath += `&bd=${filters.baseDeDatos}`;
  }
  if (filters && filters.coleccion) {
    urlPath += `&coleccion=${filters.coleccion}`;
  }
  return urlPath;
}

function deleteBorrado(transitoId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/borrados/${transitoId}`;
  return axios.delete(urlPath);
}

function postBorrado(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/borrados`;
  return axios.post(urlPath,request);
}

function updateBorrado(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/borrados/${request._id}`;
  return axios.put(urlPath,request);
}
function getBorradoById(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/borrados/${id}`;
  return axios.get(urlPath);
}
