import createAxios from "./axiosHttpMarconi";

function getRoles() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/auth/roles`;
  return axios.get(urlPath);
}

function updateRoles(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/auth/roles`;
  return axios.put(urlPath, body);
}

function getMenus() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/auth/menus`;
  return axios.get(urlPath);
}

function getMenusMaster() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/auth/menus/master`;
  return axios.get(urlPath).then((response) => {
    localStorage.setItem("roles-menu", JSON.stringify(response.master));
    return response;
  });
}

function updateMenus(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/auth/menus`;
  return axios.put(urlPath, body);
}

function getForbiddenPages(pages, user) {
  if (!pages || !user) return [];

  if (user.roles.includes("ADMIN")) return [];

  const forbiddenPages = [];
  for (const page of pages) {
    if (page.roles.length == 0) continue;

    if (!user.rolesMenu) {
      forbiddenPages.push(page.key);
      continue;
    }

    if (page.roles.some((rol) => user.rolesMenu.includes(rol))) continue;

    forbiddenPages.push(page.key);
  }
  return forbiddenPages;
}

export const rolesService = {
  getRoles,
  updateRoles,
  getMenus,
  updateMenus,
  getMenusMaster,
  getForbiddenPages,
};
