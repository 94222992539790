import { eventoService } from "@/_services";
export const evento = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {}
    },
    nuevo: {
      data: [],
      status: {}
    },
  },
  mutations: {
    getEventosRequest(state) {
      state.list.status = {
        loading: true
      }
    },
    getEventosSuccess(state, response) {
      state.list.data = response
      state.list.status = {
        loaded: true
      }
    },
    getEventosError(state, error) {
      state.list.data = null
      state.list.status = {
        error
      }
    },
    deleteEventoRequest(state) {
      state.delete.status = {
        erasing: true,
      };
    },
    deleteEventoError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteEventoSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        erased: true,
      };
    },
    nuevoEventoRequest(state) {
      state.nuevo.status = {
        loading: true,
      };
    },
    nuevoEventoError(state, error) {
      state.nuevo.data = null;
      state.nuevo.status = {
        error,
      };
    },
    nuevoEventoSuccess(state, response) {
      state.nuevo.data = response;
      state.nuevo.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getEventos({ commit }, request ) {
      commit('getEventosRequest');
      eventoService
        .getEventos(request.pagination,request.query)
        .then((response) => {
          commit('getEventosSuccess', response);
        })
        .catch((error) => {
          commit('getEventosError', error);
        });
    },
    deleteEvento({ commit }, id) {
      commit('deleteEventoRequest');
      eventoService.deleteEvento(id)
        .then((Res) => {
          commit('deleteEventoSuccess', Res);
        })
        .catch((error) => {
          commit('deleteEventoError', error);
        });
    },
    nuevoEvento({ commit }, request) {
      commit('nuevoEventoRequest');
      eventoService.nuevoEvento(request)
        .then((Res) => {
          commit('nuevoEventoSuccess', Res);
        })
        .catch((error) => {
          commit('nuevoEventoError', error);
        });
    },
  },
};
