<template>
  <filter-base :widthFilter="600" ref="filtroBase">
    <template v-slot:body>
      <el-form
        label-position="top"
        :model="localValue"
        ref="dataFilter"
        class="px-4 py-3"
      >
        <div class="row">
          <el-form-item label="Servicios" prop="servicio" class="col-12">
            <el-select
              class="select"
              v-model="localValue.servicio"
              filterable
              clearable
              placeholder="Seleccione una opción..."
            >
              <el-option
                v-for="item in allServicios"
                :key="item._id"
                :label="getLabel(item)"
                :value="item.codigo"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="País de Origen" prop="paisOrigen" class="col-6">
            <el-select
              class="select"
              v-model="localValue.paisOrigen"
              filterable
              placeholder="Seleccione una opción..."
            >
              <el-option
                v-for="item in allPaises"
                :key="item._id"
                :label="item.nombre.es"
                :value="item.iso2"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="País Destino" prop="paisDestino" class="col-6">
            <el-select
              class="select"
              v-model="localValue.paisDestino"
              filterable
              placeholder="Seleccione una opción..."
            >
              <el-option
                v-for="item in allPaises"
                :key="item._id"
                :label="item.nombre.es"
                :value="item.iso2"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item align="right">
          <el-button type="primary" @click="search()">Buscar</el-button>
          <el-button @click="clearFilters()">Limpiar</el-button>
        </el-form-item>
      </el-form>
    </template>
  </filter-base>
</template>
<script>
import FilterBase from "../../../../components/FilterBase.vue";
export default {
  components: { FilterBase },
  props: {
    value: {
      required: true,
      default: () => {
        return {
          paisOrigen: "",
          paisDestino: "",
          servicio: "",
        };
      },
    },
  },
  data() {
    return {
      localValue: this.value,
      allPaises: [],
      allServicios: [],
    };
  },
  mounted() {
    this.getPaises();
    this.getServicios();
  },
  computed: {
    paisesStatus() {
      return this.$store.state.pais.list.status;
    },
    servicios() {
      return this.$store.state.servicios.list.status;
    },
  },
  methods: {
    getLabel(item) {
      return item.codigo + " - " + item.descripcion;
    },
    getServicios() {
      this.$store.dispatch("servicios/getServicios", {
        pagination: null,
        query: null,
      });
    },
    getPaises() {
      this.$store.dispatch("pais/getPaises");
    },
    clearFilters() {
      this.localValue = {
        servicio: "",
        paisOrigen: "",
        paisDestino: "",
      };
      this.$emit("onFilterChange", this.localValue);
      this.closeFiltro();
    },
    search() {
      this.$emit("onFilterChange", this.localValue);
      this.closeFiltro();
    },
    closeFiltro() {
      this.$refs.filtroBase.closeFiltro();
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
    paisesStatus(val) {
      if (val.loaded) {
        if (this.$store.state.pais.list.data) {
          this.allPaises = this.$store.state.pais.list.data.items;
        }
      }
    },
    servicios() {
      if (this.servicios.loaded) {
        if (this.$store.state.servicios.list.data) {
          this.allServicios = this.$store.state.servicios.list.data.items;
          this.allServicios.forEach((element) => {
            element.disabled = false;
          });
        }
      }
    },
  },
};
</script>