import { createAxios, createAxiosHeaders } from "./axiosHttpMarconi";

function newEventoEnvio(envio_id, body) {
  const axios = createAxios();
  const franquiciadora = JSON.parse(localStorage.getItem("franquiciadora"));
  let urlPath = `/franquiciadora/${franquiciadora._id}/envio/${envio_id}/estados`;
  return axios.post(urlPath, body);
}
function getValoracionSimular(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/valoracion_simular/`;
  return axios.post(urlPath, body);
}

function getAllEnvios(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/envio`;

  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }

  urlPath += getFiltersPath(filters);
  return axios.get(urlPath);
}

function getFiltersPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.servicios) {
    urlPathFilters += `&servicios=${filters.servicios.join()}`;
  }

  if (filters && filters.estados) {
    urlPathFilters += `&estados=${filters.estados.join()}`;
  }

  if (filters && filters.idCliente) {
    urlPathFilters += `&idCliente=${filters.idCliente}`;
  }

  if (filters && filters.idFranquicia) {
    urlPathFilters += `&idFranquicia=${filters.idFranquicia}`;
  }

  if (filters && filters.search) {
    urlPathFilters += `&search=${filters.search}`;
  }

  if (filters && filters.year) {
    urlPathFilters += `&year=${filters.year}`;
  }
  if (filters && filters.consulta) {
    urlPathFilters += `&consulta=${filters.consulta}`;
  }
  if (filters && filters.fecha) {
    urlPathFilters += `&fecha_inicio=${filters.fecha[0]}`;
    urlPathFilters += `&fecha_fin=${filters.fecha[1]}`;
  }
  if (filters && filters.recogida) {
    urlPathFilters += `&recogida=${filters.recogida}`;
  }
  if (filters && filters.entrega) {
    urlPathFilters += `&entrega=${filters.entrega}`;
  }
  if (filters && filters.courierMillaIntermedia) {
    urlPathFilters += `&courierMillaIntermedia=${filters.courierMillaIntermedia}`;
  }
  return urlPathFilters;
}

function getEnvio(envioId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciaId}/envio/${envioId}`;
  return axios.get(urlPath);
}

function getEstadosEnvio(envioId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/envio/${envioId}/estados`;
  return axios.get(urlPath);
}

function getEnviosAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/envio/excel?limit=2000`;
  urlPath += getFiltersPath(filters);
  return axios.get(urlPath, { responseType: "blob" });
}

function getPlantillas(idCliente, pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/cliente/${idCliente}/plantillas`;
  if (pagination != null) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters.nombre && filters.nombre != "") {
    urlPath += "&nombre=" + filters.nombre;
  }
  if (filters.resumen && filters.resumen != "") {
    urlPath += "&resumen=" + filters.resumen;
  }
  return axios.get(urlPath);
}
function envioEtiquetaFranquiciadora(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  const urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/envio/etiqueta`;
  return axios.post(urlPath, body, {
    responseType: "blob",
  });
}

function getJustificante(request) {
  const axios = createAxiosHeaders();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/envio/${request.entityId}/comprobante-entrega`;
  return axios.get(urlPath).then((response) => {
    if (response.data instanceof Object) return JSON.stringify(response.data);
    else {
      const byteCharacters = atob(response.data);
      const byteArrays = [];
      for (let i = 0; i < byteCharacters.length; i++)
        byteArrays.push(byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteArrays);
      const type = response.headers["content-type"];
      return new Blob([byteArray], { type });
    }
  });
}

function getJustificanteRecogida(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/envio/${request.entityId}/comprobante-recogida`;
  return axios
    .get(urlPath, {
      responseType: "blob",
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

function getJustificanteServicio(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/envio/${request}/justificante-servicio`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getManifiesto(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/envio-manifiesto?cliente=${request.cliente}&inicio=${request.fechaInicio}&fin=${request.fechaFin}`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function sendEnvio(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  const urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/update/envio`;
  return axios.post(urlPath, body);
}

function sendEnvioProgramado(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  const urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/cliente/${request.envio.cliente}/plantilla`;
  return axios.post(urlPath, request);
}

function getDirHabituales(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/cliente/${body.clientId}/direcciones`;
  if (body.page != null) {
    urlPath += `?page=${body.page}&limit=${body.limit}&`;
  } else {
    urlPath += "?";
  }
  if (body.tipo) {
    urlPath += `tipo=${body.tipo}&`;
  }

  if (body.order) {
    urlPath += `order=${body.order}&`;
  }

  if (body.sort) {
    urlPath += `sort=${body.sort}&`;
  }

  if (body.normalizada) {
    urlPath += `normalizada=${body.normalizada}`;
  }
  return axios.get(urlPath);
}

export const envioService = {
  getAllEnvios,
  getEnvio,
  getEstadosEnvio,
  newEventoEnvio,
  getEnviosAsExcel,
  getPlantillas,
  envioEtiquetaFranquiciadora,
  getJustificante,
  getJustificanteServicio,
  getJustificanteRecogida,
  getManifiesto,
  getValoracionSimular,
  sendEnvio,
  sendEnvioProgramado,
  getDirHabituales

};
