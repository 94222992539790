import { servicioSeurService } from "@/_services";
export const servicioSeur = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {}
    },
    nuevo: {
      data: [],
      status: {}
    },
  },
  mutations: {
    getServicioSeurRequest(state) {
      state.list.status = {
        loading: true
      }
    },
    getServicioSeurSuccess(state, response) {
      state.list.data = response
      state.list.status = {
        loaded: true
      }
    },
    getServicioSeurError(state, error) {
      state.list.data = null
      state.list.status = {
        error
      }
    },
    deleteServicioSeurRequest(state) {
      state.delete.status = {
        erasing: true,
      };
    },
    deleteServicioSeurError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteServicioSeurSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        erased: true,
      };
    },
    nuevoServicioSeurRequest(state) {
      state.nuevo.status = {
        loading: true,
      };
    },
    nuevoServicioSeurError(state, error) {
      state.nuevo.data = null;
      state.nuevo.status = {
        error,
      };
    },
    nuevoServicioSeurSuccess(state, response) {
      state.nuevo.data = response;
      state.nuevo.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getServicioSeur({ commit }, request ) {
      commit('getServicioSeurRequest');
      servicioSeurService
        .getServicioSeur(request.pagination,request.query)
        .then((response) => {
          commit('getServicioSeurSuccess', response);
        })
        .catch((error) => {
          commit('getServicioSeurError', error);
        });
    },
    deleteServicioSeur({ commit }, id) {
      commit('deleteServicioSeurRequest');
      servicioSeurService.deleteServicioSeur(id)
        .then((Res) => {
          commit('deleteServicioSeurSuccess', Res);
        })
        .catch((error) => {
          commit('deleteServicioSeurError', error);
        });
    },
    nuevoServicioSeur({ commit }, request) {
      commit('nuevoServicioSeurRequest');
      servicioSeurService.nuevoServicioSeur(request)
        .then((Res) => {
          commit('nuevoServicioSeurSuccess', Res);
        })
        .catch((error) => {
          commit('nuevoServicioSeurError', error);
        });
    },
  },
};
