<template>
  <div class="dropdown">
    <button
      class="btn btn-secondary dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      id="botonFiltro"
      data-bs-auto-close="false"
    >
      Buscar
    </button>
    <div
      class="dropdown-menu dropdown-menu-end"
      id="dropdown-busqueda-envio"
      :class="`filter-body-${widthFilter}`"
    >
      <i class="el-icon-close float-end me-2" @click="closeFiltro"></i>
      <div class="mt-2">
        <slot name="body"> Filtros </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    widthFilter: {
      type: Number,
      default: () => 100,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    closeFiltro() {
      document.getElementById("botonFiltro").click();
    },
  },
  watch: {},
};
</script>
<style lang="scss">
.el-icon-close {
  cursor: pointer;
}
</style>

<style scoped>
.btn {
  margin-bottom: 0px;
}
</style>
