import { createAxios } from "./axiosHttpMarconi";

export const transitoService = {
  getTransito,
  deleteTransito,
  nuevoTransito,
  updateTransito,
  getTransitoById,
  getReglasTransitoExcel
};

function getTransito(pagination,filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/regla-transito`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += "?page=0";
  }
  urlPath+= getQueryPath(filters);
  return axios.get(urlPath);
}

function getQueryPath(filters) {
  let urlPath="";
  if (filters && filters.servicio) {
    urlPath += `&servicio=${filters.servicio}`;
  }
  if (filters && filters.paisOrigen) {
    urlPath += `&paisOrigen=${filters.paisOrigen}`;
  }
  if (filters && filters.paisDestino) {
    urlPath += `&paisDestino=${filters.paisDestino}`;
  }
  return urlPath;
}


function deleteTransito(transitoId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/regla-transito/${transitoId}`;
  return axios.delete(urlPath);
}

function nuevoTransito(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/regla-transito`;
  return axios.post(urlPath,request);
}

function updateTransito(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/regla-transito/${request._id}`;
  return axios.put(urlPath,request);
}
function getTransitoById(transitoId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/regla-transito/${transitoId}`;
  return axios.get(urlPath);
}

function getReglasTransitoExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/regla-transito/excel?limit=2000`;
  urlPath += getQueryPath(filters);
  return axios.get(urlPath, { responseType: "blob" }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}