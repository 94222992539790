var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card", { staticClass: "card-user" }, [
    _c("div", { attrs: { slot: "image" }, slot: "image" }, [
      _c("img", {
        attrs: {
          src: "https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400",
          alt: "...",
        },
      }),
    ]),
    _c("div", [
      _c("div", { staticClass: "author" }, [
        _c("img", {
          staticClass: "avatar border-gray",
          attrs: { src: "static/img/default-avatar.png", alt: "..." },
        }),
        _c("h4", { staticClass: "title" }, [
          _vm._v("Tania Keatley "),
          _c("br"),
          _c("a", { attrs: { href: "#" } }, [
            _c("small", [_vm._v("tania123")]),
          ]),
        ]),
      ]),
      _c("p", { staticClass: "description text-center" }, [
        _c("span", [
          _vm._v(' "Lamborghini Mercy '),
          _c("br"),
          _vm._v("Your chick she so thirsty "),
          _c("br"),
          _vm._v("I'm in that two seat Lambo\" "),
        ]),
      ]),
    ]),
    _c("hr"),
    _c("div", { staticClass: "text-center" }, [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-simple btn-link btn-icon",
            attrs: { type: "button" },
          },
          [_c("i", { staticClass: "fa fa-facebook-square" })]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-simple btn-link btn-icon",
            attrs: { type: "button" },
          },
          [_c("i", { staticClass: "fa fa-twitter" })]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-simple btn-link btn-icon",
            attrs: { type: "button" },
          },
          [_c("i", { staticClass: "fa fa-google-plus-square" })]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }