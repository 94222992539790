var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-timeline card-plain" }, [
    _c("div", { staticClass: "card-content" }, [
      _c(
        "ul",
        {
          staticClass: "timeline",
          class: { "timeline-simple": _vm.type === "simple" },
        },
        [_vm._t("default")],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }