import { planesCombustibleService } from "@/_services";
export const planesCombustible = {
  namespaced: true,
  state: {
    planesComb: {
      data: [],
      status: {}
    },
    eliminar: {
      data: [],
      status: {}
    },
    combustible: {
      data: [],
      status: {}
    },
  },
  mutations: {
    getPlanesCombustibleRequest(state) {
      state.planesComb.status = {
        loading: true,
      };
    },
    getCombustibleError(state, error) {
      state.planesComb.data = null;
      state.planesComb.status = {
        error,
      };
    },
    getCombustibleSuccess(state, list) {
      state.planesComb.data = list;
      state.planesComb.status = {
        loaded: true,
      };
    },
    deletePlanCombustibleRequest(state) {
      state.eliminar.status = {
        deleting: true,
      };
    },
    deletePlanCombustibleError(state, error) {
      state.eliminar.data = null;
      state.eliminar.status = {
        error,
      };
    },
    deletePlanCombustibleSuccess(state, ficha) {
      state.eliminar.data = ficha;
      state.eliminar.status = {
        deleted: true,
      };
    },
    newCombustibleRequest(state) {
      state.combustible.status = {
        loading: true,
      };
    },
    newCombustibleError(state, error) {
      state.combustible.data = null;
      state.combustible.status = {
        error,
      };
    },
    newCombustibleSuccess(state, combustible) {
      state.combustible.data = combustible;
      state.combustible.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getPlanesCombustible({ commit }, req) {
      commit("getPlanesCombustibleRequest");
      planesCombustibleService
        .getPlanesCombustible(req.pagination, req.filters)
        .then((list) => {
          commit("getCombustibleSuccess", list);
        })
        .catch((error) => {
          commit("getCombustibleError", error);
        });
    },
    eliminarPlanCombustible({ commit }, idPlan) {
      commit("deletePlanCombustibleRequest");
      planesCombustibleService
        .eliminarPlanCombustible(idPlan)
        .then((env) => {
          commit("deletePlanCombustibleSuccess", env);
        })
        .catch((error) => {
          commit("deletePlanCombustibleError", error);
        });
    },
    newCombustible({ commit }, body) {
      commit('newCombustibleRequest');
      planesCombustibleService.newCombustible(body)
        .then((combustible) => {
          commit('newCombustibleSuccess', combustible);
        })
        .catch((error) => {
          commit('newCombustibleError', error);
        });
    },

  },
};
