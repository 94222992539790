import { createAxios, createAxiosHeaders } from "./axiosHttpMarconi";
export const documentacionService = {
  getDocumentaciones,
  deleteDocumentacion,
  downloadDocumentacion,
};

function getDocumentaciones(params) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/documentaciones`;
  urlPath += `?franquicia=${params.franquicia}&year=${params.year}&month=${params.month}`;
  return axios.get(urlPath);
}

function downloadDocumentacion(body) {
  const axios = createAxiosHeaders();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/documentaciones/download`;
  return axios
    .post(urlPath, body)
    .then((response) => {
      const byteCharacters = atob(response.data);
      const byteArrays = [];
      for (let i = 0; i < byteCharacters.length; i++)
        byteArrays.push(byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteArrays);
      const type = response.headers["content-type"];
      console.log(response.headers);
      return {
        file: new Blob([byteArray], { type }),
        filename: response.headers["content-disposition"],
      };
    })
    .catch(async (error) => {
      const data = await error.text();
      throw JSON.parse(data);
    });
}

function deleteDocumentacion(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/documentaciones/delete`;
  return axios.post(urlPath, body);
}
