import { createAxiosLogistica } from "./axiosHttpMarconi";

export const medidasService = {
  getMedidas,
  deleteMedida,
  postMedida,
  updateMedida,
  getMedidaById,
};

function getMedidas(pagination, filters) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/medida`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function deleteMedida(medidaId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/medida/${medidaId}`;
  return axios.delete(urlPath);
}

function postMedida(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/medida`;
  return axios.post(urlPath, request);
}

function updateMedida(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/medida/${id}`;
  return axios.put(urlPath, body);
}

function getMedidaById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/medida/${id}`;
  return axios.get(urlPath);
}
