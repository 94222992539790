import createAxios from "./axiosHttpMarconi";
export const masivoService = {
  getMasivos,
  anularMasivo,
  getMasivoEtiquetas,
  getEnviosMasivos,
  getMasivo,
  getMasivoDownload,
};

function getMasivos(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/masivos`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.date) {
    urlPath += `&fechaInicio=${filters.date[0]}`;
    urlPath += `&fechaFin=${filters.date[1]}`;
  }
  if (filters && filters.order) {
    urlPath += `&sort=createdAt&order=${filters.order}`;
  } else {
    urlPath += `&sort=createdAt&order=descending`;
  }
  if (filters && filters.estados) {
    urlPath += `&estados=${filters.estados}`;
  }
  if (filters && filters.tipo) {
    urlPath += `&tipo=${filters.tipo}`;
  }
  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }
  if (filters && filters.nombreCliente) {
    urlPath += `&nombreCliente=${filters.nombreCliente}`;
  }
  if (filters && filters.filename) {
    urlPath += `&filename=${filters.filename}`;
  }
  if (filters && filters.franquicia && filters.franquicia._id != "") {
    urlPath += `&franquicia=${filters.franquicia._id}`;
  }
  return axios.get(urlPath);
}

function getMasivo(masivoId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/masivo/${masivoId}`;
  return axios.get(urlPath);
}

function getMasivoDownload(idMasivo) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/masivo/${idMasivo}/download`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function anularMasivo(masivo) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquicia/${user.extraData.franquiciaId}/masivo/${masivo._id}`;
  return axios.delete(urlPath);
}

function getMasivoEtiquetas(masivo) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  const urlPath = `franquicia/${user.extraData.franquiciaId}/masivo/${masivo._id}/etiqueta`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getEnviosMasivos(idMasivo) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/masivo/${idMasivo}`;
  return axios.get(urlPath);
}
