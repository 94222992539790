var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card", [
    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
      _c("h4", { staticClass: "title" }, [_vm._v("Edit Profile")]),
    ]),
    _c("div", [
      _c("form", [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-5" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "Company",
                  disabled: true,
                  placeholder: "Paper dashboard",
                },
                model: {
                  value: _vm.user.company,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "company", $$v)
                  },
                  expression: "user.company",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-3" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "Username",
                  placeholder: "Username",
                },
                model: {
                  value: _vm.user.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "username", $$v)
                  },
                  expression: "user.username",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("fg-input", {
                attrs: {
                  type: "email",
                  label: "Username",
                  placeholder: "Email",
                },
                model: {
                  value: _vm.user.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "email", $$v)
                  },
                  expression: "user.email",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "First Name",
                  placeholder: "First Name",
                },
                model: {
                  value: _vm.user.firstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "firstName", $$v)
                  },
                  expression: "user.firstName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "Last Name",
                  placeholder: "Last Name",
                },
                model: {
                  value: _vm.user.lastName,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "lastName", $$v)
                  },
                  expression: "user.lastName",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "Address",
                  placeholder: "Home Address",
                },
                model: {
                  value: _vm.user.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "address", $$v)
                  },
                  expression: "user.address",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("fg-input", {
                attrs: { type: "text", label: "City", placeholder: "City" },
                model: {
                  value: _vm.user.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "city", $$v)
                  },
                  expression: "user.city",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("fg-input", {
                attrs: {
                  type: "text",
                  label: "Country",
                  placeholder: "Country",
                },
                model: {
                  value: _vm.user.country,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "country", $$v)
                  },
                  expression: "user.country",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c("fg-input", {
                attrs: { label: "Postal Code", placeholder: "ZIP Code" },
                model: {
                  value: _vm.user.postalCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "postalCode", $$v)
                  },
                  expression: "user.postalCode",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12" },
            [
              _c(
                "fg-input",
                { attrs: { label: "About Me" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: "",
                      placeholder: "ZIP Code",
                    },
                    model: {
                      value: _vm.user.aboutMe,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "aboutMe", $$v)
                      },
                      expression: "user.aboutMe",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-info btn-fill btn-wd",
              attrs: { type: "submit" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.updateProfile.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Update Profile ")]
          ),
        ]),
        _c("div", { staticClass: "clearfix" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }