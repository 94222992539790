import axios from "axios";
import createAxios from "./axiosHttpMarconi";
import createAxiosLogin from "./axiosLogin";

export const userService = {
  login,
  changePassword,
  tieneRol,
  esFranquiciadora,
  logout,
  oneShotToken,
  getUsuarios,
  getRoles,
  nuevoUsuario,
  cambiarEstadoUser,
  getEnlaceRecuperacion,
  resetPassword,
  eliminarUser,
  updateUser,
  getUserId,
  setRolesMenu,
  setRolesMenuByQuery,
};

function login(user, password) {
  const axios = createAxiosLogin();
  return axios.post("/auth/login", { user, password }).then((userLogged) => {
    if (userLogged) {
      localStorage.setItem("user", JSON.stringify(userLogged));
    }
    return userLogged;
  });
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("franquicia");
}

function oneShotToken() {
  const axios = createAxios();
  return axios.get("/auth/token");
}

function getRoles(tipo) {
  const axios = createAxios();
  let urlPath = `/auth/roles?sort=descripcion&order=ascencing`;
  if (tipo) {
    urlPath += `?tipo=${tipo}?sort=descripcion&order=ascencing`;
  }
  return axios.get(urlPath);
}

function nuevoUsuario(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/auth/user`;
  return axios.post(urlPath, request);
}

function getUsuarios(filters, pagination) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/auth/user`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0";
  }
  urlPath = `${urlPath}${makeQueryParams(filters)}`;
  return axios.get(urlPath);
}

function makeQueryParams(filters) {
  let urlPath = "";
  if (filters && filters.user) {
    urlPath += `&user=${filters.user}`;
  }
  if (filters && filters.roles) {
    urlPath += `&roles=${filters.roles}`;
  }
  if (filters && filters.rolesMenu) {
    urlPath += `&rolesMenu=${filters.rolesMenu}`;
  }
  if (filters && filters.status) {
    urlPath += `&status=${filters.status}`;
  }
  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }
  if (filters && filters.idFranquicia) {
    urlPath += `&idFranquicia=${filters.idFranquicia}`;
  }
  if (filters && filters.tipo) {
    urlPath += `&tipo=${filters.tipo}`;
  }
  if (filters && filters.sort && filters.sort.sort) {
    urlPath += `&sort=${filters.sort.sort}`;
  }
  if (filters && filters.sort && filters.sort.order) {
    urlPath += `&order=${filters.sort.order}`;
  }
  return urlPath;
}

function tieneRol(user, rol) {
  return user.roles != null && user.roles.indexOf(rol) > -1;
}

function cambiarEstadoUser(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/auth/user/${id}`;
  return axios.put(urlPath);
}

function esFranquiciadora(user) {
  return tieneRol(user, "FRANQUICIADORA");
}

function changePassword(body) {
  const axios = createAxios();
  const urlPath = "/auth/password";
  return axios.post(urlPath, body);
}

function getEnlaceRecuperacion(body) {
  const axios = createAxiosLogin();
  const urlPath = "/auth/password/link";
  return axios.post(urlPath, body);
}

function resetPassword(body, token) {
  const axios = createAxiosLogin();
  const urlPath = `/auth/password/reestablecer?ost=${token}`;
  return axios.post(urlPath, body);
}

function eliminarUser(idUser) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/user/${idUser}`;
  return axios.delete(urlPath);
}

function updateUser(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/user/${request._id}`;
  return axios.put(urlPath, request);
}

function setRolesMenu(id, roles) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/user/${id}/rol`;
  return axios.put(urlPath, roles);
}

function setRolesMenuByQuery(filters, roles) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/user/query?page=0`;
  urlPath = `${urlPath}${makeQueryParams(filters)}`;
  return axios.put(urlPath, roles);
}

function getUserId(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/auth/user/${id}`;
  return axios.get(urlPath);
}
