import { clienteService } from "@/_services";

export const cliente = {
  namespaced: true,
  state: {
    clientes: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    new: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    rmaFile: {
      data: {},
      status: {},
    },
    invitaciones: {
      data: {},
      status: {},
    },
    traspasar: {
      data: {},
      status: {},
    },
    clientesExcel: {
      data: null,
      status: {},
    },
  },
  mutations: {
    getClienteFichaRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getClienteFichaError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getClienteFichaSuccess(state, ficha) {
      state.entity.data = ficha;
      state.entity.status = {
        loaded: true,
      };
    },
    newClienteRequest(state) {
      state.new.status = {
        creating: true,
      };
    },
    newClienteError(state, error) {
      state.new.data = null;
      state.new.status = {
        error,
      };
    },
    newClienteSuccess(state, cliente) {
      state.new.data = cliente;
      state.new.status = {
        created: true,
      };
    },
    editClienteRequest(state) {
      state.update.status = {
        editing: true,
      };
    },
    editClienteError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    editClienteSuccess(state, clienteEditado) {
      state.update.data = clienteEditado;
      state.update.status = {
        edited: true,
      };
    },
    getAllClientesRequest(state) {
      state.clientes.status = {
        loading: true,
      };
    },
    getAllClientesSuccess(state, clientes) {
      state.clientes.data = clientes;
      state.clientes.status = {
        loaded: true,
      };
    },
    getAllClientesError(state, error) {
      state.clientes.data = null;
      state.clientes.status = {
        error,
      };
    },
    getRMAFileRequest(state) {
      state.rmaFile.status = {
        downloading: true,
      };
    },
    getRMAFileError(state, error) {
      state.rmaFile.data = null;
      state.rmaFile.status = {
        error,
      };
    },
    getRMAFileSuccess(state, ficha) {
      state.rmaFile.data = ficha;
      state.rmaFile.status = {
        downloaded: true,
      };
    },
    postInvitacionesUsuariosRequest(state) {
      state.invitaciones.status = {
        sending: true,
      };
    },
    postInvitacionesUsuariosError(state, error) {
      state.invitaciones.data = null;
      state.invitaciones.status = {
        error,
      };
    },
    postInvitacionesUsuariosSuccess(state, res) {
      state.invitaciones.data = res;
      state.invitaciones.status = {
        sended: true,
      };
    },
    postClientesTraspasoRequest(state) {
      state.traspasar.status = {
        transferring: true,
      };
    },
    postClientesTraspasoError(state, error) {
      state.traspasar.data = null;
      state.traspasar.status = {
        error,
      };
    },
    postClientesTraspasoSuccess(state, res) {
      state.traspasar.data = res;
      state.traspasar.status = {
        transferred: true,
      };
    },
    clientesExcelRequest(state) {
      state.clientesExcel.status = {
        loading: true,
      };
    },
    clientesExcelSuccess(state, pdf) {
      state.clientesExcel.data = pdf;
      state.clientesExcel.status = {
        loaded: true,
      };
    },
    clientesExcelError(state, error) {
      state.clientesExcel.data = null;
      state.clientesExcel.status = {
        error,
      };
    },
  },
  actions: {
    getFichaCliente({ commit }, clienteId) {
      commit("getClienteFichaRequest");
      clienteService
        .loadCliente(clienteId)
        .then((env) => {
          commit("getClienteFichaSuccess", env);
        })
        .catch((error) => {
          commit("getClienteFichaError", error);
        });
    },
    newCliente({ commit }, body) {
      commit("newClienteRequest");
      clienteService
        .newCliente(body)
        .then((cliente) => {
          commit("newClienteSuccess", cliente);
        })
        .catch((error) => {
          commit("newClienteError", error);
        });
    },
    updateCliente({ commit }, body) {
      commit("editClienteRequest");
      clienteService
        .updateCliente(body)
        .then((Res) => {
          commit("editClienteSuccess", Res);
        })
        .catch((error) => {
          commit("editClienteError", error);
        });
    },
    getAllClientes({ commit }, req) {
      commit("getAllClientesRequest");
      clienteService
        .getAllClientes(req.pagination, req.filters)
        .then((clientes) => {
          commit("getAllClientesSuccess", clientes);
        })
        .catch((error) => {
          commit("getAllClientesError", error);
        });
    },
    getRMAFile({ commit }, req) {
      commit("getRMAFileRequest");
      clienteService
        .getRMAFile(req.cliente, req.procesoId)
        .then((env) => {
          commit("getRMAFileSuccess", env);
        })
        .catch((error) => {
          commit("getRMAFileError", error);
        });
    },
    sendInvitaciones({ commit }, req) {
      commit("postInvitacionesUsuariosRequest");
      clienteService
        .sendInvitaciones(req)
        .then((env) => {
          commit("postInvitacionesUsuariosSuccess", env);
        })
        .catch((error) => {
          commit("postInvitacionesUsuariosError", error);
        });
    },
    traspasarClientes({ commit }, req) {
      commit("postClientesTraspasoRequest");
      clienteService
        .traspasarClientes(req)
        .then((env) => {
          commit("postClientesTraspasoSuccess", env);
        })
        .catch((error) => {
          commit("postClientesTraspasoError", error);
        });
    },
    getClientesAsExcel({ commit }, request) {
      commit("clientesExcelRequest");
      clienteService
        .getClientesAsExcel(request)
        .then((excel) => {
          commit("clientesExcelSuccess", excel);
        })
        .catch((error) => {
          commit("clientesExcelError", error);
        });
    },
  },
};
