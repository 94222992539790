import Checkbox from './Inputs/Checkbox.vue'
import LAlert from './Alert.vue'
import Radio from './Inputs/Radio.vue'
import FormGroupInput from './Inputs/formGroupInput.vue'
import Switch from './Switch.vue'
import Progress from './Progress.vue'

import DropDown from './Dropdown.vue'
import Table from './Table.vue'

import FadeRenderTransition from './Transitions/FadeRenderTransition.vue'

import Card from './Cards/Card.vue'
import ChartCard from './Cards/ChartCard.vue'
import StatsCard from './Cards/StatsCard.vue'

import Breadcrumb from './Breadcrumb/Breadcrumb.vue'
import BreadcrumbItem from './Breadcrumb/BreadcrumbItem.vue'

import Pagination from './Pagination.vue'

import SidebarPlugin from './SidebarPlugin'
import Estado from './Estado'
import SelectorDireccion from './Direccion/SelectorDireccion'

let components = {
  LAlert,
  Checkbox,
  Switch,
  Progress,
  Pagination,
  Radio,
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  Table,
  DropDown,
  SidebarPlugin,
  FadeRenderTransition,
  Breadcrumb,
  BreadcrumbItem,
  Estado,
  SelectorDireccion
}
export {
  LAlert,
  Checkbox,
  Switch,
  Progress,
  Pagination,
  Radio,
  FormGroupInput,
  Card,
  ChartCard,
  StatsCard,
  Table,
  DropDown,
  SidebarPlugin,
  FadeRenderTransition,
  Breadcrumb,
  BreadcrumbItem,
  Estado,
  SelectorDireccion
}

export default components
