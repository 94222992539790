import { serviciosService } from '@/_services';

export const servicios = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    serviciosCliente: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {}
    },
    newDeprecado: {
      data: [],
      status: {}
    },
    lisDeprecados: {
      data: [],
      status: {}
    },
    deleteDeprecado: {
      data: [],
      status: {}
    }
  },
  mutations: {
    getServicioDeprecadoRequest(state) {
      state.lisDeprecados.status = {
        loading: true,
      };
    },
    cancelServicioDeprecadoRequest(state, error) {
      state.lisDeprecados.data = [];
      state.lisDeprecados.status = {
        error,
      };
    },
    getServicioDeprecadoSuccess(state, serv) {
      state.lisDeprecados.data = serv;
      state.lisDeprecados.status = {
        loaded: true,
      };
    },

    nuevoServicioDeprecadoRequest(state) {
      state.newDeprecado.status = {
        creating: true,
      };
    },
    nuevoServicioDeprecadoCancel(state, error) {
      state.newDeprecado.data = [];
      state.newDeprecado.status = {
        error,
      };
    },
    nuevoServicioDeprecadoSuccess(state, serv) {
      state.newDeprecado.data = serv;
      state.newDeprecado.status = {
        created: true,
      };
    },
    eliminarServicioDeprecadoRequest(state) {
      state.deleteDeprecado.status = {
        deleting: true,
      };
    },
    eliminarServicioDeprecadoCancel(state, error) {
      state.deleteDeprecado.data = [];
      state.deleteDeprecado.status = {
        error,
      };
    },
    eliminarServicioDeprecadoSuccess(state, serv) {
      state.deleteDeprecado.data = serv;
      state.deleteDeprecado.status = {
        deleted: true,
      };
    },

    editServicioRequest(state) {
      state.update.status = {
        editing: true,
      };
    },
    editServicioError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    editServicioSuccess(state, franquiciaEditada) {
      state.update.data = franquiciaEditada;
      state.update.status = {
        edited: true,
      };
    },

    getServicioByIdRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelServicioByIdRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getServicioByIdSuccess(state, serv) {
      state.list.data = serv;
      state.list.status = {
        loaded: true,
      };
    },
    getServicioByClienteRequest(state) {
      state.serviciosCliente.status = {
        loading: true,
      };
    },
    cancelServicioByClienteRequest(state, error) {
      state.serviciosCliente.data = [];
      state.serviciosCliente.status = {
        error,
      };
    },
    getServicioByClienteSuccess(state, serv) {
      state.serviciosCliente.data = serv;
      state.serviciosCliente.status = {
        loaded: true,
      };
    },
  },
  actions: {
    updateServicio({ commit }, body) {
      commit('editServicioRequest');
      serviciosService.updateServicio(body)
        .then((Res) => {
          commit('editServicioSuccess', Res);
        })
        .catch((error) => {
          commit('editServicioError', error);
        });
    },
    getServicios({ commit }, req) {
      commit('getServicioByIdRequest');
      serviciosService
        .getServicios(req.pagination, req.query)
        .then((serv) => {
          commit('getServicioByIdSuccess', serv);
        })
        .catch((error) => {
          commit('cancelServicioByIdRequest', error);
        });
    },
    getServiciosByCliente({ commit }, request) {
      commit('getServicioByClienteRequest');
      serviciosService
        .getServiciosByCliente(request)
        .then((serv) => {
          commit('getServicioByClienteSuccess', serv);
        })
        .catch((error) => {
          commit('cancelServicioByClienteRequest', error);
        });
    },
    getServiciosDeprecado({ commit }, req) {
      commit('getServicioDeprecadoRequest');
      serviciosService
        .getServiciosDeprecado(req.pagination, req.filters)
        .then((serv) => {
          commit('getServicioDeprecadoSuccess', serv);
        })
        .catch((error) => {
          commit('cancelServicioDeprecadoRequest', error);
        });
    },
    nuevoServicioDeprecado({ commit }, body) {
      commit('nuevoServicioDeprecadoRequest');
      serviciosService
        .nuevoServicioDeprecado(body)
        .then((serv) => {
          commit('nuevoServicioDeprecadoSuccess', serv);
        })
        .catch((error) => {
          commit('nuevoServicioDeprecadoCancel', error);
        });
    },
    eliminarServicioDeprecado({ commit }, idServicio) {
      commit('eliminarServicioDeprecadoRequest');
      serviciosService
        .eliminarServicioDeprecado(idServicio)
        .then((serv) => {
          commit('eliminarServicioDeprecadoSuccess', serv);
        })
        .catch((error) => {
          commit('eliminarServicioDeprecadoCancel', error);
        });
    },
  },
};

