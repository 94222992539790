import { rolesService } from "@/_services";

export const roles = {
  namespaced: true,
  state: {
    entity: {
      data: {},
      status: {},
    },
    editar: {
      data: {},
      status: {},
    },
    menus: {
      data: {},
      status: {},
    },
    menusMaster: {
      data: {},
      status: {},
    },
    editarMenus: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getRolesRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getRolesError(state, error) {
      state.entity.data = {};
      state.entity.status = {
        error,
      };
    },
    getRolesSuccess(state, data) {
      state.entity.data = data;
      state.entity.status = {
        loaded: true,
      };
    },
    updateRolesRequest(state) {
      state.editar.status = {
        updating: true,
      };
    },
    updateRolesError(state, error) {
      state.editar.data = {};
      state.editar.status = {
        error,
      };
    },
    updateRolesSuccess(state, data) {
      state.editar.data = data;
      state.editar.status = {
        updated: true,
      };
    },
    getMenusRequest(state) {
      state.menus.status = {
        loading: true,
      };
    },
    getMenusError(state, error) {
      state.menus.data = {};
      state.menus.status = {
        error,
      };
    },
    getMenusSuccess(state, data) {
      state.menus.data = data;
      state.menus.status = {
        loaded: true,
      };
    },
    getMenusMasterRequest(state) {
      state.menusMaster.status = {
        loading: true,
      };
    },
    getMenusMasterError(state, error) {
      state.menusMaster.data = {};
      state.menusMaster.status = {
        error,
      };
    },
    getMenusMasterSuccess(state, data) {
      state.menusMaster.data = data;
      state.menusMaster.status = {
        loaded: true,
      };
    },
    updateMenusRequest(state) {
      state.editarMenus.status = {
        updating: true,
      };
    },
    updateMenusError(state, error) {
      state.editarMenus.data = {};
      state.editarMenus.status = {
        error,
      };
    },
    updateMenusSuccess(state, data) {
      state.editarMenus.data = data;
      state.editarMenus.status = {
        updated: true,
      };
    },
  },
  actions: {
    getRoles({ commit }) {
      commit("getRolesRequest");
      rolesService
        .getRoles()
        .then((res) => {
          commit("getRolesSuccess", res);
        })
        .catch((error) => {
          commit("getRolesError", error);
        });
    },
    updateRoles({ commit }, req) {
      commit("updateRolesRequest");
      rolesService
        .updateRoles(req)
        .then((res) => {
          commit("updateRolesSuccess", res);
        })
        .catch((error) => {
          commit("updateRolesError", error);
        });
    },
    getMenus({ commit }) {
      commit("getMenusRequest");
      rolesService
        .getMenus()
        .then((res) => {
          commit("getMenusSuccess", res);
        })
        .catch((error) => {
          commit("getMenusError", error);
        });
    },
    getMenusMaster({ commit }) {
      commit("getMenusMasterRequest");
      rolesService
        .getMenusMaster()
        .then((res) => {
          commit("getMenusMasterSuccess", res);
        })
        .catch((error) => {
          commit("getMenusMasterError", error);
        });
    },
    updateMenus({ commit }, req) {
      commit("updateMenusRequest");
      rolesService
        .updateMenus(req)
        .then((res) => {
          commit("updateMenusSuccess", res);
        })
        .catch((error) => {
          commit("updateMenusError", error);
        });
    },
  },
};
