import createAxios from "./axiosHttpMarconi";
export const estadosParadaEnvioService = {
  getEstados,
  newEstado,
  deleteEstado,
};

function getEstados(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/estado-parada-envio`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += `&sort=codigo&order=ascending`;
  urlPath += getFiltersPath(filters);
  return axios.get(urlPath);
}
function getFiltersPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.codigoParada) {
    urlPathFilters += `&codigoParada=${filters.codigoParada}`;
  }

  if (filters && filters.courier) {
    urlPathFilters += `&courier=${filters.courier}`;
  }

  if (filters && filters.codigoEnvio) {
    urlPathFilters += `&codigoEnvio=${filters.codigoEnvio}`;
  }
  return urlPathFilters;
}

function newEstado(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/estado-parada-envio`;
  return axios.post(urlPath, body);
}

function deleteEstado(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/estado-parada-envio/${id}`;
  return axios.delete(urlPath);
}
