<template>
  <p v-if="texto" class="texto click-hover" v-tooltip.top-center="texto">
    <i class="fa fa-filter"></i>
    {{ texto }}
  </p>
</template>
<script>
export default {
  props: {
    filtrosProp: {
      type: Object,
    },
  },
  data() {
    return {
      datos: JSON.stringify(this.filtrosProp),
      filtros: [],
      size: "small",
    };
  },
  computed: {
    texto() {
      let filtros = "";
      for (let n = 0; n < this.filtros.length; n += 1) {
        let label = this.filtros[n].label.replace(/([A-Z])/g, " $1");
        filtros +=
          `${label[0].toUpperCase()}${label.slice(1)}: ${
            this.filtros[n].value
          }` + "     ";
      }
      return filtros;
    },
  },
  mounted() {
    for (const prope in this.filtrosProp) {
      this.filtros.push({ label: prope, value: this.filtrosProp[prope] });
    }
  },
  watch: {
    filtrosProp() {
      this.filtros = [];
      for (const prope in this.filtrosProp) {
        this.filtros.push({ label: prope, value: this.filtrosProp[prope] });
      }
    },
  },
};
</script>
<style scoped>
.texto {
  color: #909399;
  font-weight: 600;
  font-size: 11px;
  overflow: hidden;
  max-height: 2.4em;
  line-height: 1.2em;
  margin-top: 10px;
  margin-bottom: 0px;
  word-wrap: break-word;
}

i {
  color: #909399;
}

.click-hover:hover {
  -webkit-filter: brightness(120%);
  color: #3d3d3d;
}
</style>