import { createAxios } from "./axiosHttpMarconi";

export const servicioSeurService = {
  getServicioSeur,
  deleteServicioSeur,
  nuevoServicioSeur,
};

function getServicioSeur(pagination,filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/servicios/seur`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += "?page=0";
  }
  urlPath+= getQueryPath(filters);
  return axios.get(urlPath);
}

function getQueryPath(filters) {
  let urlPath="";
  if (filters && filters.servicio) {
    urlPath += `&servicio=${filters.servicio}`;
  }
  if (filters && filters.producto) {
    urlPath += `&producto=${filters.producto}`;
  }
  if (filters && filters.codigoServicio) {
    urlPath += `&codigoServicio=${filters.codigoServicio}`;
  }
  if (filters && filters.nombreProducto) {
    urlPath += `&nombreProducto=${filters.nombreProducto}`;
  }
 
  return urlPath;
}


function deleteServicioSeur(seurId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/servicios/seur/${seurId}`;
  return axios.delete(urlPath);
}

function nuevoServicioSeur(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/servicios/seur`;
  return axios.post(urlPath,request);
}