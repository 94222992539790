import { createAxios } from "./axiosHttpMarconi";
export const partnerService = {
  getOficinas,
  partnerSolucion,
  getAllPartners,
  getPartnerByCode,
};

function getOficinas(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/partner/oficinas`;
  if (filters && filters.codigoPostal) {
    urlPath += `?codigoPostal=${filters.codigoPostal}`;
  }
  if (filters && filters.poblacion) {
    urlPath += `&poblacion=${filters.poblacion}`;
  }
  if (filters && filters.idioma) {
    urlPath += `&idioma=${filters.idioma}`;
  }
  return axios.get(urlPath);
}
function partnerSolucion(idIncidencia, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/partner/incidencia/${idIncidencia}`;
  return axios.post(urlPath, body);
}

function getAllPartners() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/partners`;
  return axios.get(urlPath);
}

function getPartnerByCode(code) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/partner/${code}`;
  return axios.get(urlPath);
}
