import { createAxios } from "./axiosHttpMarconi";

export const puntoRedService = {
  getPuntosRedByPostalCode,
  getPuntosRedById,
  updatePuntoDeRed,
  nuevoPuntoDeRed,
  deletePuntoDeRed,
  getPuntosAsExcel,
};

function getPuntosAsExcel(request) {
  const axios = createAxios();
  let urlPath = `/puntosRedEntrega/exportar`;
  if (request && request.autocomplete) {
    urlPath += `?autocomplete=${request.autocomplete}`;
  }
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getPuntosRedByPostalCode(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/puntosRed`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0";
  }
  if (filters && filters.autocomplete) {
    urlPath += `&autocomplete=${filters.autocomplete}`;
  }
  if (filters && filters.codigoPostal) {
    urlPath += `&codigoPostal=${filters.codigoPostal}`;
  }
  if (filters && filters.courier) {
    urlPath += `&courier=${filters.courier}`;
  }
  if (filters && filters.poblacion) {
    urlPath += `&poblacion=${filters.poblacion}`;
  }
  if (filters && filters.nombre) {
    urlPath += `&nombre=${filters.nombre}`;
  }
  if (filters && filters.codigo) {
    urlPath += `&codigo=${filters.codigo}`;
  }
  if (filters && filters.tipo) {
    urlPath += `&tipo=${filters.tipo}`;
  }
  if (filters && filters.idFranquicia) {
    urlPath += `&franquiciaId=${filters.idFranquicia}`;
  }

  return axios.get(urlPath);
}

function updatePuntoDeRed(idPuntoDeRed, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/puntoRed/${idPuntoDeRed}`;
  return axios.put(urlPath, body);
}
function nuevoPuntoDeRed(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/puntoRed`;
  return axios.post(urlPath, body);
}

function getPuntosRedById(puntoId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/puntoRed/${puntoId}`;
  return axios.get(urlPath);
}

function deletePuntoDeRed(puntoId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/puntoRed/${puntoId}`;
  return axios.delete(urlPath);
}
