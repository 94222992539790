import { geograficoService } from "@/_services";
export const geografico = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {}
    },
    nuevo: {
      data: [],
      status: {}
    },
    update: {
      data: [],
      status: {}
    },
    geografico: {
      data: [],
      status: {}
    },
  },
  mutations: {
    getGeograficoRequest(state) {
      state.list.status = {
        loading: true
      }
    },
    getGeograficoSuccess(state, response) {
      state.list.data = response
      state.list.status = {
        loaded: true
      }
    },
    getGeograficoError(state, error) {
      state.list.data = null
      state.list.status = {
        error
      }
    },
    deleteGeograficoRequest(state) {
      state.delete.status = {
        erasing: true,
      };
    },
    deleteGeograficoError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteGeograficoSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        erased: true,
      };
    },
    nuevoGeograficoRequest(state) {
      state.nuevo.status = {
        loading: true,
      };
    },
    nuevoGeograficoError(state, error) {
      state.nuevo.data = null;
      state.nuevo.status = {
        error,
      };
    },
    nuevoGeograficoSuccess(state, response) {
      state.nuevo.data = response;
      state.nuevo.status = {
        loaded: true,
      };
    },
    updateGeograficoRequest(state) {
      state.update.status = {
        loading: true,
      };
    },
    updateGeograficoError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateGeograficoSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        loaded: true,
      };
    },
    getGeograficoByIdRequest(state) {
      state.geografico.status = {
        loading: true,
      };
    },
    getGeograficoByIdError(state, error) {
      state.geografico.data = null;
      state.geografico.status = {
        error,
      };
    },
    getGeograficoByIdSuccess(state, response) {
      state.geografico.data = response;
      state.geografico.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getGeografico({ commit }, request ) {
      commit('getGeograficoRequest');
      geograficoService
        .getGeografico(request.pagination,request.query)
        .then((response) => {
          commit('getGeograficoSuccess', response);
        })
        .catch((error) => {
          commit('getGeograficoError', error);
        });
    },
    deleteGeografico({ commit }, id) {
      commit('deleteGeograficoRequest');
      geograficoService.deleteGeografico(id)
        .then((Res) => {
          commit('deleteGeograficoSuccess', Res);
        })
        .catch((error) => {
          commit('deleteGeograficoError', error);
        });
    },
    nuevoGeografico({ commit }, request) {
      commit('nuevoGeograficoRequest');
      geograficoService.nuevoGeografico(request)
        .then((Res) => {
          commit('nuevoGeograficoSuccess', Res);
        })
        .catch((error) => {
          commit('nuevoGeograficoError', error);
        });
    },
    updateGeografico({ commit }, request) {
      commit('updateGeograficoRequest');
      geograficoService.updateGeografico(request)
        .then((Res) => {
          commit('updateGeograficoSuccess', Res);
        })
        .catch((error) => {
          commit('updateGeograficoError', error);
        });
    },
    getGeograficoById({ commit }, id) {
      commit('getGeograficoByIdRequest');
      geograficoService.getGeograficoById(id)
        .then((Res) => {
          commit('getGeograficoByIdSuccess', Res);
        })
        .catch((error) => {
          commit('getGeograficoByIdError', error);
        });
    },
  },
};
