import { otrosService } from '@/_services';

export const otrosMasivos = {
  namespaced: true,
  state: {
    download: {
      data: {},
      status: {}
    },
    list: {
      data: {},
      status: {}
    },
  },
  mutations: {
    getOtrosDownloadRequest(state) {
      state.download.status = {
        downloading: true,
      };
    },
    getOtrosDownloadError(state, error) {
      state.download.data = {};
      state.download.status = {
        error,
      };
    },
    getOtrosDownloadSuccess(state, response) {
      state.download.data = response;
      state.download.status = {
        downloaded: true,
      };
    },
    getOtrosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getOtrosError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getOtrosSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getOtrosMasivos({ commit }, req) {
      commit('getOtrosRequest');
      otrosService.getOtrosMasivos(req.pagination, req.filters)
        .then((response) => {
          commit('getOtrosSuccess', response);
        }).catch((error) => {
          commit('getOtrosError', error);
        });
    },
    getOtrosDownload({ commit }, idOtros) {
      commit('getOtrosDownloadRequest');
      otrosService
        .getOtrosDownload(idOtros)
        .then((response) => {
          commit('getOtrosDownloadSuccess', response);
        })
        .catch((error) => {
          commit('getOtrosDownloadError', error);
        });
    },
  },
};