import createAxios from './axiosHttpMarconi';

function getNotificacionesIntegraciones(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciaId}/notificaciones`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += '?page=0&limit=20';
  }

  urlPath += getFilters(filters)
  return axios.get(urlPath);
}

function getFilters(filters) {
  let urlPath = ''

  if (filters && filters.to) {
    urlPath += `&to=${filters.to}`;
  }

  if (filters && filters.from) {
    urlPath += `&from=${filters.from}`;
  }

  if (filters && filters.template) {
    urlPath += `&template=${filters.template}`;
  }

  if (filters && filters.status && filters.status.length > 0) {
    urlPath += `&status=${filters.status.join()}`;
  }
  return urlPath
}


export const integracionService = {
  getNotificacionesIntegraciones
};