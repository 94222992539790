var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c("div", { staticClass: "card-content" }, [
      _c("div", { staticClass: "table-full-width table-tasks" }, [
        _c("table", { staticClass: "table" }, [
          _c(
            "tbody",
            _vm._l(_vm.tasks, function (task, index) {
              return _c("task", {
                key: task.title,
                attrs: { task: task, index: index },
                on: {
                  "on-edit": _vm.handleTaskEdit,
                  "on-delete": _vm.handleTaskDelete,
                },
              })
            }),
            1
          ),
        ]),
      ]),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Tasks")]),
      _c("p", { staticClass: "category" }, [_vm._v("Backend development")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-footer" }, [
      _c("hr"),
      _c("div", { staticClass: "stats" }, [
        _c("i", { staticClass: "fa fa-history" }),
        _vm._v(" Updated 3 minutes ago "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }