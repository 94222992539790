import { createAxios } from "./axiosHttpMarconi";
export const franquiciaService = {
  activarDesactivarFranquicia,
  getFranquicias,
  getFranquiciasById,
  updateFranquicia,
  newFranquicia,
  newConfiguracionFranquicia,
  updateConfiguracionFranquicia,
  getDataFranquiciaAdicional,
  integracionCliente,
  cuentaFranquicia,
  getBannerFactura,
  deleteBannerFactura,
  postBannerFactura,
};

function integracionCliente(body) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${franquiciadora.extraData.franquiciadoraId}/cliente/${body._id}/integracion`;
  return axios.put(urlPath, body);
}

function newFranquicia(body) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${franquiciadora.extraData.franquiciadoraId}/franquicia`;
  return axios.post(urlPath, body);
}
function newConfiguracionFranquicia(body) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${franquiciadora.extraData.franquiciadoraId}/franquicia/configuracion`;
  return axios.post(urlPath, body);
}

function updateConfiguracionFranquicia(body) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${franquiciadora.extraData.franquiciadoraId}/franquicia/configuracion`;
  return axios.put(urlPath, body);
}

function updateFranquicia(body) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${franquiciadora.extraData.franquiciadoraId}/franquicia/${body._id}`;
  return axios.put(urlPath, body);
}

function getFranquiciasById(idFranquicia) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${franquiciadora.extraData.franquiciadoraId}/franquicia/${idFranquicia}`;
  return axios.get(urlPath);
}

function getDataFranquiciaAdicional(franquiciaId) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${franquiciadora.extraData.franquiciadoraId}/franquicia/${franquiciaId}/configuracion`;
  return axios.get(urlPath);
}

function getFranquicias(pagination, filters) {
  const axios = createAxios();
  let user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/franquicia`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.nombre) {
    urlPath += `&nombre=${filters.nombre}`;
  }
  if (filters && filters.alias) {
    urlPath += `&alias=${filters.alias}`;
  }
  if (filters && filters.codigo) {
    urlPath += `&codigo=${filters.codigo}`;
  }
  if (filters && filters.status) {
    urlPath += `&activado=${filters.status}`;
  }
  return axios.get(urlPath);
}

function activarDesactivarFranquicia(user) {
  const axios = createAxios();
  let data = JSON.parse(localStorage.getItem("user"));
  const urlPath = `franquiciadora/${data.extraData.franquiciadoraId}/franquicia/${user._id}/estado`;
  return axios.put(urlPath);
}

function cuentaFranquicia(req) {
  const axios = createAxios();
  let data = JSON.parse(localStorage.getItem("user"));
  const urlPath = `franquiciadora/${data.extraData.franquiciadoraId}/franquicia/${req.id}/integracion`;
  return axios.put(urlPath, req.body);
}

function getBannerFactura(id) {
  const axios = createAxios();
  let data = JSON.parse(localStorage.getItem("user"));
  const urlPath = `franquiciadora/${data.extraData.franquiciadoraId}/franquicia/${id}/banner-factura`;
  return axios.get(urlPath);
}

function deleteBannerFactura(id) {
  const axios = createAxios();
  let data = JSON.parse(localStorage.getItem("user"));
  const urlPath = `franquiciadora/${data.extraData.franquiciadoraId}/franquicia/${id}/banner-factura`;
  return axios.delete(urlPath);
}

function postBannerFactura(id, body) {
  const axios = createAxios();
  let data = JSON.parse(localStorage.getItem("user"));
  const urlPath = `franquiciadora/${data.extraData.franquiciadoraId}/franquicia/${id}/banner-factura`;
  return axios.post(urlPath, body);
}
