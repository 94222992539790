import { extraviadosService } from "@/_services";

export const extraviados = {
  namespaced: true,
  state: {
    list: {
      data: {},
      status: {},
    },
    reparar: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getExtraviadosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getExtraviadosError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getExtraviadosSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    repararExtraviadoRequest(state) {
      state.reparar.status = {
        repairing: true,
      };
    },
    repararExtraviadoError(state, error) {
      state.reparar.data = [];
      state.reparar.status = {
        error,
      };
    },
    repararExtraviadoSuccess(state, response) {
      state.reparar.data = response;
      state.reparar.status = {
        repaired: true,
      };
    },
  },
  actions: {
    getExtraviados({ commit }, req) {
      commit("getExtraviadosRequest");
      extraviadosService
        .getExtraviados(req.pagination, req.filters)
        .then((response) => {
          commit("getExtraviadosSuccess", response);
        })
        .catch((error) => {
          commit("getExtraviadosError", error);
        });
    },
    repararExtraviado({ commit }, req) {
      commit("repararExtraviadoRequest");
      extraviadosService
        .repararExtraviado(req)
        .then((response) => {
          commit("repararExtraviadoSuccess", response);
        })
        .catch((error) => {
          commit("repararExtraviadoError", error);
        });
    },
  },
};
