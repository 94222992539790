import { createAxiosLogistica } from "./axiosHttpMarconi";

export const almacenesService = {
  getAlmacenes,
  deleteAlmacen,
  postAlmacen,
  updateAlmacen,
  getAlmacenById,
};

function getAlmacenes(pagination, filters) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/almacen`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function deleteAlmacen(almacenId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/almacen/${almacenId}`;
  return axios.delete(urlPath);
}

function postAlmacen(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/almacen`;
  return axios.post(urlPath, request);
}

function updateAlmacen(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/almacen/${id}`;
  return axios.put(urlPath, body);
}

function getAlmacenById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/almacen/${id}`;
  return axios.get(urlPath);
}
