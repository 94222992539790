import { repartidorService, } from '@/_services';
export const repartidorEventos = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    changeStateRepartidor: {
      data: [],
      status: {},
    },
    new: {
      data: [],
      status: {},
    },
    getById: {
      data: [],
      status: {},
    }
  },
  mutations: {
    getRepartidorRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelRepartidorRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        loading: false,
        error,
      };
    },
    getRepartidorSuccess(state, env) {
      state.list.data = env;
      state.list.status = {
        loaded: true,
      };
    },
    getRepartidorByIdRequest(state) {
      state.getById.status = {
        loading: true,
      };
    },
    cancelRepartidorByIdRequest(state, error) {
      state.getById.data = [];
      state.getById.status = {
        loading: false,
        error,
      };
    },
    getRepartidorByIdSuccess(state, env) {
      state.getById.data = env;
      state.getById.status = {
        loaded: true,
      };
    },
    newRepartidorRequest(state) {
      state.new.status = {
        creating: true,
      };
    },
    cancelNewRepartidorRequest(state, error) {
      state.new.data = [];
      state.new.status = {
        creating: false,
        error,
      };
    },
    newRepartidorSuccess(state, env) {
      state.new.data = env;
      state.new.status = {
        created: true,
      };
    },
    updateRepartidorRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateCancelRepartidorRequest(state, error) {
      state.update.data = [];
      state.update.status = {
        updating: false,
        error,
      };
    },
    updateRepartidorSuccess(state, env) {
      state.update.data = env;
      state.update.status = {
        updated: true,
      };
    },
    changeStateRepartidorRequest(state) {
      state.changeStateRepartidor.status = {
        deleting: true,
      };
    },
    changeStateRepartidorError(state, error) {
      state.changeStateRepartidor.data = null;
      state.changeStateRepartidor.status = {
        error,
      };
    },
    changeStateRepartidorSuccess(state, Repartidor) {
      state.changeStateRepartidor.data = Repartidor;
      state.changeStateRepartidor.status = {
        deleted: true,
      };
    },
  },
  actions: {
    getRepartidores({ commit }, req) {
      commit('getRepartidorRequest');
      repartidorService.getRepartidores(req.pagination, req.filters)
        .then((env) => {
          commit('getRepartidorSuccess', env);
        })
        .catch((error) => {
          commit('cancelRepartidorRequest', error);
        });
    },
    getRepartidorById({ commit }, repartidorId) {
      commit('getRepartidorByIdRequest');
      repartidorService.getRepartidorById(repartidorId)
        .then((env) => {
          commit('getRepartidorByIdSuccess', env);
        })
        .catch((error) => {
          commit('cancelRepartidorByIdRequest', error);
        });
    },
    newRepartidor({ commit }, body) {
      commit('newRepartidorRequest');
      repartidorService.newRepartidor(body)
        .then((env) => {
          commit('newRepartidorSuccess', env);
        })
        .catch((error) => {
          commit('cancelNewRepartidorRequest', error);
        });
    },
    updateRepartidor({ commit }, body) {
      commit('updateRepartidorRequest');
      repartidorService.updateRepartidor(body)
        .then((env) => {
          commit('updateRepartidorSuccess', env);
        })
        .catch((error) => {
          commit('updateCancelRepartidorRequest', error);
        });
    },
    activarDesactivarRepartidor({ commit }, repartidorId) {
      commit('changeStateRepartidorRequest');
      repartidorService.activarDesactivarRepartidor(repartidorId)
        .then((repartidor) => {
          commit('changeStateRepartidorSuccess', repartidor);
        })
        .catch((error) => {
          commit('changeStateRepartidorError', error);
        });
    },
  },
};