import createAxios from "./axiosHttpMarconi";
export const tarifaService = {
  getTarifasByFranquicia,
  getAllTarifas,
  getTarifasById,
  getAllTarifasAsExcel,
  getAllTarifasPartner,
  getTarifasPartnerById,
  getAllTarifasPartnerAsExcel,
  eliminarTarifas,
  getTarifasMaestros,
  getTarifaMaestroById,
  editNombreTarifa,
};

function getTarifasByFranquicia(codigo) {
  const axios = createAxios();
  const cliente = JSON.parse(localStorage.getItem("cliente"));
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/franquicia/${cliente.franquicia._id}/tarifa`;
  if (codigo && codigo != "") {
    urlPath += `?codigo=${codigo}`;
  }
  return axios.get(urlPath);
}

function getAllTarifas(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/tarifas_precios`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += getFilters(filters);
  return axios.get(urlPath);
}

function getFilters(filters) {
  let urlPath = "";
  if (filters && filters.date) {
    urlPath += `&desde=${filters.date[0]}`;
    urlPath += `&hasta=${filters.date[1]}`;
  }
  if (filters && filters.codigoTarifa) {
    urlPath += `&codigo=${filters.codigoTarifa}`;
  }
  if (filters && filters.descripcion) {
    urlPath += `&descripcion=${filters.descripcion}`;
  }
  if (filters && filters.servicio) {
    urlPath += `&servicio=${filters.servicio}`;
  }
  if (filters && filters.geografico) {
    urlPath += `&geografico=${filters.geografico}`;
  }
  if (filters.vigente != null) {
    urlPath += `&vigente=${filters.vigente}`;
  }
  if (filters.especial && filters.especial != "") {
    urlPath += `&especial=${filters.especial}`;
  }
  if (filters.central != null) {
    urlPath += `&central=${filters.central}`;
  }
  return urlPath;
}

function getTarifasById(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/tarifa_precio/${id}`;
  return axios.get(urlPath);
}

function getAllTarifasAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/tarifa_precios/excel?limit=2000`;
  urlPath += getFilters(filters);
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getAllTarifasPartner(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/tarifas_precios_partner`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += getFilters(filters);
  return axios.get(urlPath);
}

function getTarifasPartnerById(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/tarifa_precio_partner/${id}`;
  return axios.get(urlPath);
}

function getAllTarifasPartnerAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/tarifa_precios_partner/excel?limit=2000`;
  urlPath += getFilters(filters);
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function eliminarTarifas(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${
    user.extraData.franquiciadoraId
  }/tarifas_precios?ids=${request.ids.join(",")}`;
  return axios.delete(urlPath);
}

function getTarifasMaestros(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/tarifas`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }

  /* if (filters.codigo && filters.codigo != "") {
    urlPath += `&codigo=${filters.codigo}`;
  }

  if (filters.nombre && filters.nombre != "") {
    urlPath += `&nombre=${filters.nombre}`;
  } */
  return axios.get(urlPath);
}

function getTarifaMaestroById(idTarifa) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/tarifas/${idTarifa}`;
  return axios.get(urlPath);
}

function editNombreTarifa(idTarifa, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/tarifas/${idTarifa}`;
  return axios.put(urlPath, body);
}
