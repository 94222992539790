import { transitoService } from "@/_services";
export const transito = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {}
    },
    nuevo: {
      data: [],
      status: {}
    },
    update: {
      data: [],
      status: {}
    },
    transito: {
      data: [],
      status: {}
    },
    excel: {
      data: [],
      status: {}
    }
  },
  mutations: {
    getTransitoRequest(state) {
      state.list.status = {
        loading: true
      }
    },
    getTransitoSuccess(state, response) {
      state.list.data = response
      state.list.status = {
        loaded: true
      }
    },
    getTransitoError(state, error) {
      state.list.data = null
      state.list.status = {
        error
      }
    },
    deleteTransitoRequest(state) {
      state.delete.status = {
        erasing: true,
      };
    },
    deleteTransitoError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteTransitoSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        erased: true,
      };
    },
    nuevoTransitoRequest(state) {
      state.nuevo.status = {
        loading: true,
      };
    },
    nuevoTransitoError(state, error) {
      state.nuevo.data = null;
      state.nuevo.status = {
        error,
      };
    },
    nuevoTransitoSuccess(state, response) {
      state.nuevo.data = response;
      state.nuevo.status = {
        loaded: true,
      };
    },
    updateTransitoRequest(state) {
      state.update.status = {
        loading: true,
      };
    },
    updateTransitoError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateTransitoSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        loaded: true,
      };
    },
    getTransitoByIdRequest(state) {
      state.transito.status = {
        loading: true,
      };
    },
    getTransitoByIdError(state, error) {
      state.transito.data = null;
      state.transito.status = {
        error,
      };
    },
    getTransitoByIdSuccess(state, response) {
      state.transito.data = response;
      state.transito.status = {
        loaded: true,
      };
    },
    getReglasTransitoExcelRequest(state) {
      state.excel.status = {
        downloading: true,
      };
    },
    getReglasTransitoExcelError(state, error) {
      state.excel.data = {};
      state.excel.status = {
        error,
      };
    },
    getReglasTransitoExcelSuccess(state, excel) {
      state.excel.data = excel;
      state.excel.status = {
        downloaded: true,
      };
    },
  },
  actions: {
    getTransito({ commit }, request ) {
      commit('getTransitoRequest');
      transitoService
        .getTransito(request.pagination,request.query)
        .then((response) => {
          commit('getTransitoSuccess', response);
        })
        .catch((error) => {
          commit('getTransitoError', error);
        });
    },
    deleteTransito({ commit }, id) {
      commit('deleteTransitoRequest');
      transitoService.deleteTransito(id)
        .then((Res) => {
          commit('deleteTransitoSuccess', Res);
        })
        .catch((error) => {
          commit('deleteTransitoError', error);
        });
    },
    nuevoTransito({ commit }, request) {
      commit('nuevoTransitoRequest');
      transitoService.nuevoTransito(request)
        .then((Res) => {
          commit('nuevoTransitoSuccess', Res);
        })
        .catch((error) => {
          commit('nuevoTransitoError', error);
        });
    },
    updateTransito({ commit }, request) {
      commit('updateTransitoRequest');
      transitoService.updateTransito(request)
        .then((Res) => {
          commit('updateTransitoSuccess', Res);
        })
        .catch((error) => {
          commit('updateTransitoError', error);
        });
    },
    getTransitoById({ commit }, id) {
      commit('getTransitoByIdRequest');
      transitoService.getTransitoById(id)
        .then((Res) => {
          commit('getTransitoByIdSuccess', Res);
        })
        .catch((error) => {
          commit('getTransitoByIdError', error);
        });
    },
    getReglasTransitoExcel({ commit }, filters) {
      commit("getReglasTransitoExcelRequest");
      transitoService
        .getReglasTransitoExcel(filters)
        .then((excel) => {
          commit("getReglasTransitoExcelSuccess", excel);
        })
        .catch((error) => {
          commit("getReglasTransitoExcelError", error);
        });
    },
  },
};
