<template>
  <modal
    @hide="hideModal"
    class="modal-status-cexdevolucion"
    :loading="loading"
  >
    <template v-slot:header>
      <h5 class="modal-title" v-if="accion === 'editar'">
        Modificar: {{ transitoSeleccionado.servicio }}
      </h5>
      <h5 class="modal-title" v-else>Nuevo</h5>
    </template>
    <template v-slot:body>
      <el-form
        ref="transitoReferencia"
        :model="dataTransito"
        label-position="top"
        :rules="rules"
      >
        <div class="row">
          <el-form-item label="Servicios" prop="servicio" class="col-12">
            <el-select
              class="select"
              v-model="dataTransito.servicio"
              filterable
              placeholder="Seleccione una opción..."
            >
              <el-option
                v-for="item in allServicios"
                :key="item._id"
                :label="getLabel(item)"
                :value="item.codigo"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="País de Origen" prop="paisOrigen" class="col-6">
            <el-select
              class="select"
              v-model="dataTransito.paisOrigen"
              filterable
              placeholder="Seleccione una opción..."
            >
              <el-option
                v-for="item in allPaises"
                :key="item._id"
                :label="item.nombre.es"
                :value="item.iso2"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="País Destino" prop="paisDestino" class="col-6">
            <el-select
              class="select"
              v-model="dataTransito.paisDestino"
              filterable
              placeholder="Seleccione una opción..."
            >
              <el-option
                v-for="item in allPaises"
                :key="item._id"
                :label="item.nombre.es"
                :value="item.iso2"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Medidas" prop="medidas" class="col">
            <el-input
              v-model="dataTransito.medidas"
              placeholder="Medidas"
            ></el-input>
          </el-form-item>
          <el-form-item label="Peso" prop="peso" class="col">
            <el-input v-model="dataTransito.peso" placeholder="Peso"></el-input>
          </el-form-item>
          <el-form-item
            label="Arista Máxima"
            prop="mediaMaximaArista"
            class="col"
          >
            <el-input
              v-model="dataTransito.mediaMaximaArista"
              placeholder="Arista Máxima"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="hideModal">
        Cerrar
      </button>
      <button type="button" class="btn btn-primary" @click="aceptar">
        Aceptar
      </button>
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/ModalHotakauth.vue";

export default {
  components: { Modal },
  props: {
    idTransito: {
      required: true,
      default: () => "new",
    },
    accion: {},
    transitoSeleccionado: {},
  },
  data() {
    var checkDecimales = (rule, value, callback) => {
      var re = /^(\d+(\.\d{0,4})?|\.?\d{1,2})$/;
      if (re.exec(value) == null) {
        return callback(new Error("Ingrese un numero valido"));
      } else {
        return callback();
      }
    };
    return {
      loading: false,
      allServicios: [],
      allPaises: [],
      dataTransito: {
        servicio: "",
        paisOrigen: "",
        paisDestino: "",
        medidas: 0,
        peso: 0,
        mediaMaximaArista: 0,
      },
      rules: {
        servicio: [
          {
            required: true,
            message: "Seleccione un servicio!",
            trigger: "blur",
          },
        ],
        paisOrigen: [
          { required: true, message: "Seleccione un pais!", trigger: "blur" },
        ],
        paisDestino: [
          { required: true, message: "Seleccione un pais!", trigger: "blur" },
        ],
        medidas: [
          {
            required: true,
            message: "Ingrese las medidas!",
            trigger: "blur",
            validator: checkDecimales,
          },
        ],
        peso: [
          {
            required: true,
            message: "Ingrese el peso!",
            trigger: "blur",
            validator: checkDecimales,
          },
        ],
        mediaMaximaArista: [
          {
            required: true,
            message: "Ingrese media arista!",
            trigger: "blur",
            validator: checkDecimales,
          },
        ],
      },
    };
  },
  mounted() {
    this.getServicios();
    this.getPaises();
    if (this.idTransito != "new") {
      this.getTransitoById();
    }
  },
  computed: {
    nuevoStatus() {
      return this.$store.state.transito.nuevo.status;
    },
    updateStatus() {
      return this.$store.state.transito.update.status;
    },
    servicios() {
      return this.$store.state.servicios.list.status;
    },
    paisesStatus() {
      return this.$store.state.pais.list.status;
    },
    transitoStatus() {
      return this.$store.state.transito.transito.status;
    },
  },
  methods: {
    getLabel(item) {
      return item.codigo + " - " + item.descripcion;
    },
    getServicios() {
      this.$store.dispatch("servicios/getServicios", {
        pagination: null,
        query: null,
      });
    },
    getPaises() {
      this.$store.dispatch("pais/getPaises");
    },
    getTransitoById() {
      this.$store.dispatch("transito/getTransitoById", this.idTransito);
    },
    hideModal() {
      this.$emit("hide");
    },
    aceptar() {
      this.$refs["transitoReferencia"].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.idTransito == "new")
            this.$store.dispatch("transito/nuevoTransito", this.dataTransito);
          else
            this.$store.dispatch("transito/updateTransito", this.dataTransito);
        }
      });
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
  },
  watch: {
    nuevoStatus(val) {
      if (val.loaded) {
        this.notificacion("Correcto!", "Creado correctamente!", "success");
        this.$emit("guardado");
        this.hideModal();
        this.loading = false;
      }
      if (val.error) {
        this.notificacion("Error!", "Ha ocurrido un error!", "danger");
        this.loading = false;
      }
    },
    updateStatus(val) {
      if (val.loaded) {
        this.notificacion("Correcto!", "Guardado correctamente!", "success");
        this.$emit("guardado");
        this.hideModal();
        this.loading = false;
      }
      if (val.error) {
        this.notificacion("Error!", "Ha ocurrido un error!", "danger");
        this.loading = false;
      }
    },
    servicios() {
      if (this.servicios.loaded) {
        if (this.$store.state.servicios.list.data) {
          this.allServicios = this.$store.state.servicios.list.data.items;
          this.allServicios.forEach((element) => {
            element.disabled = false;
          });
        }
      }
    },
    paisesStatus(val) {
      if (val.loaded) {
        if (this.$store.state.pais.list.data) {
          this.allPaises = this.$store.state.pais.list.data.items;
        }
      }
    },
    transitoStatus(val) {
      if (val.loaded) {
        this.dataTransito = this.$store.state.transito.transito.data;
      }
    },
  },
};
</script>

<style lang="scss">
.modal-status-cexdevolucion .modal-container {
  width: 700px !important;
}
.select {
  width: 100% !important;
}
</style>
