import { solucionesService } from "@/_services";
export const solucion = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getSolucionesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getSolucionesSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getSolucionesError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    deleteSolucionRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deleteSolucionError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteSolucionSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        deleted: true,
      };
    },
    postSolucionRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postSolucionError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postSolucionSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateSolucionRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateSolucionError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateSolucionSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getSolucionByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getSolucionByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getSolucionByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getSoluciones({ commit }, request) {
      commit("getSolucionesRequest");
      solucionesService
        .getSoluciones(request.pagination, request.query)
        .then((response) => {
          commit("getSolucionesSuccess", response);
        })
        .catch((error) => {
          commit("getSolucionesError", error);
        });
    },
    deleteSolucion({ commit }, id) {
      commit("deleteSolucionRequest");
      solucionesService
        .deleteSolucion(id)
        .then((res) => {
          commit("deleteSolucionSuccess", res);
        })
        .catch((error) => {
          commit("deleteSolucionError", error);
        });
    },
    postSolucion({ commit }, request) {
      commit("postSolucionRequest");
      solucionesService
        .postSolucion(request)
        .then((res) => {
          commit("postSolucionSuccess", res);
        })
        .catch((error) => {
          commit("postSolucionError", error);
        });
    },
    updateSolucion({ commit }, request) {
      commit("updateSolucionRequest");
      solucionesService
        .updateSolucion(request.id, request.body)
        .then((res) => {
          commit("updateSolucionSuccess", res);
        })
        .catch((error) => {
          commit("updateSolucionError", error);
        });
    },
    getSolucionById({ commit }, id) {
      commit("getSolucionByIdRequest");
      solucionesService
        .getSolucionById(id)
        .then((res) => {
          commit("getSolucionByIdSuccess", res);
        })
        .catch((error) => {
          commit("getSolucionByIdError", error);
        });
    },
  },
};
