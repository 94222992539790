import { suplementoService } from '@/_services';

export const suplemento = {
  namespaced: true,
  state: {
    allSuplementos: {
      data: [],
      status: {},
    },
    suplementosCentral: {
      data: [],
      status: {},
    },
    newSuplemento: {
      data: [],
      status: {},
    },
    editarSuplemento: {
      data: [],
      status: {},
    },
    eliminarSuplemento: {
      data: [],
      status: {},
    },
    suplementosExcel: {
      data: [],
      status: {},
    }
  },
  mutations: {
    suplementoExcelRequest(state) {
      state.suplementosExcel.status = {
        loading: true
      }
    },
    suplementoExcelSuccess(state, pdf) {
      state.suplementosExcel.data = pdf
      state.suplementosExcel.status = {
        loaded: true
      }
    },
    suplementoExcelError(state, error) {
      state.suplementosExcel.data = null
      state.suplementosExcel.status = {
        error
      }
    },
    eliminarSuplementoRequest(state) {
      state.eliminarSuplemento.status = {
        loading: true,
      };
    },
    eliminarSuplementoSuccess(state, direccion) {
      state.eliminarSuplemento.data = direccion;
      state.eliminarSuplemento.status = {
        loaded: true,
      };
    },
    eliminarSuplementoError(state, error) {
      state.eliminarSuplemento.data = [];
      state.eliminarSuplemento.status = {
        error,
      };
    },
    editarSuplementoRequest(state) {
      state.editarSuplemento.status = {
        loading: true,
      };
    },
    cancelEditarSuplementoRequest(state, error) {
      state.editarSuplemento.data = [];
      state.editarSuplemento.status = {
        error,
      };
    },
    editarSuplementoSuccess(state, suplemento) {
      state.editarSuplemento.data = suplemento;
      state.editarSuplemento.status = {
        edited: true,
      };
    },

    getNewSuplementoCentralRequest(state) {
      state.newSuplemento.status = {
        loading: true,
      };
    },
    cancelNewSuplementoCentralRequest(state, error) {
      state.newSuplemento.data = [];
      state.newSuplemento.status = {
        error,
      };
    },
    getNewSuplementoCentralSuccess(state, suplemento) {
      state.newSuplemento.data = suplemento;
      state.newSuplemento.status = {
        created: true,
      };
    },
    getSuplementoCentralRequest(state) {
      state.suplementosCentral.status = {
        loading: true,
      };
    },
    cancelSuplementoCentralRequest(state, error) {
      state.suplementosCentral.data = [];
      state.suplementosCentral.status = {
        error,
      };
    },
    getSuplementoCentralSuccess(state, tarifas) {
      state.suplementosCentral.data = tarifas;
      state.suplementosCentral.status = {
        loaded: true,
      };
    },
    getAllSuplementosRequest(state) {
      state.allSuplementos.status = {
        loading: true,
      };
    },
    cancelAllSuplementosRequest(state, error) {
      state.allSuplementos.data = [];
      state.allSuplementos.status = {
        error,
      };
    },
    getAllSuplementosSuccess(state, tarifas) {
      state.allSuplementos.data = tarifas;
      state.allSuplementos.status = {
        loaded: true,
      };
    },
  },
  actions: {
    eliminarSuplementos({ commit }, requests) {
      commit('eliminarSuplementoRequest');
      suplementoService
        .eliminarSuplementos(requests)
        .then((suplemento) => {
          commit('eliminarSuplementoSuccess', suplemento);
        })
        .catch((error) => {
          commit('eliminarSuplementoError', error);
        });
    },
    getAllSuplementos({ commit }, req) {
      commit('getAllSuplementosRequest');
      suplementoService
        .getAllSuplementos(req.pagination, req.filters)
        .then((tarifas) => {
          commit('getAllSuplementosSuccess', tarifas);
        })
        .catch((error) => {
          commit('cancelAllSuplementosRequest', error);
        });
    },
    nuevoSuplemento({ commit }, req) {
      commit('getNewSuplementoCentralRequest');
      suplementoService
        .nuevoSuplemento(req)
        .then((suplementos) => {
          commit('getNewSuplementoCentralSuccess', suplementos);
        })
        .catch((error) => {
          commit('cancelNewSuplementoCentralRequest', error);
        });
    },
    editarSuplemento({ commit }, req) {
      commit('editarSuplementoRequest');
      suplementoService
        .editarSuplemento(req)
        .then((suplementos) => {
          commit('editarSuplementoSuccess', suplementos);
        })
        .catch((error) => {
          commit('cancelEditarSuplementoRequest', error);
        });
    },
    getSuplementosCentral({ commit }, req) {
      commit('getSuplementoCentralRequest');
      suplementoService
        .getSuplementosCentral()
        .then((central) => {
          commit('getSuplementoCentralSuccess', central);
        })
        .catch((error) => {
          commit('cancelSuplementoCentralRequest', error);
        });
    },
    getAllSuplementosAsExcel({ commit }, request) {
      commit('suplementoExcelRequest');
      suplementoService
        .getAllSuplementosAsExcel(request)
        .then((excel) => {
          commit('suplementoExcelSuccess', excel);
        })
        .catch((error) => {
          commit('suplementoExcelError', error);
        });
    },
  },
};

