import { userService, franquiciaService } from "@/_services";

export const user = {
  namespaced: true,
  state: {
    entity: {
      data: {},
      status: {},
    },
    editPass: {
      data: {},
      status: {},
    },
    oneShotToken: {
      data: {},
      status: {},
    },
    list: {
      data: [],
      status: {},
    },
    roles: {
      data: [],
      status: {},
    },
    nuevoUsuario: {
      data: [],
      status: {},
    },
    changeStateUser: {
      data: [],
      status: {},
    },
    enlace: {
      data: [],
      status: {},
    },
    reset: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    userById: {
      data: [],
      status: {},
    },
    updateRolesMenu: {
      data: [],
      status: {},
    },
    updateRolesMenuByQuery: {
      data: [],
      status: {},
    },
  },
  mutations: {
    newUsuarioRequest(state) {
      state.nuevoUsuario.status = {
        creating: true,
      };
    },
    newUsuarioError(state, error) {
      state.nuevoUsuario.data = null;
      state.nuevoUsuario.status = {
        error,
      };
    },
    newUsuarioSuccess(state, envio) {
      state.nuevoUsuario.data = envio;
      state.nuevoUsuario.status = {
        created: true,
      };
    },

    changeStateUsuarioRequest(state) {
      state.changeStateUser.status = {
        loading: true,
      };
    },
    changeStateUsuarioError(state, error) {
      state.changeStateUser.data = null;
      state.changeStateUser.status = {
        error,
      };
    },
    changeStateUsuarioSuccess(state, envio) {
      state.changeStateUser.data = envio;
      state.changeStateUser.status = {
        loaded: true,
      };
    },
    loginUserRequest(state) {
      state.entity.status = {
        logging: true,
      };
    },
    loginUserError(state, error) {
      state.entity.data = {};
      state.entity.status = {
        error,
      };
    },
    loginUserSuccess(state, usuario) {
      state.entity.data = usuario;
      state.entity.status = {
        logged: true,
      };
    },
    changePasswordRequest(state) {
      state.editPass.status = {
        editing: true,
      };
    },
    changePasswordError(state, error) {
      state.editPass.data = {};
      state.editPass.status = {
        error,
      };
    },
    changePasswordSuccess(state, usuario) {
      state.editPass.data = usuario;
      state.editPass.status = {
        edited: true,
      };
    },
    oneShotTokenRequest(state) {
      state.oneShotToken.data = null;
      state.oneShotToken.status = {
        loaded: false,
      };
    },
    oneShotTokenError(state, error) {
      state.oneShotToken.data = null;
      state.oneShotToken.status = {
        loaded: true,
        error: error,
      };
    },
    oneShotTokenSuccess(state, token) {
      state.oneShotToken.data = token;
      state.oneShotToken.status = {
        loaded: true,
      };
    },
    getUsuariosRequest(state) {
      state.list.status = {
        lading: true,
      };
    },
    getUsuariosError(state, error) {
      state.list.data = {};
      state.list.status = {
        error,
      };
    },
    getUsuariosSuccess(state, usuario) {
      state.list.data = usuario;
      state.list.status = {
        loaded: true,
      };
    },
    getRolesRequest(state) {
      state.roles.status = {
        loading: true,
      };
    },
    getRolesError(state, error) {
      state.roles.data = {};
      state.roles.status = {
        error,
      };
    },
    getRolesSuccess(state, usuario) {
      state.roles.data = usuario;
      state.roles.status = {
        loaded: true,
      };
    },
    getEnlaceRequest(state) {
      state.enlace.status = {
        getting: true,
      };
    },
    getEnlaceError(state, error) {
      state.enlace.data = {};
      state.enlace.status = {
        error,
      };
    },
    getEnlaceSuccess(state, user) {
      state.enlace.data = user;
      state.enlace.status = {
        getted: true,
      };
    },
    resetPasswordRequest(state) {
      state.reset.status = {
        resetting: true,
      };
    },
    resetPasswordError(state, error) {
      state.reset.data = {};
      state.reset.status = {
        error,
      };
    },
    resetPasswordSuccess(state, user) {
      state.reset.data = user;
      state.reset.status = {
        reseted: true,
      };
    },
    eliminarUserRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    eliminarUserError(state, error) {
      state.delete.data = {};
      state.delete.status = {
        error,
      };
    },
    eliminarUserSuccess(state, user) {
      state.delete.data = user;
      state.delete.status = {
        deleted: true,
      };
    },
    updateUserRequest(state) {
      state.update.status = {
        loading: true,
      };
    },
    updateUserError(state, error) {
      state.update.data = {};
      state.update.status = {
        error,
      };
    },
    updateUserSuccess(state, user) {
      state.update.data = user;
      state.update.status = {
        loaded: true,
      };
    },
    setRolesMenuRequest(state) {
      state.updateRolesMenu.status = {
        updating: true,
      };
    },
    setRolesMenuError(state, error) {
      state.updateRolesMenu.data = {};
      state.updateRolesMenu.status = {
        error,
      };
    },
    setRolesMenuSuccess(state, user) {
      state.updateRolesMenu.data = user;
      state.updateRolesMenu.status = {
        updated: true,
      };
    },
    setRolesMenuByQueryRequest(state) {
      state.updateRolesMenuByQuery.status = {
        updating: true,
      };
    },
    setRolesMenuByQueryError(state, error) {
      state.updateRolesMenuByQuery.data = {};
      state.updateRolesMenuByQuery.status = {
        error,
      };
    },
    setRolesMenuByQuerySuccess(state, user) {
      state.updateRolesMenuByQuery.data = user;
      state.updateRolesMenuByQuery.status = {
        updated: true,
      };
    },
    getUserIdRequest(state) {
      state.userById.status = {
        loading: true,
      };
    },
    getUserIdError(state, error) {
      state.userById.data = {};
      state.userById.status = {
        error,
      };
    },
    getUserIdSuccess(state, user) {
      state.userById.data = user;
      state.userById.status = {
        loaded: true,
      };
    },
  },

  actions: {
    login({ commit }, req) {
      commit("loginUserRequest");
      userService
        .login(req.user, req.password)
        .then((res) => {
          commit("loginUserSuccess", res);
        })
        .catch((error) => {
          commit("loginUserError", error);
        });
    },
    changePassword({ commit }, body) {
      commit("changePasswordRequest");
      userService
        .changePassword(body)
        .then((res) => {
          commit("changePasswordSuccess", res);
        })
        .catch((error) => {
          commit("changePasswordError", error);
        });
    },
    oneShotToken({ commit }, body) {
      commit("oneShotTokenRequest");
      userService
        .oneShotToken(body)
        .then((res) => {
          commit("oneShotTokenSuccess", res["token"]);
        })
        .catch((error) => {
          commit("oneShotTokenError", error);
        });
    },
    getUsuarios({ commit }, req) {
      commit("getUsuariosRequest");
      userService
        .getUsuarios(req.filters, req.pagination)
        .then((usuarios) => {
          commit("getUsuariosSuccess", usuarios);
        })
        .catch((error) => {
          commit("getUsuariosError", error);
        });
    },
    getRoles({ commit }, roles) {
      commit("getRolesRequest");
      userService
        .getRoles(roles)
        .then((response) => {
          commit("getRolesSuccess", response);
        })
        .catch((error) => {
          commit("getRolesError", error);
        });
    },
    nuevoUsuario({ commit }, req) {
      commit("newUsuarioRequest");
      userService
        .nuevoUsuario(req)
        .then((env) => {
          commit("newUsuarioSuccess", env);
        })
        .catch((error) => {
          commit("newUsuarioError", error);
        });
    },
    cambiarEstadoUser({ commit }, user) {
      commit("changeStateUsuarioRequest");
      userService
        .cambiarEstadoUser(user)
        .then((usuario) => {
          commit("changeStateUsuarioSuccess", usuario);
        })
        .catch((error) => {
          commit("changeStateUsuarioError", error);
        });
    },
    getEnlaceRecuperacion({ commit }, body) {
      commit("getEnlaceRequest");
      userService
        .getEnlaceRecuperacion(body)
        .then((user) => {
          commit("getEnlaceSuccess", user);
        })
        .catch((error) => {
          commit("getEnlaceError", error);
        });
    },
    resetLogin({ commit }, request) {
      commit("resetPasswordRequest");
      userService
        .resetPassword(request.body, request.token)
        .then((user) => {
          commit("resetPasswordSuccess", user);
        })
        .catch((error) => {
          commit("resetPasswordError", error);
        });
    },
    eliminarUser({ commit }, id) {
      commit("eliminarUserRequest");
      userService
        .eliminarUser(id)
        .then((user) => {
          commit("eliminarUserSuccess", user);
        })
        .catch((error) => {
          commit("eliminarUserError", error);
        });
    },
    updateUser({ commit }, request) {
      commit("updateUserRequest");
      userService
        .updateUser(request)
        .then((user) => {
          commit("updateUserSuccess", user);
        })
        .catch((error) => {
          commit("updateUserError", error);
        });
    },
    setRolesMenu({ commit }, request) {
      commit("setRolesMenuRequest");
      userService
        .setRolesMenu(request.id, request.body)
        .then((user) => {
          commit("setRolesMenuSuccess", user);
        })
        .catch((error) => {
          commit("setRolesMenuError", error);
        });
    },
    setRolesMenuByQuery({ commit }, request) {
      commit("setRolesMenuByQueryRequest");
      userService
        .setRolesMenuByQuery(request.query, request.body)
        .then((user) => {
          commit("setRolesMenuByQuerySuccess", user);
        })
        .catch((error) => {
          commit("setRolesMenuByQueryError", error);
        });
    },
    getUserId({ commit }, id) {
      commit("getUserIdRequest");
      userService
        .getUserId(id)
        .then((user) => {
          commit("getUserIdSuccess", user);
        })
        .catch((error) => {
          commit("getUserIdError", error);
        });
    },
  },
};
