import { plataformaIntegracionService } from "@/_services";

export const plataforma = {
  namespaced: true,
  state: {
    plataformaConfigList: {
      data: [],
      status: {},
    },
    new: {
      data: [],
      status: {},
    },
    delete:{
      data: [],
      status: {},
    },
    integracionConfig:{
      data: {},
      status: {},
    },
    update:{
      data: {},
      status: {},
    },
    entity: {
      data: {},
      status: {},
    },
    plataformaIntegracion: {
      data: {},
      status: {},
    },
    reencolar: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getIntegracionConfigRequest(state) {
      state.plataformaConfigList.status = {
        loading: true,
      };
    },
    getIntegracionConfigError(state, error) {
      state.plataformaConfigList.data = null;
      state.plataformaConfigList.status = {
        error,
      };
    },
    getIntegracionConfigSuccess(state, list) {
      state.plataformaConfigList.data = list;
      state.plataformaConfigList.status = {
        loaded: true,
      };
    },
    newPlataformaIntegracionconfigRequest(state) {
      state.new.status = {
        loading: true,
      };
    },
    newPlataformaIntegracionconfigError(state, error) {
      state.new.data = null;
      state.new.status = {
        error,
      };
    },
    newPlataformaIntegracionconfigSuccess(state, list) {
      state.new.data = list;
      state.new.status = {
        loaded: true,
      };
    },
    eliminarIntegracionConfigRequest(state) {
      state.delete.status = {
        loading: true,
      };
    },
    eliminarIntegracionConfigError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    eliminarIntegracionConfigSuccess(state, list) {
      state.delete.data = list;
      state.delete.status = {
        loaded: true,
      };
    },
    getIntegracionConfigByIdRequest(state) {
      state.integracionConfig.status = {
        loading: true,
      };
    },
    getIntegracionConfigByIdError(state, error) {
      state.integracionConfig.data = null;
      state.integracionConfig.status = {
        error,
      };
    },
    getIntegracionConfigByIdSuccess(state, list) {
      state.integracionConfig.data = list;
      state.integracionConfig.status = {
        loaded: true,
      };
    },
    updatePlataformaIntegracionConfigRequest(state) {
      state.update.status = {
        loading: true,
      };
    },
    updatePlataformaIntegracionConfigError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updatePlataformaIntegracionConfigSuccess(state, list) {
      state.update.data = list;
      state.update.status = {
        loaded: true,
      };
    },
    getPlataformaIntegracionEstadoRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getPlataformaIntegracionEstadoError(state, error) {
      state.entity.data = [];
      state.entity.status = {
        error,
      };
    },
    getPlataformaIntegracionEstadoSuccess(state, fechas) {
      state.entity.data = fechas;
      state.entity.status = {
        loaded: true,
      };
    },
    getPlataformaIntegracionRequest(state) {
      state.plataformaIntegracion.status = {
        loading: true,
      };
    },
    getPlataformaIntegracionError(state, error) {
      state.plataformaIntegracion.data = [];
      state.plataformaIntegracion.status = {
        error,
      };
    },
    getPlataformaIntegracionSuccess(state, fechas) {
      state.plataformaIntegracion.data = fechas;
      state.plataformaIntegracion.status = {
        loaded: true,
      };
    },
    reencolarPlataformaEnvioRequest(state) {
      state.reencolar.status = {
        loading: true,
      };
    },
    reencolarPlataformaEnvioError(state, error) {
      state.reencolar.data = [];
      state.reencolar.status = {
        error,
      };
    },
    reencolarPlataformaEnvioSuccess(state, estado) {
      state.reencolar.data = estado;
      state.reencolar.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getIntegracionConfig({ commit }, request) {
      commit('getIntegracionConfigRequest');
      plataformaIntegracionService.getIntegracionConfig(request.pagination, request.filters)
        .then((Res) => {
          commit('getIntegracionConfigSuccess', Res);
        })
        .catch((error) => {
          commit('getIntegracionConfigError', error);
        });
    },
    newPlataformaIntegracionconfig({ commit }, request) {
      commit('newPlataformaIntegracionconfigRequest');
      plataformaIntegracionService.newPlataformaIntegracionconfig(request)
        .then((Res) => {
          commit('newPlataformaIntegracionconfigSuccess', Res);
        })
        .catch((error) => {
          commit('newPlataformaIntegracionconfigError', error);
        });
    },
    eliminarIntegracionConfig({ commit }, id) {
      commit('eliminarIntegracionConfigRequest');
      plataformaIntegracionService.eliminarIntegracionConfig(id)
        .then((Res) => {
          commit('eliminarIntegracionConfigSuccess', Res);
        })
        .catch((error) => {
          commit('eliminarIntegracionConfigError', error);
        });
    },
    getIntegracionConfigById({ commit }, id) {
      commit('getIntegracionConfigByIdRequest');
      plataformaIntegracionService.getIntegracionConfigById(id)
        .then((Res) => {
          commit('getIntegracionConfigByIdSuccess', Res);
        })
        .catch((error) => {
          commit('getIntegracionConfigByIdError', error);
        });
    },
    updatePlataformaIntegracionconfig({ commit }, body) {
      commit('updatePlataformaIntegracionConfigRequest');
      plataformaIntegracionService.updatePlataformaIntegracionConfig(body)
        .then((Res) => {
          commit('updatePlataformaIntegracionConfigSuccess', Res);
        })
        .catch((error) => {
          commit("updatePlataformaIntegracionError", error);
        });
    },
    reencolarPlataformaIntegracion({ commit }, body) {
      commit("reencolarPlataformaEnvioRequest");
      plataformaIntegracionService
        .reencolarPlataformaIntegracion(body)
        .then((fecha) => {
          commit("reencolarPlataformaEnvioSuccess", fecha);
        })
        .catch((error) => {
          commit("reencolarPlataformaEnvioError", error);
        });
    },
    getEstadoPlataformaIntegracion({ commit }, id) {
      commit("getPlataformaIntegracionEstadoRequest");
      plataformaIntegracionService
        .getEstadoPlataformaIntegracion(id)
        .then((plataformaIntegracion) => {
          commit("getPlataformaIntegracionEstadoSuccess", plataformaIntegracion);
        })
        .catch((error) => {
          commit("getPlataformaIntegracionEstadoError", error);
        });
    },
    getPlataformaIntegracion({ commit }, req) {
      commit("getPlataformaIntegracionRequest");
      plataformaIntegracionService
        .getPlataformaIntegracion(req.pagination, req.filters)
        .then((envios) => {
          commit("getPlataformaIntegracionSuccess", envios);
        })
        .catch((error) => {
          commit("getPlataformaIntegracionError", error);
        });
    },
  },
};
