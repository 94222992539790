var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", class: { "nav-open": _vm.$sidebar.showSidebar } },
    [
      _c("notifications"),
      _c(
        "side-bar",
        { attrs: { backgroundColor: _vm.colorBackground, id: "sidebar" } },
        [
          _c("mobile-menu"),
          _vm.menus.length > 0 && this.userLogged
            ? _c(
                "template",
                { slot: "links" },
                [
                  _vm.isMenuVisible("/operativa")
                    ? _c(
                        "sidebar-item",
                        {
                          attrs: {
                            hideItem: _vm.ocultarItem,
                            link: {
                              name: "Operativa",
                              icon: "nc-icon nc-delivery-fast",
                              path: "*",
                            },
                          },
                        },
                        [
                          _vm.isMenuVisible("/franquiciadora/envios")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Envíos",
                                    path: "/franquiciadora/envios",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/rma")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "RMA",
                                    path: "/franquiciadora/rma",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/extraviados")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Extraviados",
                                    path: "/franquiciadora/extraviados",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/envios-agrupados")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Envíos agrupados",
                                    path: "/franquiciadora/envios-agrupados",
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMenuVisible("/movilidad")
                    ? _c(
                        "sidebar-item",
                        {
                          attrs: {
                            hideItem: _vm.ocultarItem,
                            link: {
                              name: "Movilidad",
                              icon: "nc-icon nc-compass-05",
                              path: "*",
                            },
                          },
                        },
                        [
                          _vm.isMenuVisible("/franquiciadora/trafico")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "General",
                                    path: "/franquiciadora/trafico",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/recogidas")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Recogidas",
                                    path: "/franquiciadora/recogidas",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/entregas")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Entregas",
                                    path: "/franquiciadora/entregas",
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMenuVisible("/partner")
                    ? _c(
                        "sidebar-item",
                        {
                          attrs: {
                            hideItem: _vm.ocultarItem,
                            link: {
                              name: "Partner",
                              icon: "nc-icon nc-globe-2",
                              path: "*",
                            },
                          },
                        },
                        [
                          _vm.isMenuVisible("/franquiciadora/partner/salidas")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Salidas",
                                    path: "/franquiciadora/partner/salidas",
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMenuVisible("/franquiciadora/incidencias")
                    ? _c("sidebar-item", {
                        attrs: {
                          hideItem: _vm.ocultarItem,
                          link: {
                            name: "Gestión de Incidencias",
                            icon: "nc-icon nc-settings-tool-66",
                            path: "/franquiciadora/incidencias",
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.isMenuVisible("/importaciones")
                    ? _c(
                        "sidebar-item",
                        {
                          attrs: {
                            hideItem: _vm.ocultarItem,
                            link: {
                              name: "Importaciones",
                              icon: "nc-icon nc-cloud-upload-94",
                              path: "*",
                            },
                          },
                        },
                        [
                          _vm.isMenuVisible("/franquiciadora/importaciones")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Envios",
                                    path: "/franquiciadora/importaciones",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/importacionesRma")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "RMA",
                                    path: "/franquiciadora/importacionesRma",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible(
                            "/franquiciadora/importacionesOtros"
                          )
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Otros",
                                    path: "/franquiciadora/importacionesOtros",
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMenuVisible("/facturacion")
                    ? _c(
                        "sidebar-item",
                        {
                          attrs: {
                            hideItem: _vm.ocultarItem,
                            link: {
                              name: "Facturación",
                              icon: "nc-icon nc-paper-2",
                              path: "*",
                            },
                          },
                        },
                        [
                          _vm.isMenuVisible(
                            "/franquiciadora/control-valoracion"
                          )
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Control de Valoraciones",
                                    path: "/franquiciadora/control-valoracion",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/tarifas-cliente")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Tarifas Cliente",
                                    path: "/franquiciadora/tarifas-cliente",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/tarifas")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Tarifas Franquicia",
                                    path: "/franquiciadora/tarifas",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/tarifasPartner")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Tarifas Partner",
                                    path: "/franquiciadora/tarifasPartner",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/documentacion")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Documentación",
                                    path: "/franquiciadora/documentacion",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/fichero-master")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Ficheros master",
                                    path: "/franquiciadora/fichero-master",
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMenuVisible("/maestros")
                    ? _c(
                        "sidebar-item",
                        {
                          attrs: {
                            hideItem: _vm.ocultarItem,
                            link: {
                              name: "Maestros",
                              icon: "nc-icon nc-layers-3",
                              path: "*",
                            },
                          },
                        },
                        [
                          _vm.isMenuVisible("/franquiciadora/puntosred")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Puntos de Red",
                                    path: "/franquiciadora/puntosred",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/estados")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Estados",
                                    path: "/franquiciadora/estados",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible(
                            "/franquiciadora/estados-parada-envio"
                          )
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Servicios Parada",
                                    path: "/franquiciadora/estados-parada-envio",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/estados-paradas")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Estados Paradas",
                                    path: "/franquiciadora/estados-paradas",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/servicios")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Servicios",
                                    path: "/franquiciadora/servicios",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible(
                            "/franquiciadora/geografico-servicios"
                          )
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Geográfico Servicios",
                                    path: "/franquiciadora/geografico-servicios",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/fecha-cierre")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Fecha Cierre",
                                    path: "/franquiciadora/fecha-cierre",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/regla-transito")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Reglas de Tránsito",
                                    path: "/franquiciadora/regla-transito",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/borrados")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Borrados",
                                    path: "/franquiciadora/borrados",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/control-vistas")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Vistas Control",
                                    path: "/franquiciadora/control-vistas",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/envios-vistas")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Vistas entregas/recogidas",
                                    path: "/franquiciadora/envios-vistas",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/impuestos")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Impuestos",
                                    path: "/franquiciadora/impuestos",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/soluciones")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Soluciones",
                                    path: "/franquiciadora/soluciones",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/soluciones-reglas")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Soluciones Reglas",
                                    path: "/franquiciadora/soluciones-reglas",
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMenuVisible("/franquiciadora/franquicias")
                    ? _c("sidebar-item", {
                        attrs: {
                          hideItem: _vm.ocultarItem,
                          link: {
                            name: "Franquicias",
                            icon: "nc-icon nc-bank",
                            path: "/franquiciadora/franquicias",
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.isMenuVisible("/franquiciadora/clientes")
                    ? _c("sidebar-item", {
                        attrs: {
                          hideItem: _vm.ocultarItem,
                          link: {
                            name: "Clientes",
                            icon: "nc-icon nc-single-02",
                            path: "/franquiciadora/clientes",
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.isMenuVisible("/Mobile")
                    ? _c(
                        "sidebar-item",
                        {
                          attrs: {
                            hideItem: _vm.ocultarItem,
                            link: {
                              name: "Mobile",
                              icon: "nc-icon nc-mobile",
                              path: "*",
                            },
                          },
                        },
                        [
                          _vm.isMenuVisible("/franquiciadora/eventos")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Eventos",
                                    path: "/franquiciadora/eventos",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible(
                            "/franquiciadora/gestion-configuracion"
                          )
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Configuración",
                                    path: "/franquiciadora/gestion-configuracion",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible(
                            "/franquiciadora/repartidores-realizan-eventos"
                          )
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Repartidores",
                                    path: "/franquiciadora/repartidores-realizan-eventos",
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMenuVisible("/integraciones")
                    ? _c(
                        "sidebar-item",
                        {
                          attrs: {
                            hideItem: _vm.ocultarItem,
                            link: {
                              name: "Integraciones",
                              icon: "nc-icon nc-puzzle-10",
                              path: "*",
                            },
                          },
                        },
                        [
                          _vm.isMenuVisible("/franquiciadora/error-courier")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Errores Courier",
                                    path: "/franquiciadora/error-courier",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/ficheros-sorter")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Ficheros Sorter",
                                    path: "/franquiciadora/ficheros-sorter",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible(
                            "/franquiciadora/plataforma-integracion"
                          )
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Plataforma Integración",
                                    path: "/franquiciadora/plataforma-integracion",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible(
                            "/franquiciadora/configuracion-integracion"
                          )
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Plataforma Integración Config",
                                    path: "/franquiciadora/configuracion-integracion",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible(
                            "/franquiciadora/renombrar-servicios"
                          )
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Servicios Renombrados",
                                    path: "/franquiciadora/renombrar-servicios",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/servicios-seur")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Servicios Seur",
                                    path: "/franquiciadora/servicios-seur",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/mobile-queue")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Mobile Queue",
                                    path: "/franquiciadora/mobile-queue",
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMenuVisible("/notificaciones")
                    ? _c(
                        "sidebar-item",
                        {
                          attrs: {
                            hideItem: _vm.ocultarItem,
                            link: {
                              name: "Notificaciones",
                              icon: "nc-icon nc-bell-55",
                              path: "",
                            },
                          },
                        },
                        [
                          _vm.isMenuVisible(
                            "/franquiciadora/integraciones-notificaciones"
                          )
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Email",
                                    path: "/franquiciadora/integraciones-notificaciones",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible(
                            "/franquiciadora/notificaciones-dashboard"
                          )
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Portales",
                                    path: "/franquiciadora/notificaciones-dashboard",
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMenuVisible("/autorizacion")
                    ? _c(
                        "sidebar-item",
                        {
                          attrs: {
                            hideItem: _vm.ocultarItem,
                            link: {
                              name: "Autorización",
                              icon: "nc-icon nc-key-25",
                              path: "*",
                            },
                          },
                        },
                        [
                          _vm.isMenuVisible("/franquiciadora/autorizacion")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Api Keys",
                                    path: "/franquiciadora/autorizacion",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/usuarios")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Usuarios",
                                    path: "/franquiciadora/usuarios",
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.isMenuVisible("/franquiciadora/menu-roles")
                            ? _c("sidebar-item", {
                                attrs: {
                                  hideItem: _vm.ocultarItem,
                                  link: {
                                    name: "Menú roles",
                                    path: "/franquiciadora/menu-roles",
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "main-panel" },
        [
          _c("top-navbar"),
          _c("dashboard-content", { on: { click: _vm.toggleSidebar } }),
          _c("content-footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }