<template>
  <modal @hide="hideModal" class="modal-status-geo_servicio" :loading="loading">
    <template v-slot:header>
      <h5 class="modal-title">
        {{ title }}
      </h5>
    </template>
    <template v-slot:body>
      <el-form
        ref="geograficoRef"
        :model="dataGeografico"
        label-position="top"
        :rules="rules"
      >
        <div class="row">
          <el-form-item
            class="input-class col-6"
            prop="geografico"
            label="Geográfico"
          >
            <el-input
              :disabled="idGeo === 'new' ? false : true"
              v-model="dataGeografico.tipo"
              placeholder="Ingrese el geográfico"
            ></el-input>
          </el-form-item>
          <el-form-item prop="servicios" label="Servicios" class="col-6">
            <el-select
              v-model="dataGeografico.servicios"
              placeholder="Selecciona un servicio"
              class="select"
              multiple
              filterable
            >
              <el-option
                v-for="item in allServicios"
                :key="item._id"
                :label="getLabel(item)"
                :value="item.codigo"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-tabs
          element-loading-text="Guardando..."
          v-model="activeName"
          class="tabs"
        >
          <el-tab-pane label="Excepciones" name="excepciones">
            <div class="row">
              <el-table
                style="width: 100%"
                :data="dataGeografico.serviciosDefecto"
              >
                <el-table-column label="Origen" prop="origen">
                  <template v-slot="scope">
                    <el-select
                      v-model="scope.row.origen"
                      placeholder="Selecciona un servicio"
                      class="select"
                      filterable
                    >
                      <el-option
                        v-for="item in arrayOrigen"
                        :key="item._id"
                        :label="getLabel(item)"
                        :value="item.codigo"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="Reemplazo" prop="reemplazo">
                  <template v-slot="scope">
                    <el-select
                      v-model="scope.row.reemplazo"
                      placeholder="Selecciona un servicio"
                      class="select"
                      filterable
                    >
                      <el-option
                        v-for="item in arrayReemplazo"
                        :key="item._id"
                        :label="getLabel(item)"
                        :value="item.codigo"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column width="100">
                  <template v-slot="scope">
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDelete(scope.$index, scope.row)"
                      icon="el-icon-delete"
                    ></el-button>
                  </template>
                </el-table-column>
                <el-table-column width="100">
                  <template v-slot="scope">
                    <el-button
                      v-if="verBotonNuevaLinea(scope)"
                      @click="addLinea(scope.row)"
                      >+</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Excepciones medidas" name="medidas">
            <div class="row">
              <el-table
                style="width: 100%"
                :data="dataGeografico.serviciosDefectoMedidas"
              >
                <el-table-column label="Origen" prop="origen">
                  <template v-slot="scope">
                    <el-select
                      v-model="scope.row.origen"
                      placeholder="Selecciona un servicio"
                      class="select"
                      filterable
                    >
                      <el-option
                        v-for="item in arrayOrigen"
                        :key="item._id"
                        :label="getLabel(item)"
                        :value="item.codigo"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="Reemplazo" prop="reemplazo">
                  <template v-slot="scope">
                    <el-select
                      v-model="scope.row.reemplazo"
                      placeholder="Selecciona un servicio"
                      class="select"
                      filterable
                    >
                      <el-option
                        v-for="item in arrayOrigen"
                        :key="item._id"
                        :label="getLabel(item)"
                        :value="item.codigo"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="Peso" prop="peso">
                  <template v-slot="scope">
                    <el-input
                      placeholder="Ingrese el peso"
                      v-model="scope.row.peso"
                      type="number"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="Medidas" prop="medidas">
                  <template v-slot="scope">
                    <el-input
                      type="number"
                      placeholder="Ingrese medidas"
                      v-model="scope.row.medidas"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column width="100">
                  <template v-slot="scope">
                    <el-button
                      size="mini"
                      type="danger"
                      @click="handleDeleteMedidas(scope.$index, scope.row)"
                      icon="el-icon-delete"
                    ></el-button>
                  </template>
                </el-table-column>
                <el-table-column width="100">
                  <template v-slot="scope">
                    <el-button
                      v-if="verBotonNuevaLineaMedidas(scope)"
                      @click="addLineaMedidas(scope.row)"
                      >+</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="hideModal">
        Cerrar
      </button>
      <button type="button" class="btn btn-primary" @click="aceptar">
        Aceptar
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/ModalHotakauth.vue";

export default {
  components: { Modal },
  props: {
    idGeo: {
      default: () => "",
    },
  },
  data() {
    return {
      serviciosMedidas: [
        {
          origen: "",
          reemplazo: "",
          peso: 0.0,
          medidas: 0.0,
        },
      ],
      activeName: "excepciones",
      geograficoList: [
        "ANDORRA",
        "AZORES",
        "AZORES_INTERISLAS",
        "BALEARES_INTERISLAS",
        "BALEARES_ISLAS_MAYORES",
        "BALEARES_ISLAS_MENORES",
        "CANARIAS_INTERISLAS",
        "CANARIAS_ISLAS_MAYORES",
        "CANARIAS_ISLAS_MENORES",
        "CEUTA",
        "GIBRALTAR",
        "LOCAL",
        "MADEIRA",
        "MADEIRA_INTERISLAS",
        "MELILLA",
        "NACIONAL",
        "PENINSULAR_ES-PT",
        "PROVINCIAL",
        "REGIONAL",
        "ESTANDAR_EURO_1",
        "ESTANDAR_EURO_2",
        "ESTANDAR_EURO_3",
        "ESTANDAR_EURO_4",
        "ESTANDAR_EURO_5",
        "ESTANDAR_EURO_6",
        "EXPRESS_ZONA_1",
        "EXPRESS_ZONA_2",
        "EXPRESS_ZONA_3",
        "EXPRESS_ZONA_4",
        "EXPRESS_ZONA_5",
        "EXPRESS_ZONA_6",
        "EXPRESS_ZONA_7",
        "EXPRESS_ZONA_8",
        "EXPRESS_ZONA_9",
        "EUROPACK_EURO_1",
        "EUROPACK_EURO_2",
        "EUROPACK_EURO_3",
        "EUROPACK_EURO_4",
        "EUROPACK_EURO_5",
        "EUROPACK_EURO_6",
        "INTEREXPRESSPLUS_ZONA_1",
        "INTEREXPRESSPLUS_ZONA_10",
        "INTEREXPRESSPLUS_ZONA_11",
        "INTEREXPRESSPLUS_ZONA_2",
        "INTEREXPRESSPLUS_ZONA_3",
        "INTEREXPRESSPLUS_ZONA_4",
        "INTEREXPRESSPLUS_ZONA_5",
        "INTEREXPRESSPLUS_ZONA_6",
        "INTEREXPRESSPLUS_ZONA_7",
        "INTEREXPRESSPLUS_ZONA_8",
        "INTEREXPRESSPLUS_ZONA_9",
      ],
      loading: false,
      allServicios: [],
      allEstados: [],
      dataGeografico: {
        tipo: "",
        servicios: [],
        serviciosDefecto: [{ origen: "", reemplazo: "" }],
        serviciosDefectoMedidas: [
          { origen: "", reemplazo: "", peso: 0.0, medidas: 0.0 },
        ],
      },
      rules: {
        tipo: [
          {
            required: true,
            message: "El campo es obligatório",
            trigger: "blur",
          },
        ],
        servicios: [
          {
            required: true,
            message: "El campo es obligatório",
            trigger: "blur",
          },
        ],
      },
      arrayOrigen: [],
      arrayReemplazo: [],
    };
  },
  mounted() {
    this.getEstados();
    this.getServicios();
    if (this.idGeo != "new") {
      this.getGeograficoById();
    }
  },
  computed: {
    title() {
      return this.idGeo == "new"
        ? "Nuevo"
        : "Modificar: " + this.dataGeografico.tipo;
    },
    nuevoStatus() {
      return this.$store.state.geografico.nuevo.status;
    },
    updateStatus() {
      return this.$store.state.geografico.update.status;
    },
    byIdStatus() {
      return this.$store.state.geografico.geografico.status;
    },
    estadosState() {
      return this.$store.state.estado.list.status;
    },
    servicios() {
      return this.$store.state.servicios.list.status;
    },
    lengthServicios() {
      return this.dataGeografico.servicios.length;
    },
  },
  methods: {
    labelSinGuionBajo(label) {
      return label.replace(/_/g, " ");
    },
    getGeograficoById() {
      this.$store.dispatch("geografico/getGeograficoById", this.idGeo);
    },
    addLinea(row) {
      if (row.origen != "" && row.reemplazo != "")
        this.dataGeografico.serviciosDefecto.push({
          origen: "",
          reemplazo: "",
        });
      else
        this.notificacion(
          "Servicio",
          "Rellene el origen y reemplazo",
          "warning"
        );
    },
    addLineaMedidas(row) {
      if (row.origen != "" && row.reemplazo != "")
        this.dataGeografico.serviciosDefectoMedidas.push({
          origen: "",
          reemplazo: "",
        });
      else
        this.notificacion(
          "Servicio",
          "Rellene el origen y reemplazo",
          "warning"
        );
    },
    verBotonNuevaLinea(row) {
      if (row.$index == this.dataGeografico.serviciosDefecto.length - 1)
        return true;
      else return false;
    },
    verBotonNuevaLineaMedidas(row) {
      if (row.$index == this.dataGeografico.serviciosDefectoMedidas.length - 1)
        return true;
      else return false;
    },
    getServicios() {
      this.$store.dispatch("servicios/getServicios", {
        pagination: null,
        query: null,
      });
    },
    getEstados() {
      this.$store.dispatch("estado/getEstados", "ESTADO");
    },
    hideModal() {
      this.$emit("hide");
    },
    getLabel(item) {
      return item.codigo;
    },
    validarServiciosDefectos() {
      let ultimo = this.dataGeografico.serviciosDefecto.length - 1;
      if (
        this.dataGeografico.serviciosDefecto[ultimo].origen == "" &&
        this.dataGeografico.serviciosDefecto[ultimo].reemplazo == ""
      ) {
        this.dataGeografico.serviciosDefecto.pop();
      }
      ultimo = this.dataGeografico.serviciosDefecto.length - 1;
      if (
        this.dataGeografico.serviciosDefecto[ultimo].origen == "" ||
        this.dataGeografico.serviciosDefecto[ultimo].reemplazo == ""
      ) {
        this.notificacion(
          "Servicios por defecto",
          "Rellene el origen y reemplazo",
          "warning"
        );
        return false;
      } else {
        return true;
      }
    },
    aceptar() {
      this.$refs["geograficoRef"].validate((valid) => {
        if (valid && this.validarServiciosDefectos()) {
          this.dataGeografico.serviciosDefectoMedidas.forEach((item) => {
            item.peso = parseFloat(item.peso);
            item.medidas = parseFloat(item.medidas);
          });
          if (this.idGeo === "new") {
            this.$store.dispatch(
              "geografico/nuevoGeografico",
              this.dataGeografico
            );
          } else
            this.$store.dispatch(
              "geografico/updateGeografico",
              this.dataGeografico
            );
        }
      });
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
    buildArray() {
      this.arrayOrigen = this.allServicios.filter(
        (servicio) => !this.dataGeografico.servicios.includes(servicio.codigo)
      );
      this.arrayReemplazo = this.allServicios.filter((servicio) =>
        this.dataGeografico.servicios.includes(servicio.codigo)
      );
    },
    handleDelete(index, row) {
      this.dataGeografico.serviciosDefecto.splice(index, 1);
    },
    handleDeleteMedidas(index, row) {
      this.dataGeografico.serviciosDefectoMedidas.splice(index, 1);
    },
  },
  watch: {
    nuevoStatus(val) {
      if (val.loaded) {
        this.notificacion("Correcto!", "Creado correctamente!", "success");
        this.$emit("guardado");
        this.hideModal();
        this.loading = false;
      }
      if (val.error) {
        this.notificacion("Error!", val.error.error, "danger");
        this.loading = false;
      }
    },
    updateStatus(val) {
      if (val.loaded) {
        this.notificacion("Correcto!", "Actualizado correctamente!", "success");
        this.$emit("guardado");
        this.hideModal();
        this.loading = false;
      }
      if (val.error) {
        this.notificacion("Error!", val.error.error, "danger");
        this.loading = false;
      }
    },
    estadosState(val) {
      if (val.loaded) {
        if (this.$store.state.estado.list.data) {
          this.allEstados = this.$store.state.estado.list.data.items;
          if (this.tipo == "ANULAR") {
            this.evento.estado = "19";
          }
        }
      }
    },
    servicios() {
      if (this.servicios.loaded) {
        if (this.$store.state.servicios.list.data) {
          this.allServicios = this.$store.state.servicios.list.data.items;
        }
      }
      this.buildArray();
    },
    byIdStatus(val) {
      if (val.loaded) {
        this.dataGeografico = this.$store.state.geografico.geografico.data;
        if (
          !this.dataGeografico.serviciosDefectoMedidas ||
          this.dataGeografico.serviciosDefectoMedidas.length === 0
        ) {
          this.dataGeografico.serviciosDefectoMedidas = [
            { origen: "", reemplazo: "", peso: 0.0, medidas: 0.0 },
          ];
        }
      }
    },
    lengthServicios(val) {
      this.buildArray();
    },
  },
};
</script>

<style lang="scss">
.input-class .el-form-item__label {
  margin: none;
}
.modal-status-geo_servicio .modal-container {
  width: 800px !important;
}
.select {
  width: 100% !important;
}
</style>
