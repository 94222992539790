import { createAxios } from "./axiosHttpMarconi";

export const agrupacionesFranquiciaService = {
  getAgrupacionesFranquicias,
  postAgrupacionFranquicia,
  updateAgrupacionFranquicia,
  getAgrupacionFranquiciaById,
};

function getAgrupacionesFranquicias(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/agrupaciones-franquicia`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0";
  }
  if (filters && filters.activa) {
    urlPath += "&activa=" + filters.activa;
  }
  return axios.get(urlPath);
}

function postAgrupacionFranquicia(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/agrupaciones-franquicia`;
  return axios.post(urlPath, request);
}

function updateAgrupacionFranquicia(id, body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/agrupaciones-franquicia/${id}`;
  return axios.put(urlPath, body);
}

function getAgrupacionFranquiciaById(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/agrupaciones-franquicia/${id}`;
  return axios.get(urlPath);
}
