import { impuestosService } from "@/_services";
export const impuestos = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    new: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    listTipos: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getImpuestosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getImpuestosError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getImpuestosSuccess(state, env) {
      state.list.data = env;
      state.list.status = {
        loaded: true,
      };
    },
    getTipoImpuestosRequest(state) {
      state.listTipos.status = {
        loading: true,
      };
    },
    getTipoImpuestosError(state, error) {
      state.listTipos.data = [];
      state.listTipos.status = {
        error,
      };
    },
    getTipoImpuestosSuccess(state, env) {
      state.listTipos.data = env;
      state.listTipos.status = {
        loaded: true,
      };
    },
    getImpuestoByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getImpuestoByIdError(state, error) {
      state.entity.data = [];
      state.entity.status = {
        error,
      };
    },
    getImpuestoByIdSuccess(state, env) {
      state.entity.data = env;
      state.entity.status = {
        loaded: true,
      };
    },
    newImpuestoRequest(state) {
      state.new.status = {
        creating: true,
      };
    },
    newImpuestoError(state, error) {
      state.new.data = [];
      state.new.status = {
        error: error,
      };
    },
    newImpuestoSuccess(state, env) {
      state.new.data = env;
      state.new.status = {
        created: true,
      };
    },
    updateImpuestoRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateImpuestoError(state, error) {
      state.update.data = [];
      state.update.status = {
        error,
      };
    },
    updateImpuestoSuccess(state, env) {
      state.update.data = env;
      state.update.status = {
        updated: true,
      };
    },
    deleteImpuestoRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deleteImpuestoError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteImpuestoSuccess(state, impuesto) {
      state.delete.data = impuesto;
      state.delete.status = {
        deleted: true,
      };
    },
  },
  actions: {
    getImpuestos({ commit }, req) {
      commit("getImpuestosRequest");
      impuestosService
        .getImpuestos(req.pagination, req.filters)
        .then((env) => {
          commit("getImpuestosSuccess", env);
        })
        .catch((error) => {
          commit("getImpuestosError", error);
        });
    },
    getTipoImpuestos({ commit }) {
      commit("getTipoImpuestosRequest");
      impuestosService
        .getTipoImpuestos()
        .then((env) => {
          commit("getTipoImpuestosSuccess", env);
        })
        .catch((error) => {
          commit("getTipoImpuestosError", error);
        });
    },
    getImpuestoById({ commit }, impuestoId) {
      commit("getImpuestoByIdRequest");
      impuestosService
        .getImpuestoById(impuestoId)
        .then((env) => {
          commit("getImpuestoByIdSuccess", env);
        })
        .catch((error) => {
          commit("getImpuestoByIdError", error);
        });
    },
    newImpuesto({ commit }, body) {
      commit("newImpuestoRequest");
      impuestosService
        .newImpuesto(body)
        .then((env) => {
          commit("newImpuestoSuccess", env);
        })
        .catch((error) => {
          commit("newImpuestoError", error);
        });
    },
    updateImpuesto({ commit }, body) {
      commit("updateImpuestoRequest");
      impuestosService
        .updateImpuesto(body)
        .then((env) => {
          commit("updateImpuestoSuccess", env);
        })
        .catch((error) => {
          commit("updateImpuestoError", error);
        });
    },
    deleteImpuesto({ commit }, impuestoId) {
      commit("deleteImpuestoRequest");
      impuestosService
        .deleteImpuesto(impuestoId)
        .then((impuesto) => {
          commit("deleteImpuestoSuccess", impuesto);
        })
        .catch((error) => {
          commit("deleteImpuestoError", error);
        });
    },
  },
};
