import createAxios from "./axiosHttpMarconi";
export const ficherosSorterService = {
  getFicherosSorter,
  getFicherosSorterDownload
};


function getFicherosSorterDownload(idFichero) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/ficheros_sorter/${idFichero}/download`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

function getFicherosSorter(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/ficheros_sorter`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.date) {
    urlPath += `&fecha_inicio=${filters.date[0]}`;
    urlPath += `&fecha_fin=${filters.date[1]}`;
  }
  return axios.get(urlPath);
}