var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-lg-8 col-md-7" },
      [_c("edit-profile-form")],
      1
    ),
    _c("div", { staticClass: "col-lg-4 col-md-5" }, [_c("user-card")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }