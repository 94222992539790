import createAxios from "./axiosHttpMarconi";

function getEnviosVistas(pagination) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/vistas`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage
      }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  return axios.get(urlPath);
}
function getEnviosVistasById(idEnvioVista) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/vistas/${idEnvioVista}`;
  return axios.get(urlPath);
}

function updateEnviosVistas(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/vistas/${body.envioVistaId}`;
  return axios.put(urlPath, body);
}

export const envioVistasService = {
  getEnviosVistas,
  getEnviosVistasById,
  updateEnviosVistas
};