import { integracionService } from "@/_services";

export const notificacionIntegracion = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getIntegracionNotificacionRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getIntegracionNotificacionError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getIntegracionNotificacionSuccess(state, data) {
      state.list.data = data;
      state.list.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getNotificacionesIntegraciones({ commit }, req) {
      commit("getIntegracionNotificacionRequest");
      integracionService
        .getNotificacionesIntegraciones(req.pagination, req.filters)
        .then((api) => {
          commit("getIntegracionNotificacionSuccess", api);
        })
        .catch((error) => {
          commit("getIntegracionNotificacionError", error);
        });
    },
  },
};
