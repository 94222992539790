import createAxios from "./axiosHttpMarconi";

function getMobileQueue(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/mobile_queue`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage
      }`;
  } else {
    urlPath += "?page=0&limit=10";
  }

  urlPath += getFiltersPath(filters);
  return axios.get(urlPath);
}

function getFiltersPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.envio) {
    urlPathFilters += `&envio=${filters.envio}`;
  }
  if (filters && filters.numero) {
    urlPathFilters += `&numero=${filters.numero}`;
  }
  if (filters.estados) {
    urlPathFilters += `&estados=${filters.estados}`;
  }
  if (filters && filters.fecha) {
    urlPathFilters += `&fechaInicio=${filters.fecha[0]}`;
    urlPathFilters += `&fechaFin=${filters.fecha[1]}`;
  }
  return urlPathFilters;
}

function reencolarMobileQueueError(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/mobile_queue/reencolar`;
  return axios.put(urlPath, body);
}

function getErrorMobileQueueById(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/mobile_queue/${id}`;
  return axios.get(urlPath);
}

export const mobileQueueService = {
  getMobileQueue,
  reencolarMobileQueueError,
  getErrorMobileQueueById
};