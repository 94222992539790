var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("filter-base", {
    ref: "filtroBase",
    attrs: { widthFilter: 600 },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "el-form",
              {
                ref: "dataFilter",
                staticClass: "px-4 py-3",
                attrs: { "label-position": "top", model: _vm.localValue },
              },
              [
                _c(
                  "el-form-item",
                  { staticClass: "input-class", attrs: { prop: "servicio" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Servicio" },
                      model: {
                        value: _vm.localValue.servicio,
                        callback: function ($$v) {
                          _vm.$set(_vm.localValue, "servicio", $$v)
                        },
                        expression: "localValue.servicio",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { staticClass: "input-class", attrs: { prop: "producto" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "producto" },
                      model: {
                        value: _vm.localValue.producto,
                        callback: function ($$v) {
                          _vm.$set(_vm.localValue, "producto", $$v)
                        },
                        expression: "localValue.producto",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "input-class",
                    attrs: { prop: "nombreServicio" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Servicio Seur" },
                      model: {
                        value: _vm.localValue.nombreServicio,
                        callback: function ($$v) {
                          _vm.$set(_vm.localValue, "nombreServicio", $$v)
                        },
                        expression: "localValue.nombreServicio",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    staticClass: "input-class",
                    attrs: { prop: "nombreProducto" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Producto Seur" },
                      model: {
                        value: _vm.localValue.nombreProducto,
                        callback: function ($$v) {
                          _vm.$set(_vm.localValue, "nombreProducto", $$v)
                        },
                        expression: "localValue.nombreProducto",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { align: "right" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.search()
                          },
                        },
                      },
                      [_vm._v("Buscar")]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clearFilters()
                          },
                        },
                      },
                      [_vm._v("Limpiar")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }