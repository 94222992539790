import { valoracionService } from "@/_services";

export const valoracion = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    valoracion: {
      data: {},
      status: {},
    },
    revaloraciones: {
      data: {},
      status: {},
    },
    revalorar: {
      data: {},
      status: {},
    },
    valoracionesExcel: {
      data: null,
      status: {},
    },
  },
  mutations: {
    getValoracionesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getValoracionesError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    getValoracionesSuccess(state, ficha) {
      state.list.data = ficha;
      state.list.status = {
        loaded: true,
      };
    },
    getValoracionRequest(state) {
      state.valoracion.status = {
        loading: true,
      };
    },
    getValoracionSuccess(state, env) {
      state.valoracion.data = env;
      state.valoracion.status = {
        loaded: true,
      };
    },
    getValoracionError(state, error) {
      state.valoracion.data = null;
      state.valoracion.status = {
        error,
      };
    },
    revalorarRequest(state) {
      state.revalorar.status = {
        loading: true,
      };
    },
    revalorarSuccess(state, env) {
      state.revalorar.data = env;
      state.revalorar.status = {
        loaded: true,
      };
    },
    revalorarError(state, error) {
      state.revalorar.data = null;
      state.revalorar.status = {
        error,
      };
    },
    valoracionesExcelRequest(state) {
      state.valoracionesExcel.status = {
        loading: true,
      };
    },
    valoracionesExcelSuccess(state, pdf) {
      state.valoracionesExcel.data = pdf;
      state.valoracionesExcel.status = {
        loaded: true,
      };
    },
    valoracionesExcelError(state, error) {
      state.valoracionesExcel.data = null;
      state.valoracionesExcel.status = {
        error,
      };
    },
  },
  actions: {
    getValoraciones({ commit }, req) {
      commit("getValoracionesRequest");
      valoracionService
        .getValoraciones(req.pagination, req.filters)
        .then((valoraciones) => {
          commit("getValoracionesSuccess", valoraciones);
        })
        .catch((error) => {
          commit("getValoracionesError", error);
        });
    },
    getValoracion({ commit }, envioId) {
      commit("getValoracionRequest");
      valoracionService
        .getValoracion(envioId)
        .then((env) => {
          commit("getValoracionSuccess", env);
        })
        .catch((error) => {
          commit("getValoracionError", error);
        });
    },
    revalorarByParams({ commit }, body) {
      commit("revalorarRequest");
      valoracionService
        .revalorar(body)
        .then((response) => {
          commit("revalorarSuccess", response);
        })
        .catch((error) => {
          commit("revalorarError", error);
        });
    },
    getValoracionesExcel({ commit }, req) {
      commit("valoracionesExcelRequest");
      valoracionService
        .getValoracionesAsExcel(req.filters)
        .then((excel) => {
          commit("valoracionesExcelSuccess", excel);
        })
        .catch((error) => {
          commit("valoracionesExcelError", error);
        });
    },
  },
};
