import createAxios from "./axiosHttpMarconi";
export const apiKeyService = {
  getApiKey,
  newApiKey,
  desactivarApiKey
};

function getApiKey(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/api_key`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage
      }`;
  } else {
    urlPath += "?page=0&limit=10";
  }

  urlPath += getFiltersPath(filters);
  return axios.get(urlPath);
}

function getFiltersPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.nombre) {
    urlPathFilters += `&appName=${filters.nombre}`;
  }
  if (filters && filters.roles) {
    urlPathFilters += `&roles=${filters.roles}`;
  }
  if (filters && filters.cliente) {
    urlPathFilters += `&idCliente=${filters.cliente}`;
  }
  urlPathFilters += `&active=${filters.activo}`
  return urlPathFilters;
}

function newApiKey(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/api_key`;
  return axios.post(urlPath, body);
}

function desactivarApiKey(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/api_key`;
  return axios.put(urlPath, body);
}