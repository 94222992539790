import createAxios from "./axiosHttpMarconi";

function getTipoImpuestos(pagination) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/impuestos-tipo`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=100";
  }
  return axios.get(urlPath);
}

function getImpuestos(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/impuestos`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=100";
  }
  urlPath += getFiltersPath(filters);
  return axios.get(urlPath);
}

function getFiltersPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.codigo) {
    urlPathFilters += `&codigo=${filters.codigo}`;
  }
  if (filters && filters.nombre) {
    urlPathFilters += `&nombre=${filters.nombre}`;
  }
  if (filters && filters.vigente) {
    urlPathFilters += `&vigente=${filters.vigente}`;
  }
  return urlPathFilters;
}

function updateImpuesto(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/impuestos/${body._id}`;
  return axios.put(urlPath, body);
}

function getImpuestoById(impuestoId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/impuestos/${impuestoId}`;
  return axios.get(urlPath);
}

function newImpuesto(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/impuestos`;
  return axios.post(urlPath, body);
}

function deleteImpuesto(impuestoId) {
  const axios = createAxios();
  let user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/impuestos/${impuestoId}`;
  return axios.delete(urlPath);
}

export const impuestosService = {
  newImpuesto,
  getImpuestos,
  updateImpuesto,
  deleteImpuesto,
  getImpuestoById,
  getTipoImpuestos,
};
