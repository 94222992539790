import { solucionesReglasService } from "@/_services";
export const solucionRegla = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getSolucionesReglaRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getSolucionesReglaSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getSolucionesReglaError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    deleteSolucionReglaRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deleteSolucionReglaError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteSolucionReglaSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        deleted: true,
      };
    },
    postSolucionReglaRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postSolucionReglaError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postSolucionReglaSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateSolucionReglaRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateSolucionReglaError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateSolucionReglaSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getSolucionReglaByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getSolucionReglaByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getSolucionReglaByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getSolucionesRegla({ commit }, request) {
      commit("getSolucionesReglaRequest");
      solucionesReglasService
        .getSolucionesRegla(request.pagination, request.query)
        .then((response) => {
          commit("getSolucionesReglaSuccess", response);
        })
        .catch((error) => {
          commit("getSolucionesReglaError", error);
        });
    },
    deleteSolucionRegla({ commit }, id) {
      commit("deleteSolucionReglaRequest");
      solucionesReglasService
        .deleteSolucionRegla(id)
        .then((res) => {
          commit("deleteSolucionReglaSuccess", res);
        })
        .catch((error) => {
          commit("deleteSolucionReglaError", error);
        });
    },
    postSolucionRegla({ commit }, request) {
      commit("postSolucionReglaRequest");
      solucionesReglasService
        .postSolucionRegla(request)
        .then((res) => {
          commit("postSolucionReglaSuccess", res);
        })
        .catch((error) => {
          commit("postSolucionReglaError", error);
        });
    },
    updateSolucionRegla({ commit }, request) {
      commit("updateSolucionReglaRequest");
      solucionesReglasService
        .updateSolucionRegla(request.id, request.body)
        .then((res) => {
          commit("updateSolucionReglaSuccess", res);
        })
        .catch((error) => {
          commit("updateSolucionReglaError", error);
        });
    },
    getSolucionReglaById({ commit }, id) {
      commit("getSolucionReglaByIdRequest");
      solucionesReglasService
        .getSolucionReglaById(id)
        .then((res) => {
          commit("getSolucionReglaByIdSuccess", res);
        })
        .catch((error) => {
          commit("getSolucionReglaByIdError", error);
        });
    },
  },
};
