import { createAxiosLogistica } from "./axiosHttpMarconi";

export const ubicacionesService = {
  getUbicaciones,
  deleteUbicacion,
  postUbicacion,
  updateUbicacion,
  getUbicacionById,
};

function getUbicaciones(pagination, filters) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/ubicacion`;
  const params = new URLSearchParams({ ...pagination, ...filters });

  urlPath += `?${params.toString()}`;
  return axios.get(urlPath);
}

function deleteUbicacion(ubicacionId) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/ubicacion/${ubicacionId}`;
  return axios.delete(urlPath);
}

function postUbicacion(request) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/ubicacion`;
  return axios.post(urlPath, request);
}

function updateUbicacion(id, body) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/ubicacion/${id}`;
  return axios.put(urlPath, body);
}

function getUbicacionById(id) {
  const axios = createAxiosLogistica();
  let urlPath = `logistica/ubicacion/${id}`;
  return axios.get(urlPath);
}
