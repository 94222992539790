var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-secondary dropdown-toggle",
        attrs: {
          "data-bs-toggle": "dropdown",
          "aria-expanded": "false",
          id: "botonFiltro",
          "data-bs-auto-close": "false",
        },
      },
      [_vm._v(" Buscar ")]
    ),
    _c(
      "div",
      {
        staticClass: "dropdown-menu dropdown-menu-end",
        class: "filter-body-" + _vm.widthFilter,
        attrs: { id: "dropdown-busqueda-envio" },
      },
      [
        _c("i", {
          staticClass: "el-icon-close float-end me-2",
          on: { click: _vm.closeFiltro },
        }),
        _c(
          "div",
          { staticClass: "mt-2" },
          [
            _vm._t("body", function () {
              return [_vm._v(" Filtros ")]
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }