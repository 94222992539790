import { estadosParadaEnvioService } from "@/_services";

export const estadosParadaEnvio = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: { data: {}, status: {} },
    entityDelete: { data: {}, status: {} },
  },
  mutations: {
    getEstadosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getEstadosError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getEstadosSuccess(state, estados) {
      state.list.data = estados;
      state.list.status = {
        loaded: true,
      };
    },
    deleteEstadoRequest(state) {
      state.entityDelete.status = {
        deleting: true,
      };
    },
    deleteEstadoError(state, error) {
      state.entityDelete.data = [];
      state.entityDelete.status = {
        error,
      };
    },
    deleteEstadoSuccess(state, estados) {
      state.entityDelete.data = estados;
      state.entityDelete.status = {
        deleted: true,
      };
    },
    newEstadoRequest(state) {
      state.entity.status = {
        creating: true,
      };
    },
    newEstadoError(state, error) {
      state.entity.data = [];
      state.entity.status = {
        error,
      };
    },
    newEstadoSuccess(state, estado) {
      state.entity.data = estado;
      state.entity.status = {
        created: true,
      };
    },
  },
  actions: {
    getEstados({ commit }, req) {
      commit("getEstadosRequest");
      estadosParadaEnvioService
        .getEstados(req.pagination, req.filters)
        .then((estados) => {
          commit("getEstadosSuccess", estados);
        })
        .catch((error) => {
          commit("getEstadosError", error);
        });
    },
    newEstado({ commit }, body) {
      commit("newEstadoRequest");
      estadosParadaEnvioService
        .newEstado(body)
        .then((estado) => {
          commit("newEstadoSuccess", estado);
        })
        .catch((error) => {
          commit("newEstadoError", error);
        });
    },
    deleteEstado({ commit }, body) {
      commit("deleteEstadoRequest");
      estadosParadaEnvioService
        .deleteEstado(body)
        .then((estado) => {
          commit("deleteEstadoSuccess", estado);
        })
        .catch((error) => {
          commit("deleteEstadoError", error);
        });
    },
  },
};
