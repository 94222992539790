import { envioConsultasService } from "@/_services";

export const envioConsultas = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    querySave: {
      data: {},
      status: {},
    },
    queryEdit: {
      data: {},
      status: {},
    },
    queryDelete: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getQueriesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getQueriesError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getQueriesSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    postQueryRequest(state) {
      state.querySave.status = {
        saving: true,
      };
    },
    postQueryError(state, error) {
      state.querySave.data = [];
      state.querySave.status = {
        error,
      };
    },
    postQuerySuccess(state, response) {
      state.querySave.data = response;
      state.querySave.status = {
        saved: true,
      };
    },
    putQueryRequest(state) {
      state.queryEdit.status = {
        editing: true,
      };
    },
    putQueryError(state, error) {
      state.queryEdit.data = [];
      state.queryEdit.status = {
        error,
      };
    },
    putQuerySuccess(state, response) {
      state.queryEdit.data = response;
      state.queryEdit.status = {
        edited: true,
      };
    },
    deleteQueryRequest(state) {
      state.queryDelete.status = {
        deleting: true,
      };
    },
    deleteQueryError(state, error) {
      state.queryDelete.data = [];
      state.queryDelete.status = {
        error,
      };
    },
    deleteQuerySuccess(state, response) {
      state.queryDelete.data = response;
      state.queryDelete.status = {
        deleted: true,
      };
    },
  },
  actions: {
    getQueries({ commit }) {
      commit("getQueriesRequest");
      envioConsultasService
        .getQueries()
        .then((res) => {
          commit("getQueriesSuccess", res);
        })
        .catch((error) => {
          commit("getQueriesError", error);
        });
    },
    saveQuery({ commit }, req) {
      commit("postQueryRequest");
      envioConsultasService
        .postQuery(req)
        .then((res) => {
          commit("postQuerySuccess", res);
        })
        .catch((error) => {
          commit("postQueryError", error);
        });
    },
    editQuery({ commit }, req) {
      commit("putQueryRequest");
      envioConsultasService
        .putQuery(req.id, req.request)
        .then((res) => {
          commit("putQuerySuccess", res);
        })
        .catch((error) => {
          commit("putQueryError", error);
        });
    },
    deleteQuery({ commit }, req) {
      commit("deleteQueryRequest");
      envioConsultasService
        .deleteQuery(req)
        .then((res) => {
          commit("deleteQuerySuccess", res);
        })
        .catch((error) => {
          commit("deleteQueryError", error);
        });
    },
  },
};
