var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _vm._m(0),
    _c("td", [_vm._v(_vm._s(_vm.task.title))]),
    _c("td", { staticClass: "td-actions text-right" }, [
      _c("div", { staticClass: "table-icons" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-simple btn-xs",
            attrs: { type: "button", rel: "tooltip", title: "Edit Task" },
            on: { click: _vm.handleEditClick },
          },
          [_c("i", { staticClass: "ti-pencil-alt" })]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-danger btn-simple btn-xs",
            attrs: { type: "button", rel: "tooltip", title: "Remove" },
            on: { click: _vm.handleDeleteClick },
          },
          [_c("i", { staticClass: "ti-close" })]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("label", { staticClass: "checkbox" }, [
        _c("input", {
          attrs: { type: "checkbox", value: "", "data-toggle": "checkbox" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }