import { createAxios } from "./axiosHttpMarconi";

export const planesCombustibleService = { getPlanesCombustible, eliminarPlanCombustible, newCombustible };

function getPlanesCombustible(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/suplemento?codigo=gasoil&central=true`;
  if (pagination) {
    urlPath += `&page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += '&page=0&limit=10';
  }
  if (filters && filters.fecha) {
    urlPath += `&fechaVigencia=${filters.fecha}`;
  }
  return axios.get(urlPath);
}

function eliminarPlanCombustible(idPlan) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/suplemento/${idPlan}`;
  return axios.delete(urlPath);
}

function newCombustible(body) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem('user'));
  let urlPath = `franquiciadora/${franquiciadora.extraData.franquiciadoraId}/suplemento`;
  return axios.post(urlPath, body);
}