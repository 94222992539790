import { createAxios } from "./axiosHttpMarconi";

export const geograficoService = {
  getGeografico,
  deleteGeografico,
  nuevoGeografico,
  updateGeografico,
  getGeograficoById
};

function getGeografico(pagination,filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/geografico`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += "?page=0";
  }
  urlPath+= getUrlPath(filters);
  return axios.get(urlPath);
}

function getUrlPath(filters) {
  let urlPath="";
  if (filters && filters.geografico) {
    urlPath += `&servicio=${filters.geografico}`;
  }
  return urlPath;
}


function deleteGeografico(transitoId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/geografico/${transitoId}`;
  return axios.delete(urlPath);
}

function nuevoGeografico(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/geografico`;
  return axios.post(urlPath,request);
}

function updateGeografico(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/geografico/${request._id}`;
  return axios.put(urlPath,request);
}
function getGeograficoById(geoID) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/geografico/${geoID}`;
  return axios.get(urlPath);
}
