
import createAxios from './axiosHttpMarconi';
export const suplementoService = {
  nuevoSuplemento, getSuplementosCentral, getAllSuplementos, editarSuplemento,
  eliminarSuplementos, getAllSuplementosAsExcel
}
function eliminarSuplementos(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/suplemento?ids=${request.ids.join(',')}`;
  return axios.delete(urlPath);
}

function nuevoSuplemento(data) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/suplemento`;
  return axios.post(urlPath, data);
}

function editarSuplemento(data) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/suplemento/${data.suplemento}`;
  return axios.put(urlPath, data);
}

function getSuplementosCentral() {
  const axios = createAxios();
  let urlPath = `/conceptos?sort=nombre&order=ascending`;
  return axios.get(urlPath);
}

function getAllSuplementos(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/suplemento`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage
      }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += getFilters(filters)
  urlPath += '&sort=desde&order=descending'
  return axios.get(urlPath);
}

function getAllSuplementosAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/suplementos/excel?limit=2000`;
  urlPath += getFilters(filters)
  return axios.get(urlPath, {
    responseType: 'blob'
  });
}

function getFilters(filters) {
  let urlPath = '';
  if (filters && filters.nombre) {
    urlPath += `&nombre=${filters.nombre}`;
  }
  if (filters && filters.nombre) {
    urlPath += `&nombre=${filters.nombre}`;
  }
  if (filters && filters.idCliente) {
    urlPath += `&cliente=${filters.idCliente}`;
  }
  if (filters && filters.franquicia) {
    urlPath += `&franquicia=${filters.franquicia}`;
  }
  if (filters.central != null) {
    urlPath += `&central=${filters.central}`
  }
  if (filters.especial != null) {
    urlPath += `&especial=${filters.especial}`
  }
  urlPath += `&vigente=${filters.vigente}`;
  return urlPath
}