import { errorCourierService } from "@/_services";

export const errorCourier = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    reencolar: {
      data: {},
      status: {},
    },
    entity: {
      data: {},
      status: {},
    },
  },
  mutations: {

    getEnviosErrorRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getEnviosErrorError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getEnviosErrorSuccess(state, fechas) {
      state.list.data = fechas;
      state.list.status = {
        loaded: true,
      };
    },
    reencolarEnviosErrorRequest(state) {
      state.reencolar.status = {
        loading: true,
      };
    },
    reencolarEnviosErrorError(state, error) {
      state.reencolar.data = [];
      state.reencolar.status = {
        error,
      };
    },
    reencolarEnviosErrorSuccess(state, estado) {
      state.reencolar.data = estado;
      state.reencolar.status = {
        loaded: true,
      };
    },
    getErrorCourierByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getErrorCourierByIdError(state, error) {
      state.entity.data = [];
      state.entity.status = {
        error,
      };
    },
    getErrorCourierByIdSuccess(state, fechas) {
      state.entity.data = fechas;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getEnviosError({ commit }, req) {
      commit("getEnviosErrorRequest");
      errorCourierService
        .getEnviosError(req.pagination, req.filters)
        .then((envios) => {
          commit("getEnviosErrorSuccess", envios);
        })
        .catch((error) => {
          commit("getEnviosErrorError", error);
        });
    },

    reencolarEnviosError({ commit }, body) {
      commit("reencolarEnviosErrorRequest");
      errorCourierService
        .reencolarEnviosError(body)
        .then((fecha) => {
          commit("reencolarEnviosErrorSuccess", fecha);
        })
        .catch((error) => {
          commit("reencolarEnviosErrorError", error);
        });
    },
    getErrorCourierById({ commit }, id) {
      commit("getErrorCourierByIdRequest");
      errorCourierService
        .getErrorCourierById(id)
        .then((errorCourier) => {
          commit("getErrorCourierByIdSuccess", errorCourier);
        })
        .catch((error) => {
          commit("getErrorCourierByIdError", error);
        });
    },
  },
};
