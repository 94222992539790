import { tarifaClienteService } from "@/_services";

export const tarifaCliente = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    allTarifas: {
      data: [],
      status: {},
    },
    tarifasAsExcel: {
      data: [],
      status: {},
    },
    tarifaFranquicia: {
      data: [],
      status: {},
    },
    tarifa: {
      data: {},
      status: {},
    },
    incremento: {
      data: {},
      status: {},
    },
    finalizarTarifa: {
      data: [],
      status: {},
    },
    eliminarTarifas: {
      data: [],
      status: {},
    },
    copyFranquicias: {
      data: [],
      status: {},
    },
  },
  mutations: {
    eliminarTarifasRequest(state) {
      state.eliminarTarifas.status = {
        loading: true,
      };
    },
    eliminarTarifasSuccess(state, direccion) {
      state.eliminarTarifas.data = direccion;
      state.eliminarTarifas.status = {
        loaded: true,
      };
    },
    eliminarTarifasError(state, error) {
      state.eliminarTarifas.data = [];
      state.eliminarTarifas.status = {
        error,
      };
    },

    getTarifasByFranquiciaRequest(state) {
      state.tarifaFranquicia.status = {
        loading: true,
      };
    },
    cancelTarifasByFranquiciaRequest(state, error) {
      state.tarifaFranquicia.data = [];
      state.tarifaFranquicia.status = {
        error,
      };
    },
    getTarifasByFranquiciaSuccess(state, tarifas) {
      state.tarifaFranquicia.data = tarifas;
      state.tarifaFranquicia.status = {
        loaded: true,
      };
    },
    tarifasExcelRequest(state) {
      state.tarifasAsExcel.status = {
        loading: true,
      };
    },
    tarifasExcelSuccess(state, pdf) {
      state.tarifasAsExcel.data = pdf;
      state.tarifasAsExcel.status = {
        loaded: true,
      };
    },
    tarifasExcelError(state, error) {
      state.tarifasAsExcel.data = null;
      state.tarifasAsExcel.status = {
        error,
      };
    },
    getTarifasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelTarifasRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getTarifasSuccess(state, tarifas) {
      state.list.data = tarifas;
      state.list.status = {
        loaded: true,
      };
    },
    getAllTarifasRequest(state) {
      state.allTarifas.status = {
        loading: true,
      };
    },
    cancelAllTarifasRequest(state, error) {
      state.allTarifas.data = [];
      state.allTarifas.status = {
        error,
      };
    },
    getAllTarifasSuccess(state, tarifas) {
      state.allTarifas.data = tarifas;
      state.allTarifas.status = {
        loaded: true,
      };
    },
    getTarifasByIdRequest(state) {
      state.tarifa.status = {
        loading: true,
      };
    },
    getTarifasByIdError(state, error) {
      state.tarifa.data = {};
      state.tarifa.status = {
        error,
      };
    },
    getTarifasByIdSuccess(state, tarifa) {
      state.tarifa.data = tarifa;
      state.tarifa.status = {
        loaded: true,
      };
    },
    nuevoIncrementoRequest(state) {
      state.incremento.status = {
        creating: true,
      };
    },
    nuevoIncrementoError(state, error) {
      state.incremento.data = {};
      state.incremento.status = {
        error,
      };
    },
    nuevoIncrementoSuccess(state, response) {
      state.incremento.data = response;
      state.incremento.status = {
        created: true,
      };
    },
    tarifaFinalizarRequest(state) {
      state.finalizarTarifa.status = {
        editing: true,
      };
    },
    tarifaFinalizarError(state, error) {
      state.finalizarTarifa.data = {};
      state.finalizarTarifa.status = {
        error,
      };
    },
    tarifaFinalizarSuccess(state, response) {
      state.finalizarTarifa.data = response;
      state.finalizarTarifa.status = {
        edited: true,
      };
    },
    copiarTarifasRequest(state) {
      state.copyFranquicias.status = {
        coping: true,
      };
    },
    copiarTarifasError(state, error) {
      state.copyFranquicias.data = {};
      state.copyFranquicias.status = {
        error,
      };
    },
    copiarTarifasSuccess(state, response) {
      state.copyFranquicias.data = response;
      state.copyFranquicias.status = {
        copied: true,
      };
    },
  },
  actions: {
    getTarifas({ commit }) {
      commit("getTarifasRequest");
      tarifaClienteService
        .getTarifas()
        .then((tarifas) => {
          commit("getTarifasSuccess", tarifas);
        })
        .catch((error) => {
          commit("cancelTarifasRequest", error);
        });
    },
    getTarifasByFranquiciaId({ commit }, codigo) {
      commit("getTarifasByFranquiciaRequest");
      tarifaClienteService
        .getTarifasByFranquiciaId(codigo)
        .then((tarifas) => {
          commit("getTarifasByFranquiciaSuccess", tarifas);
        })
        .catch((error) => {
          commit("cancelTarifasByFranquiciaRequest", error);
        });
    },
    getAllTarifas({ commit }, req) {
      commit("getAllTarifasRequest");
      tarifaClienteService
        .getAllTarifas(req.pagination, req.filters)
        .then((tarifas) => {
          commit("getAllTarifasSuccess", tarifas);
        })
        .catch((error) => {
          commit("cancelAllTarifasRequest", error);
        });
    },
    getTarifasByFranquicia({ commit }) {
      commit("getTarifasRequest");
      tarifaClienteService
        .getTarifasByFranquicia()
        .then((tarifas) => {
          commit("getTarifasSuccess", tarifas);
        })
        .catch((error) => {
          commit("cancelTarifasRequest", error);
        });
    },
    getAllTarifasAsExcel({ commit }, request) {
      commit("tarifasExcelRequest");
      tarifaClienteService
        .getAllTarifasAsExcel(request)
        .then((excel) => {
          commit("tarifasExcelSuccess", excel);
        })
        .catch((error) => {
          commit("tarifasExcelError", error);
        });
    },
    getTarifasById({ commit }, id) {
      commit("getTarifasByIdRequest");
      tarifaClienteService
        .getTarifasById(id)
        .then((tarifas) => {
          commit("getTarifasByIdSuccess", tarifas);
        })
        .catch((error) => {
          commit("getTarifasByIdError", error);
        });
    },
    copiarTarifas({ commit }, req) {
      commit("copiarTarifasRequest");
      tarifaClienteService
        .copiarTarifas(req)
        .then((tarifas) => {
          commit("copiarTarifasSuccess", tarifas);
        })
        .catch((error) => {
          commit("copiarTarifasError", error);
        });
    },
  },
};
