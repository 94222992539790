import { notificacionesDashboardService } from "@/_services";

export const notificacionesDashboard = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getNotificacionesDashboardRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getNotificacionesDashboardError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    getNotificacionesDashboardSuccess(state, ficha) {
      state.list.data = ficha;
      state.list.status = {
        loaded: true,
      };
    },
    newNotificacionRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    newNotificacionSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
    newNotificacionError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
  },
  actions: {
    getNotificacionesDashboard({ commit }, req) {
      commit("getNotificacionesDashboardRequest");
      notificacionesDashboardService
        .getNotificacionesDashboard(req.pagination, req.filters)
        .then((notificaciones) => {
          commit("getNotificacionesDashboardSuccess", notificaciones);
        })
        .catch((error) => {
          commit("getNotificacionesDashboardError", error);
        });
    },
    newNotificacion({ commit }, request) {
      commit("newNotificacionRequest");
      notificacionesDashboardService
        .newNotificacion(request.body, request.file)
        .then((res) => {
          commit("newNotificacionSuccess", res);
        })
        .catch((error) => {
          commit("newNotificacionError", error);
        });
    },
  },
};
