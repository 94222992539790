import { agrupacionesClienteService } from "@/_services";
export const agrupacionesCliente = {
  namespaced: true,
  state: {
    entity: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
  },
  mutations: {
    postAgrupacionClienteRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postAgrupacionClienteError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postAgrupacionClienteSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    getAgrupacionClienteRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getAgrupacionClienteError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getAgrupacionClienteSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    postAgrupacionCliente({ commit }, request) {
      commit("postAgrupacionClienteRequest");
      agrupacionesClienteService
        .postAgrupacionCliente(request)
        .then((res) => {
          commit("postAgrupacionClienteSuccess", res);
        })
        .catch((error) => {
          commit("postAgrupacionClienteError", error);
        });
    },
    getAgrupacionCliente({ commit }, id) {
      commit("getAgrupacionClienteRequest");
      agrupacionesClienteService
        .getAgrupacionCliente(id)
        .then((res) => {
          commit("getAgrupacionClienteSuccess", res);
        })
        .catch((error) => {
          commit("getAgrupacionClienteError", error);
        });
    },
  },
};
