<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar :backgroundColor="colorBackground" id="sidebar">
      <mobile-menu></mobile-menu>
      <template v-if="this.userLogged" slot="links">
        <sidebar-item
          v-if="isMenuVisible('/operativa')"
          :hideItem="ocultarItem"
          :link="{
            name: 'Operativa',
            icon: 'nc-icon nc-delivery-fast',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Envíos',
              path: '/franquiciadora/envios',
            }"
            v-if="isMenuVisible('/franquiciadora/envios')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'RMA',
              path: '/franquiciadora/rma',
            }"
            v-if="isMenuVisible('/franquiciadora/rma')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Extraviados',
              path: '/franquiciadora/extraviados',
            }"
            v-if="isMenuVisible('/franquiciadora/extraviados')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Envíos agrupados',
              path: '/franquiciadora/envios-agrupados',
            }"
            v-if="isMenuVisible('/franquiciadora/envios-agrupados')"
          />
          <!-- <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Importaciones',
              path: '/franquiciadora/importaciones',
            }"
            v-if="isMenuVisible('/franquiciadora/importaciones')"
          /> -->
        </sidebar-item>
        <sidebar-item
          v-if="isMenuVisible('/movilidad')"
          :hideItem="ocultarItem"
          :link="{
            name: 'Movilidad',
            icon: 'nc-icon nc-compass-05',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'General',
              path: '/franquiciadora/trafico',
            }"
            v-if="isMenuVisible('/franquiciadora/trafico')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Recogidas',
              path: '/franquiciadora/recogidas',
            }"
            v-if="isMenuVisible('/franquiciadora/recogidas')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Entregas',
              path: '/franquiciadora/entregas',
            }"
            v-if="isMenuVisible('/franquiciadora/entregas')"
          />
        </sidebar-item>
        <sidebar-item
          v-if="isMenuVisible('/partner')"
          :hideItem="ocultarItem"
          :link="{
            name: 'Partner',
            icon: 'nc-icon nc-globe-2',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Salidas',
              path: '/franquiciadora/partner/salidas',
            }"
            v-if="isMenuVisible('/franquiciadora/partner/salidas')"
          />
        </sidebar-item>
        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Gestión de Incidencias',
            icon: 'nc-icon nc-settings-tool-66',
            path: '/franquiciadora/incidencias',
          }"
          v-if="isMenuVisible('/franquiciadora/incidencias')"
        >
        </sidebar-item>
        <sidebar-item
          v-if="isMenuVisible('/importaciones')"
          :hideItem="ocultarItem"
          :link="{
            name: 'Importaciones',
            icon: 'nc-icon nc-cloud-upload-94',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Envios',
              path: '/franquiciadora/importaciones',
            }"
            v-if="isMenuVisible('/franquiciadora/importaciones')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'RMA',
              path: '/franquiciadora/importacionesRma',
            }"
            v-if="isMenuVisible('/franquiciadora/importacionesRma')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Otros',
              path: '/franquiciadora/importacionesOtros',
            }"
            v-if="isMenuVisible('/franquiciadora/importacionesOtros')"
          />
        </sidebar-item>
        <sidebar-item
          v-if="isMenuVisible('/facturacion')"
          :hideItem="ocultarItem"
          :link="{
            name: 'Facturación',
            icon: 'nc-icon nc-paper-2',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Control de Valoraciones',
              path: '/franquiciadora/control-valoracion',
            }"
            v-if="isMenuVisible('/franquiciadora/control-valoracion')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Tarifas Cliente',
              path: '/franquiciadora/tarifas-cliente',
            }"
            v-if="isMenuVisible('/franquiciadora/tarifas-cliente')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Tarifas Franquicia',
              path: '/franquiciadora/tarifas',
            }"
            v-if="isMenuVisible('/franquiciadora/tarifas')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Tarifas Partner',
              path: '/franquiciadora/tarifasPartner',
            }"
            v-if="isMenuVisible('/franquiciadora/tarifasPartner')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Documentación',
              path: '/franquiciadora/documentacion',
            }"
            v-if="isMenuVisible('/franquiciadora/documentacion')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Ficheros master',
              path: '/franquiciadora/fichero-master',
            }"
            v-if="isMenuVisible('/franquiciadora/fichero-master')"
          />
        </sidebar-item>
        <sidebar-item
          v-if="isMenuVisible('/maestros')"
          :hideItem="ocultarItem"
          :link="{
            name: 'Maestros',
            icon: 'nc-icon nc-layers-3',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Puntos de Red',
              path: '/franquiciadora/puntosred',
            }"
            v-if="isMenuVisible('/franquiciadora/puntosred')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Estados',
              path: '/franquiciadora/estados',
            }"
            v-if="isMenuVisible('/franquiciadora/estados')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Servicios Parada',
              path: '/franquiciadora/estados-parada-envio',
            }"
            v-if="isMenuVisible('/franquiciadora/estados-parada-envio')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Estados Paradas',
              path: '/franquiciadora/estados-paradas',
            }"
            v-if="isMenuVisible('/franquiciadora/estados-paradas')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Servicios',
              path: '/franquiciadora/servicios',
            }"
            v-if="isMenuVisible('/franquiciadora/servicios')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Geográfico Servicios',
              path: '/franquiciadora/geografico-servicios',
            }"
            v-if="isMenuVisible('/franquiciadora/geografico-servicios')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Fecha Cierre',
              path: '/franquiciadora/fecha-cierre',
            }"
            v-if="isMenuVisible('/franquiciadora/fecha-cierre')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Reglas de Tránsito',
              path: '/franquiciadora/regla-transito',
            }"
            v-if="isMenuVisible('/franquiciadora/regla-transito')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Borrados',
              path: '/franquiciadora/borrados',
            }"
            v-if="isMenuVisible('/franquiciadora/borrados')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Vistas Control',
              path: '/franquiciadora/control-vistas',
            }"
            v-if="isMenuVisible('/franquiciadora/control-vistas')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Vistas entregas/recogidas',
              path: '/franquiciadora/envios-vistas',
            }"
            v-if="isMenuVisible('/franquiciadora/envios-vistas')"
          />

          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Impuestos',
              path: '/franquiciadora/impuestos',
            }"
            v-if="isMenuVisible('/franquiciadora/impuestos')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Soluciones',
              path: '/franquiciadora/soluciones',
            }"
            v-if="isMenuVisible('/franquiciadora/soluciones')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Soluciones Reglas',
              path: '/franquiciadora/soluciones-reglas',
            }"
            v-if="isMenuVisible('/franquiciadora/soluciones-reglas')"
          />
        </sidebar-item>
        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Franquicias',
            icon: 'nc-icon nc-bank',
            path: '/franquiciadora/franquicias/',
          }"
          v-if="isMenuVisible('/franquiciadora/franquicias/')"
        >
        </sidebar-item>
        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Clientes',
            icon: 'nc-icon nc-single-02',
            path: '/franquiciadora/clientes/',
          }"
          v-if="isMenuVisible('/franquiciadora/clientes/')"
        >
        </sidebar-item>
        <sidebar-item
          v-if="isMenuVisible('/mobile')"
          :hideItem="ocultarItem"
          :link="{
            name: 'Mobile',
            icon: 'nc-icon nc-mobile',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Eventos',
              path: '/franquiciadora/eventos',
            }"
            v-if="isMenuVisible('/franquiciadora/eventos')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Configuración',
              path: '/franquiciadora/gestion-configuracion',
            }"
            v-if="isMenuVisible('/franquiciadora/gestion-configuracion')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Repartidores',
              path: '/franquiciadora/repartidores-realizan-eventos',
            }"
            v-if="
              isMenuVisible('/franquiciadora/repartidores-realizan-eventos')
            "
          />
        </sidebar-item>
        <sidebar-item
          v-if="isMenuVisible('/integraciones')"
          :hideItem="ocultarItem"
          :link="{
            name: 'Integraciones',
            icon: 'nc-icon nc-puzzle-10',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Errores Courier',
              path: '/franquiciadora/error-courier',
            }"
            v-if="isMenuVisible('/franquiciadora/error-courier')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Ficheros Sorter',
              path: '/franquiciadora/ficheros-sorter',
            }"
            v-if="isMenuVisible('/franquiciadora/ficheros-sorter')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Plataforma Integración',
              path: '/franquiciadora/plataforma-integracion',
            }"
            v-if="isMenuVisible('/franquiciadora/plataforma-integracion')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Plataforma Integración Config',
              path: '/franquiciadora/configuracion-integracion',
            }"
            v-if="isMenuVisible('/franquiciadora/configuracion-integracion')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Servicios Renombrados',
              path: '/franquiciadora/renombrar-servicios',
            }"
            v-if="isMenuVisible('/franquiciadora/renombrar-servicios')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Servicios Seur',
              path: '/franquiciadora/servicios-seur',
            }"
            v-if="isMenuVisible('/franquiciadora/servicios-seur')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Mobile Queue',
              path: '/franquiciadora/mobile-queue',
            }"
            v-if="isMenuVisible('/franquiciadora/mobile-queue')"
          />
        </sidebar-item>
        <sidebar-item
          :hideItem="ocultarItem"
          :link="{
            name: 'Notificaciones',
            icon: 'nc-icon nc-bell-55',
            path: '',
          }"
          v-if="isMenuVisible('/notificaciones')"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Email',
              path: '/franquiciadora/integraciones-notificaciones',
            }"
            v-if="isMenuVisible('/franquiciadora/integraciones-notificaciones')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Portales',
              path: '/franquiciadora/notificaciones-dashboard',
            }"
            v-if="isMenuVisible('/franquiciadora/notificaciones-dashboard')"
          />
        </sidebar-item>
        <sidebar-item
          v-if="isMenuVisible('/autorizacion')"
          :hideItem="ocultarItem"
          :link="{
            name: 'Autorización',
            icon: 'nc-icon nc-key-25',
            path: '*',
          }"
        >
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Api Keys',
              path: '/franquiciadora/autorizacion',
            }"
            v-if="isMenuVisible('/franquiciadora/autorizacion')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Usuarios',
              path: '/franquiciadora/usuarios',
            }"
            v-if="isMenuVisible('/franquiciadora/usuarios')"
          />
          <sidebar-item
            :hideItem="ocultarItem"
            :link="{
              name: 'Menu roles',
              path: '/franquiciadora/menu-roles',
            }"
            v-if="isMenuVisible('/franquiciadora/menu-roles')"
          />
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  data() {
    return {
      ocultarItem: "mostrarme",
      ro: null,
      colorBackground: "black",
      menus: [],
      userLogged: null,
    };
  },
  computed: {
    menusStatus() {
      return this.$store.state.roles.menusMaster.status;
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        docClasses.add("perfect-scrollbar-off");
      } else {
        initScrollbar("main-panel");

        docClasses.add("perfect-scrollbar-on");
      }
    },
    onResize() {
      let val = document.getElementById("sidebar").clientWidth;
      if (val == 80) {
        this.ocultarItem = "ocultarme";
      } else {
        this.ocultarItem = "mostrarme";
      }
    },
    isMenuVisible(route) {
      if (this.userLogged.roles.includes("ADMIN")) return true;
      const item = this.menus.find((item) => item.key == route);
      if (!item) return true;
      if (item.roles.length <= 0) return true;
      if (!this.userLogged.rolesMenu) {
        return false;
      }
      return item.roles.some((elemento) =>
        this.userLogged.rolesMenu.includes(elemento)
      );
    },
    getMenus() {
      this.$store.dispatch("roles/getMenusMaster");
    },
  },
  mounted() {
    if (process.env.NODE_ENV === "production") {
      this.colorBackground = "black";
    } else this.colorBackground = "purple";
    this.$sidebar.ocultarSideBar();
    /* document.body.classList.toggle('sidebar-mini') */
    this.initScrollbar();
    this.ro = new ResizeObserver(this.onResize);
    let side = document.getElementById("sidebar");
    this.ro.observe(side);
    this.userLogged = JSON.parse(localStorage.getItem("user"));
    this.getMenus();
  },
  beforeUnmount() {
    let side = document.getElementById("sidebar");
    this.ro.unobserve(side);
  },
  watch: {
    menusStatus(val) {
      if (val.loaded) {
        this.menus = this.$store.state.roles.menusMaster.data.master;
      }
    },
  },
};
</script>
