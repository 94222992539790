import { createAxios } from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";

function getEnviosAgrupados(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/envios-agrupados`;

  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }

  if (filters && filters.servicios) {
    urlPath += `&servicios=${filters.servicios.join()}`;
  }

  if (filters && filters.estados) {
    urlPath += `&estados=${filters.estados.join()}`;
  }

  if (filters && filters.sort) {
    urlPath += `&sort=${filters.sort.prop}&order=${filters.sort.order}`;
  }

  if (filters && filters.referencia) {
    urlPath += `&referencia=${filters.referencia}`;
  }

  if (filters && filters.idCliente) {
    urlPath += `&cliente_id=${filters.idCliente}`;
  }

  if (filters && filters.idFranquicia) {
    urlPath += `&franquicia_id=${filters.idFranquicia}`;
  }

  return axios.get(urlPath);
}
function imprimirEtiquetas(fichero) {
  const axios = createAxios();
  const urlPath = `envios-agrupados/masivo/${fichero}/etiquetas`;
  return axios.get(urlPath, { responseType: "blob" });
}

export const enviosAgrupadosService = {
  getEnviosAgrupados,
  imprimirEtiquetas,
};
