import { envioService } from "@/_services";

export const envio = {
  namespaced: true,
  state: {
    envioProgramado: {
      data: {},
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    estadosEnvio: {
      data: {},
      status: {},
    },
    envio: {
      data: [],
      status: {},
    },
    newEventoEnvio: {
      data: {},
      status: {},
    },
    plantillas: {
      data: [],
      status: {},
    },
    responseJustificante: {
      data: {},
      status: {},
    },
    responseRecogida:{
      data: {},
      status: {},
    },
    justificanteServicio: {
      data: {},
      status: {},
    },
    responseManifiesto: {
      data: {},
      status: {},
    },
    dirHabituales: {
      entrega: {},
      recogida: {},
      rma: {},
      status: {
        entrega: {},
        recogida: {},
        rma: {},
      },
    },
  },
  mutations: {
    getDirHabitualesRequest(state) {
      state.dirHabituales.status = {
        loading: true,
      };
    },
    getDirHabitualesError(state, error) {
      state.dirHabituales.data = null;
      state.dirHabituales.status = {
        error,
      };
    },
    getDirHabitualesSuccess(state, direccion) {
      state.dirHabituales.data = direccion;
      state.dirHabituales.status = {
        loaded: true,
      };
    },
    getDirHabitualesSuccessByTipo(state, response) {
      if (response.tipo) {
        if (response.tipo == "ENTREGA") {
          state.dirHabituales.entrega = response.res;
          state.dirHabituales.status.entrega = {
            loaded: true,
          };
        } else if (response.tipo == "RMA") {
          state.dirHabituales.rma = response.res;
          state.dirHabituales.status.entrega = {
            loaded: true,
          };
        } else {
          state.dirHabituales.recogida = response.res;
          state.dirHabituales.status.recogida = {
            loaded: true,
          };
        }
      }
    },
    getDirHabitualesErrorByTipo(state, response) {
      if (response.tipo) {
        if (response.tipo == "RECOGIDA") {
          state.dirHabituales.status.recogida = {
            error: response.error,
          };
        } else {
          state.dirHabituales.status.entrega = {
            error: response.error,
          };
        }
      }
    },
    getDirHabitualesRequestByTipo(state, tipo) {
      if (tipo) {
        if (tipo == "RECOGIDA") {
          state.dirHabituales.status.recogida = {
            loading: true,
          };
        } else {
          state.dirHabituales.status.entrega = {
            loading: true,
          };
        }
      }
    },
    
    envioProgramadoRequest(state) {
      state.envioProgramado.status = {
        creating: true,
      };
    },
    envioProgramadoError(state, error) {
      state.envioProgramado.data = null;
      state.envioProgramado.status = {
        error,
      };
    },
    envioProgramadoSuccess(state, envio) {
      state.envioProgramado.data = envio;
      state.envioProgramado.status = {
        created: true,
      };
    },
    newEnvioRequest(state) {
      state.entity.status = {
        creating: true,
      };
    },
    newEnvioError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    newEnvioSuccess(state, envio) {
      state.entity.data = envio;
      state.entity.status = {
        created: true,
      };
    },
    newEventoEnvioRequest(state) {
      state.newEventoEnvio.status = {
        creating: true,
      };
    },
    newEventoEnvioError(state, error) {
      state.newEventoEnvio.data = null;
      state.newEventoEnvio.status = {
        error,
      };
    },
    newEventoEnvioSuccess(state, envio) {
      state.newEventoEnvio.data = envio;
      state.newEventoEnvio.status = {
        created: true,
      };
    },
    getEnvioRequest(state) {
      state.envio.status = {
        loading: true,
      };
    },
    getEnvioSuccess(state, env) {
      state.envio.data = env;
      state.envio.status = {
        loaded: true,
      };
    },
    getEnvioError(state, error) {
      state.envio.data = null;
      state.envio.status = {
        error,
      };
    },
    getEstadosEnvioError(state, error) {
      state.estadosEnvio.data = null;
      state.estadosEnvio.status = {
        error,
      };
    },
    getEstadosEnvioSuccess(state, estados) {
      state.estadosEnvio.data = estados;
      state.estadosEnvio.status = {
        loaded: true,
      };
    },
    getEstadosEnvioRequest(state) {
      state.estadosEnvio.status = {
        loading: true,
      };
    },
    getPlantillasRequest(state) {
      state.plantillas.status = {
        loading: true,
      };
    },
    getPlantillasSuccess(state, direccion) {
      state.plantillas.data = direccion;
      state.plantillas.status = {
        loaded: true,
      };
    },
    getPlantillError(state, error) {
      state.plantillas.data = [];
      state.plantillas.status = {
        error,
      };
    },
    getJustificanteRequest(state) {
      state.responseJustificante.status = {
        loading: true,
      };
    },
    getJustificanteSuccess(state, justificante) {
      state.responseJustificante.data = justificante;
      state.responseJustificante.status = {
        loaded: true,
      };
    },
    getJustificanteError(state, error) {
      state.responseJustificante.data = null;
      state.responseJustificante.status = {
        error,
      };
    },
    getJustificanteRequestRecogida(state) {
      state.responseRecogida.status = {
        loading: true,
      };
    },
    getJustificanteSuccessRecogida(state, justificante) {
      state.responseRecogida.data = justificante;
      state.responseRecogida.status = {
        loaded: true,
      };
    },
    getJustificanteErrorRecogida(state, error) {
      state.responseRecogida.data = null;
      state.responseRecogida.status = {
        error,
      };
    },
    getJustificanteServicioRequest(state) {
      state.justificanteServicio.status = {
        loading: true,
      };
    },
    getJustificanteServicioSuccess(state, justificante) {
      state.justificanteServicio.data = justificante;
      state.justificanteServicio.status = {
        loaded: true,
      };
    },
    getJustificanteServicioError(state, error) {
      state.justificanteServicio.data = null;
      state.justificanteServicio.status = {
        error,
      };
    },
    getManifiestoRequest(state) {
      state.responseManifiesto.status = {
        loading: true,
      };
    },
    getManifiestoSuccess(state, manifiesto) {
      state.responseManifiesto.data = manifiesto;
      state.responseManifiesto.status = {
        loaded: true,
      };
    },
    getManifiestoError(state, error) {
      state.responseManifiesto.data = null;
      state.responseManifiesto.status = {
        error,
      };
    },
  },
  actions: {
    getDirHabituales({ commit }, body) {
      commit("getDirHabitualesRequest");
      envioService
        .getDirHabituales(body)
        .then((env) => {
          commit("getDirHabitualesSuccess", env);
        })
        .catch((error) => {
          commit("getDirHabitualesError", error);
        });
    },
    getDirHabitualesByTipo({ commit }, body) {
      commit("getDirHabitualesRequestByTipo", body.tipo);
      envioService
        .getDirHabituales(body)
        .then((env) => {
          commit("getDirHabitualesSuccessByTipo", {
            res: env,
            tipo: body.tipo,
          });
        })
        .catch((error) => {
          commit("getDirHabitualesErrorByTipo", {
            error: error,
            tipo: body.tipo,
          });
        });
    },
    sendEnvioProgramado({ commit }, body) {
      commit("envioProgramadoRequest");
      envioService
        .sendEnvioProgramado(body)
        .then((env) => {
          commit("envioProgramadoSuccess", env);
        })
        .catch((error) => {
          commit("envioProgramadoError", error);
        });
    },
    getEnvio({ commit }, envioId) {
      commit("getEnvioRequest");
      envioService
        .getEnvio(envioId)
        .then((env) => {
          commit("getEnvioSuccess", env);
        })
        .catch((error) => {
          commit("getEnvioError", error);
        });
    },
    sendEnvio({ commit }, body) {
      commit("newEnvioRequest");
      envioService
        .sendEnvio(body)
        .then((env) => {
          commit("newEnvioSuccess", env);
        })
        .catch((error) => {
          commit("newEnvioError", error);
        });
    },
    getEstadosEnvio({ commit }, body) {
      commit("getEstadosEnvioRequest");
      envioService
        .getEstadosEnvio(body)
        .then((env) => {
          commit("getEstadosEnvioSuccess", env);
        })
        .catch((error) => {
          commit("getEstadosEnvioError", error);
        });
    },
    newEventoEnvio({ commit }, req) {
      commit("newEventoEnvioRequest");
      envioService
        .newEventoEnvio(req._id, req.body)
        .then((env) => {
          commit("newEventoEnvioSuccess", env);
        })
        .catch((error) => {
          commit("newEventoEnvioError", error);
        });
    },
    getPlantillas({ commit }, req) {
      commit("getPlantillasRequest");
      envioService
        .getPlantillas(req.idCliente, req.pagination, req.filters)
        .then((plantillas) => {
          commit("getPlantillasSuccess", plantillas);
        })
        .catch((error) => {
          commit("getPlantillError", error);
        });
    },
    getJustificante({ commit }, request) {
      commit("getJustificanteRequest");
      envioService
        .getJustificante(request)
        .then((justificante) => {
          commit("getJustificanteSuccess", justificante);
        })
        .catch((error) => {
          commit("getJustificanteError", error);
        });
    },
    getJustificanteRecogida({ commit }, request) {
      commit("getJustificanteRequestRecogida");
      envioService
        .getJustificanteRecogida(request)
        .then((justificante) => {
          commit("getJustificanteSuccessRecogida", justificante);
        })
        .catch((error) => {
          commit("getJustificanteErrorRecogida", error);
        });
    },
    getJustificanteServicio({ commit }, request) {
      commit("getJustificanteServicioRequest");
      envioService
        .getJustificanteServicio(request)
        .then((justificante) => {
          commit("getJustificanteServicioSuccess", justificante);
        })
        .catch((error) => {
          commit("getJustificanteServicioError", error);
        });
    },
    getManifiesto({ commit }, request) {
      commit('getManifiestoRequest');
      envioService.getManifiesto(request)
        .then((manifiesto) => {
          commit('getManifiestoSuccess', manifiesto);
        })
        .catch((error) => {
          commit('getManifiestoError', error);
        });
    },
  },
};
