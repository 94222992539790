var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", {
    staticClass: "modal-status-cexdevolucion",
    attrs: { loading: _vm.loading },
    on: { hide: _vm.hideModal },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [_c("h5", { staticClass: "modal-title" }, [_vm._v("Nuevo")])]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "el-form",
              {
                ref: "servicioReferencia",
                attrs: {
                  model: _vm.dataSeur,
                  "label-position": "top",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col",
                        attrs: { label: "Servicio", prop: "servicio" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Servicio" },
                          model: {
                            value: _vm.dataSeur.servicio,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataSeur, "servicio", $$v)
                            },
                            expression: "dataSeur.servicio",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col",
                        attrs: { label: "Producto", prop: "producto" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Producto" },
                          model: {
                            value: _vm.dataSeur.producto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataSeur, "producto", $$v)
                            },
                            expression: "dataSeur.producto",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col",
                        attrs: {
                          label: "Nombre servicio",
                          prop: "nombreServicio",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Nombre servicio" },
                          model: {
                            value: _vm.dataSeur.nombreServicio,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataSeur, "nombreServicio", $$v)
                            },
                            expression: "dataSeur.nombreServicio",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col",
                        attrs: {
                          label: "Nombre producto",
                          prop: "nombreProducto",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "Nombre producto" },
                          model: {
                            value: _vm.dataSeur.nombreProducto,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataSeur, "nombreProducto", $$v)
                            },
                            expression: "dataSeur.nombreProducto",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.hideModal },
              },
              [_vm._v(" Cerrar ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.aceptar },
              },
              [_vm._v(" Aceptar ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }