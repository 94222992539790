<template>
  <div class="modal modal-mask">
    <div class="modal-dialog modal-wrapper">
      <div class="modal-content modal-container" v-loading="loading">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="header"> Warning </slot>
          </h5>
          <i class="el-icon-close position-absolute top-0 end-0 mt-3 me-3" @click="hideModal"></i>
        </div>

        <div class="modal-body">
          <slot name="body"> ¿Está seguro de eliminar la configuración? </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <button type="button" class="btn btn-danger" @click="$emit('hide')">
              Cancelar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="$emit('accepted')"
            >
              Aceptar
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading:{
      required: false,
      default: () => false
    }
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
  },
};
</script>

<style>
.color {
  background-color: #ffffff;
  border: none;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.el-icon-close{
  cursor: pointer;
}
</style>
