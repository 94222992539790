import { puntoRedService } from "@/_services";
export const puntoRed = {
  namespaced: true,
  state: {
    puntos: {
      data: [],
      status: {},
    },
    puntoRed: {
      data: [],
      status: {}
    },
    update: {
      data: [],
      status: {}
    },
    nuevo: {
      data: [],
      status: {}
    },
    delete: {
      data: [],
      status: {}
    },
    busqueda:{
      data:{},
      status:{}
    },
    excel:{
      data:{},
      status:{}
    }
  },
  mutations: {
    puntoRedExcelRequest(state) {
      state.excel.status = {
        loading: true
      }
    },
    puntoRedExcelSuccess(state, pdf) {
      state.excel.data = pdf
      state.excel.status = {
        loaded: true
      }
    },
    puntoRedExcelError(state, error) {
      state.excel.data = null
      state.excel.status = {
        error
      }
    },
    getPuntosRedByPostalCodeRequest(state) {
      state.puntos.status = {
        loading: true,
      };
    },
    getPuntosRedByPostalCodeError(state, error) {
      state.puntos.data = null;
      state.puntos.status = {
        error,
      };
    },
    getPuntosRedByPostalCodeSuccess(state, list) {
      state.puntos.data = list;
      state.puntos.status = {
        loaded: true,
      };
    },

    getPuntoRedById(state) {
      state.puntoRed.status = {
        loading: true,
      };
    },
    getPuntoRedByIdError(state, error) {
      state.puntoRed.data = null;
      state.puntoRed.status = {
        error,
      };
    },
    getPuntoRedByIdSuccess(state, list) {
      state.puntoRed.data = list;
      state.puntoRed.status = {
        loaded: true,
      };
    },
    updatePuntoRedRequest(state) {
      state.update.status = {
        editing: true,
      };
    },
    updatePuntoRedError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updatePuntoRedSuccess(state, puntoRedEditado) {
      state.update.data = puntoRedEditado;
      state.update.status = {
        edited: true,
      };
    },

    newPuntoRedRequest(state) {
      state.nuevo.status = {
        creando: true,
      };
    },
    nuevoPuntoRedError(state, error) {
      state.nuevo.data = null;
      state.nuevo.status = {
        error,
      };
    },
    nuevoPuntoRedSuccess(state, nuevoPuntoRed) {
      state.nuevo.data = nuevoPuntoRed;
      state.nuevo.status = {
        created: true,
      };
    },
    deletePuntoDeRedRequest(state) {
      state.delete.status = {
        erasing: true,
      };
    },
    deletePuntoDeRedError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deletePuntoDeRedSuccess(state, deletePuntoRed) {
      state.delete.data = deletePuntoRed;
      state.delete.status = {
        erased: true,
      };
    },
  },
  actions: {
    getPuntosAsExcel({ commit }, request ) {
      commit('puntoRedExcelRequest');
      puntoRedService
        .getPuntosAsExcel(request)
        .then((excel) => {
          commit('puntoRedExcelSuccess', excel);
        })
        .catch((error) => {
          commit('puntoRedExcelError', error);
        });
    },
    getPuntosRedByPostalCode({ commit }, req) {
      commit("getPuntosRedByPostalCodeRequest");
      puntoRedService
        .getPuntosRedByPostalCode(req.pagination, req.filters)
        .then((list) => {
          commit("getPuntosRedByPostalCodeSuccess", list);
        })
        .catch((error) => {
          commit("getPuntosRedByPostalCodeError", error);
        });
    },
    getPuntosRedById({ commit }, puntoId) {
      commit("getPuntoRedById");
      puntoRedService
        .getPuntosRedById(puntoId)
        .then((list) => {
          commit("getPuntoRedByIdSuccess", list);
        })
        .catch((error) => {
          commit("getPuntoRedByIdError", error);
        });
    },
    updatePuntoDeRed({ commit }, req) {
      commit('updatePuntoRedRequest');
      puntoRedService.updatePuntoDeRed(req.idPuntoDeRed, req.body)
        .then((Res) => {
          commit('updatePuntoRedSuccess', Res);
        })
        .catch((error) => {
          commit('updatePuntoRedError', error);
        });
    },
    nuevoPuntoDeRed({ commit }, body) {
      commit('newPuntoRedRequest');
      puntoRedService.nuevoPuntoDeRed(body)
        .then((Res) => {
          commit('nuevoPuntoRedSuccess', Res);
        })
        .catch((error) => {
          commit('nuevoPuntoRedError', error);
        });
    },
    deletePuntoDeRed({ commit }, id) {
      commit('deletePuntoDeRedRequest');
      puntoRedService.deletePuntoDeRed(id)
        .then((Res) => {
          commit('deletePuntoDeRedSuccess', Res);
        })
        .catch((error) => {
          commit('deletePuntoDeRedError', error);
        });
    },
  },
};
