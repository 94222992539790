import { createAxios } from "./axiosHttpMarconi";
export const plataformaIntegracionService = { getIntegracionConfig,newPlataformaIntegracionconfig,eliminarIntegracionConfig,getIntegracionConfigById,updatePlataformaIntegracionConfig,getEstadoPlataformaIntegracion, getPlataformaIntegracion, reencolarPlataformaIntegracion};

function getIntegracionConfig(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/plataforma_integracion/config`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += getFiltersConfigPath(filters);
  return axios.get(urlPath);
}

function getEstadoPlataformaIntegracion(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/plataforma_integracion/${id}`;
  return axios.get(urlPath);
}

function getPlataformaIntegracion(pagination, filters){
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/plataforma_integracion`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }

  urlPath += getFiltersPath(filters);
  return axios.get(urlPath);
}

function getFiltersConfigPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.tipo) {
    urlPathFilters += `&tipo=${filters.tipo}`;
  }

  if (filters && filters.idCliente) {
    urlPathFilters += `&idCliente=${filters.idCliente}`;
  }
  return urlPathFilters;
}

function newPlataformaIntegracionconfig(body){
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/plataforma_integracion/config`;
  return axios.post(urlPath, body);
}

function eliminarIntegracionConfig(id){
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/plataforma_integracion/${id}/config`;
  return axios.delete(urlPath);
}
function getIntegracionConfigById(id){
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/plataforma_integracion/${id}/config`;
  return axios.get(urlPath);
}

function updatePlataformaIntegracionConfig(body){
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/plataforma_integracion/${body.id}/config`;
  return axios.put(urlPath,body);
}
function reencolarPlataformaIntegracion(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/plataforma_integracion/reencolar`;
  return axios.put(urlPath, body);
}

function getFiltersPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.localizador) {
    urlPathFilters += `&localizador=${filters.localizador}`;
  }
  if (filters && filters.estados) {
    urlPathFilters += `&estados=${filters.estados}`;
  }
  if (filters && filters.tipo) {
    urlPathFilters += `&tipo=${filters.tipo}`;
  }
  return urlPathFilters;
}
