import createAxios from "./axiosHttpMarconi";
function getRepartidores(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/repartidor`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=100";
  }
  urlPath += getFiltersPath(filters);
  return axios.get(urlPath);
}

function getFiltersPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.codigo) {
    urlPathFilters += `&codigo=${filters.codigo}`;
  }
  if (filters && filters.nombre) {
    urlPathFilters += `&nombre=${filters.nombre}`;
  }
  if (filters && filters.telefono) {
    urlPathFilters += `&telefono=${filters.telefono}`;
  }
  if (filters && filters.idFranquicia) {
    urlPathFilters += `&franquicia=${filters.idFranquicia}`;
  }
  if (filters && filters.activo) {
    urlPathFilters += `&activo=${filters.activo}`;
  }
  return urlPathFilters;
}

function updateRepartidor(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/repartidor/${body.repartidorId}`;
  return axios.put(urlPath, body);
}

function getRepartidorById(repartidorId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/repartidor/${repartidorId}`;
  return axios.get(urlPath);
}

function newRepartidor(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/repartidor`;
  return axios.post(urlPath, body);
}

function activarDesactivarRepartidor(body) {
  const axios = createAxios();
  let user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/repartidor/${body.repartidorId}/estado`;
  return axios.put(urlPath, body);
}

export const repartidorService = {
  getRepartidores,
  updateRepartidor,
  activarDesactivarRepartidor,
  newRepartidor,
  getRepartidorById,
};
