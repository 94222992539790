import { createAxios } from "./axiosHttpMarconi";

export const agrupacionesClienteService = {
  postAgrupacionCliente,
  getAgrupacionCliente,
};

function postAgrupacionCliente(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/agrupaciones-cliente`;
  return axios.post(urlPath, request);
}

function getAgrupacionCliente() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/agrupaciones-cliente`;
  return axios.get(urlPath);
}
