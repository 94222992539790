import { mobileQueueService } from "@/_services";

export const mobileQueue = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    reencolar: {
      data: {},
      status: {},
    },
    entity: {
      data: {},
      status: {},
    },
  },
  mutations: {

    getMobileQueueErrorRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getMobileQueueErrorError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getMobileQueueErrorSuccess(state, fechas) {
      state.list.data = fechas;
      state.list.status = {
        loaded: true,
      };
    },
    reencolarMobileQueueErrorRequest(state) {
      state.reencolar.status = {
        loading: true,
      };
    },
    reencolarMobileQueueErrorError(state, error) {
      state.reencolar.data = [];
      state.reencolar.status = {
        error,
      };
    },
    reencolarMobileQueueErrorSuccess(state, estado) {
      state.reencolar.data = estado;
      state.reencolar.status = {
        loaded: true,
      };
    },
    getErrorMobileQueueByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getErrorMobileQueueByIdError(state, error) {
      state.entity.data = [];
      state.entity.status = {
        error,
      };
    },
    getErrorMobileQueueByIdSuccess(state, fechas) {
      state.entity.data = fechas;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getMobileQueue({ commit }, req) {
      commit("getMobileQueueErrorRequest");
      mobileQueueService
        .getMobileQueue(req.pagination, req.filters)
        .then((envios) => {
          commit("getMobileQueueErrorSuccess", envios);
        })
        .catch((error) => {
          commit("getMobileQueueErrorError", error);
        });
    },

    reencolarMobileQueueError({ commit }, body) {
      commit("reencolarMobileQueueErrorRequest");
      mobileQueueService
        .reencolarMobileQueueError(body)
        .then((fecha) => {
          commit("reencolarMobileQueueErrorSuccess", fecha);
        })
        .catch((error) => {
          commit("reencolarMobileQueueErrorError", error);
        });
    },
    getErrorMobileQueueById({ commit }, id) {
      commit("getErrorMobileQueueByIdRequest");
      mobileQueueService
        .getErrorMobileQueueById(id)
        .then((mobileQueue) => {
          commit("getErrorMobileQueueByIdSuccess", mobileQueue);
        })
        .catch((error) => {
          commit("getErrorMobileQueueByIdError", error);
        });
    },
  },
};
