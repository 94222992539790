import createAxios from "./axiosHttpMarconi";
import { franquiciaService } from "./franquicia.service";
export const tarifaClienteService = {
  getTarifasByFranquicia,
  getAllTarifas,
  getSuplementosCentral,
  getAllTarifasAsExcel,
  getTarifasByFranquiciaId,
  getTarifasById,
  nuevoIncremento,
  copiarTarifas,
};

function getTarifasByFranquicia() {
  const axios = createAxios();
  const franquicia = franquiciaService.getLocalFranquicia();
  let urlPath = `franquiciadora/${franquicia._id}/tarifa?sort=codigo&order=ascending`;
  return axios.get(urlPath);
}

function getTarifasById(id) {
  const axios = createAxios();
  const franquicia = JSON.parse(localStorage.getItem("franquicia"));
  let urlPath = `franquiciadora/${franquicia._id}/tarifa_precio_cliente/${id}`;
  return axios.get(urlPath);
}

function copiarTarifas(contract) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciaId}/tarifa_precio_cliente/copiar`;
  return axios.post(urlPath, contract);
}

function getTarifasByFranquiciaId(codigo) {
  const axios = createAxios();
  const franquicia = JSON.parse(localStorage.getItem("franquicia"));
  let urlPath = `franquiciadora/${franquicia._id}/tarifa`;
  if (codigo && codigo != "") {
    urlPath += `?codigo=${codigo}`;
  }
  return axios.get(urlPath);
}

function getSuplementosCentral() {
  const axios = createAxios();
  let urlPath = "/conceptos";
  return axios.get(urlPath);
}

function getAllTarifas(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciaId}/tarifas_precios_cliente`;
  if (pagination) {
    urlPath += `?sort=desde&order=ascending&page=${
      pagination.currentPage - 1
    }&limit=${pagination.perPage}`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += getFilters(filters);
  return axios.get(urlPath);
}

function getAllTarifasAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciaId}/tarifa_precios_cliente/excel?limit=2000`;
  urlPath += getFilters(filters);
  urlPath += "&sort=desde&order=descending";
  return axios.get(urlPath, { responseType: "blob" });
}

function getFilters(filters) {
  let urlPath = "";
  if (filters && filters.date) {
    urlPath += `&desde=${filters.date[0]}`;
    urlPath += `&hasta=${filters.date[1]}`;
  }
  if (filters && filters.tipo) {
    urlPath += `&tipo=${filters.tipo}`;
  }
  if (filters && filters.codigoTarifa) {
    urlPath += `&codigo=${filters.codigoTarifa}`;
  }
  if (filters && filters.descripcion) {
    urlPath += `&descripcion=${filters.descripcion}`;
  }
  if (filters && filters.servicio) {
    urlPath += `&servicio=${filters.servicio}`;
  }
  if (filters && filters.geografico) {
    urlPath += `&geografico=${filters.geografico}`;
  }
  if (filters && filters.franquicia) {
    urlPath += `&franquicia=${filters.franquicia}`;
  }
  if (filters.fecha) {
    urlPath += `&dateStart=${filters.fecha[0]}`;
    urlPath += `&dateEnd=${filters.fecha[1]}`;
  }
  if (filters.vigente != null) {
    urlPath += `&vigente=${filters.vigente}`;
  }
  return urlPath;
}

function nuevoIncremento(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciaId}/tarifa_precios/incremento`;
  return axios.post(urlPath, request);
}
