import createAxios from "./axiosHttpMarconi";
function getOperativasVistas() {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/vistas_control`;
  return axios.get(urlPath);
}
function updateOperativasVistas(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/vistas_control/${body.operativaId}`;
  return axios.put(urlPath, body);
}
export const operativaService = {
  getOperativasVistas,
  updateOperativasVistas
};