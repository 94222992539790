<template>
  <filter-base :widthFilter="600" ref="filtroBase">
    <template v-slot:body>
      <el-form
        label-position="top"
        :model="localValue"
        ref="dataFilter"
        class="px-4 py-3"
      >
        <el-form-item class="input-class" prop="servicio">
          <el-input v-model="localValue.servicio" placeholder="Servicio"></el-input>
        </el-form-item>
        <el-form-item class="input-class" prop="producto">
          <el-input v-model="localValue.producto" placeholder="producto"></el-input>
        </el-form-item>
        <el-form-item class="input-class" prop="nombreServicio">
          <el-input v-model="localValue.nombreServicio" placeholder="Servicio Seur"></el-input>
        </el-form-item>
        <el-form-item class="input-class" prop="nombreProducto">
          <el-input v-model="localValue.nombreProducto" placeholder="Producto Seur"></el-input>
        </el-form-item>
        <el-form-item align="right">
          <el-button type="primary" @click="search()">Buscar</el-button>
          <el-button @click="clearFilters()">Limpiar</el-button>
        </el-form-item>
      </el-form>
    </template>
  </filter-base>
</template>
<script>
import FilterBase from "../../../../components/FilterBase.vue";
export default {
  components: { FilterBase },
  props: {
    value: {
      required: true,
      default: () => {
        return {
          servicio: "",
          producto: "",
          nombreServicio: "",
          nombreProducto: "",
        };
      },
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    clearFilters() {
      this.localValue = {
        servicio: "",
        producto: "",
        nombreServicio: "",
        nombreProducto: "",
      };
      this.$emit("onFilterChange", this.localValue);
      this.closeFiltro();
    },
    search() {
      this.$emit("onFilterChange", this.localValue);
      this.closeFiltro();
    },
    closeFiltro() {
      this.$refs.filtroBase.closeFiltro();
    },
  },
  watch: {},
};
</script>