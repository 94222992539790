import createAxios from "./axiosHttpMarconi";
export const gestionConfiguracionService = {
  getGestionConfiguracion,
  nuevaGestionConfiguracion,
  updateGestionConfiguracion,
  deleteGestionConfiguracion
};

function deleteGestionConfiguracion(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciaId}/mobile/configuracion/${id}`;
  return axios.delete(urlPath);
}

function nuevaGestionConfiguracion(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciaId}/mobile/configuracion`;
  return axios.post(urlPath, body);
}

function updateGestionConfiguracion(body, idConfiguracion) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciaId}/mobile/configuracion/${idConfiguracion}`;
  return axios.put(urlPath, body);
}

function getGestionConfiguracion(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciaId}/mobile/configuracion`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += '?page=0&limit=10';
  }
  if (filters && filters.config) {
    urlPath += `&config=${filters.config}`;
  }
  if (filters && filters.activado != null) {
    urlPath += `&activado=${filters.activado}`;
  }
  return axios.get(urlPath);
}