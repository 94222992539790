import { estadosParadasService } from "@/_services";

export const estadosParadas = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    saveEstado: {
      data: {},
      status: {},
    },
    estado: {
      data: {},
      status: {},
    },
    update: {
      data: {},
      status: {},
    },
    excel: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getEstadosRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getEstadosError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getEstadosSuccess(state, estados) {
      state.list.data = estados;
      state.list.status = {
        loaded: true,
      };
    },
    saveEstadoRequest(state) {
      state.saveEstado.status = {
        loading: true,
      };
    },
    saveEstadoError(state, error) {
      state.saveEstado.data = [];
      state.saveEstado.status = {
        error,
      };
    },
    saveEstadoSuccess(state, estado) {
      state.saveEstado.data = estado;
      state.saveEstado.status = {
        loaded: true,
      };
    },
    getEstadoByIdRequest(state) {
      state.estado.status = {
        loading: true,
      };
    },
    getEstadoByIdError(state, error) {
      state.estado.data = {};
      state.estado.status = {
        error,
      };
    },
    getEstadoByIdSuccess(state, estado) {
      state.estado.data = estado;
      state.estado.status = {
        loaded: true,
      };
    },
    updateEstadoRequest(state) {
      state.update.status = {
        loading: true,
      };
    },
    updateEstadoError(state, error) {
      state.update.data = {};
      state.update.status = {
        error,
      };
    },
    updateEstadoSuccess(state, estado) {
      state.update.data = estado;
      state.update.status = {
        loaded: true,
      };
    },
    getEstadosExcelRequest(state) {
      state.excel.status = {
        downloading: true,
      };
    },
    getEstadosExcelError(state, error) {
      state.excel.data = {};
      state.excel.status = {
        error,
      };
    },
    getEstadosExcelSuccess(state, excel) {
      state.excel.data = excel;
      state.excel.status = {
        downloaded: true,
      };
    },
  },
  actions: {
    getEstados({ commit }, req) {
      commit("getEstadosRequest");
      estadosParadasService
        .getEstados(req.pagination, req.filters)
        .then((estados) => {
          commit("getEstadosSuccess", estados);
        })
        .catch((error) => {
          commit("getEstadosError", error);
        });
    },
    saveEstado({ commit }, body) {
      commit("saveEstadoRequest");
      estadosParadasService
        .saveEstado(body)
        .then((estados) => {
          commit("saveEstadoSuccess", estados);
        })
        .catch((error) => {
          commit("saveEstadoError", error);
        });
    },
    getEstadoById({ commit }, id) {
      commit("getEstadoByIdRequest");
      estadosParadasService
        .getEstadoById(id)
        .then((estado) => {
          commit("getEstadoByIdSuccess", estado);
        })
        .catch((error) => {
          commit("getEstadoByIdError", error);
        });
    },
    updateEstadoParadas({ commit }, body) {
      commit("updateEstadoRequest");
      estadosParadasService
        .updateEstadoParadas(body)
        .then((estado) => {
          commit("updateEstadoSuccess", estado);
        })
        .catch((error) => {
          commit("updateEstadoError", error);
        });
    },
    getEstadosParadasExcel({ commit }, filters) {
      commit("getEstadosExcelRequest");
      estadosParadasService
        .getEstadosParadasExcel(filters)
        .then((estado) => {
          commit("getEstadosExcelSuccess", estado);
        })
        .catch((error) => {
          commit("getEstadosExcelError", error);
        });
    },
  },
};
