<template>
  <modal @hide="hideModal" class="modal-status">
    <template v-slot:header>
      <h5 style="color: #212529">Cambiar Contraseña</h5>
    </template>
    <template v-slot:body>
      <div class="row">
        <el-form :model="usuario" :rules="rulesPassword" ref="changePassword">
          <el-form-item prop="password" label="Contraseña actual">
            <el-input
              v-model="usuario.password"
              placeholder="Contraseña actual"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="newPassword" label="Nueva contraseña">
            <el-input
              v-model="usuario.newPassword"
              placeholder="Nueva contraseña"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="confirmPassword" label="Confirmar contraseña">
            <el-input
              v-model="usuario.confirmPassword"
              placeholder="Confirmar Contraseña"
              show-password
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="hideModal">
        Cancelar
      </button>
      <button type="button" class="btn btn-primary" @click="editedpassword">
        Aceptar
      </button>
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/ModalHotakauth.vue";
export default {
  components: { Modal },
  data() {
    const validate = (rulesPassword, value, callback) => {
      if (value !== "") {
        if (value !== this.usuario.newPassword) {
          callback(new Error("Las contraseñas no coinciden"));
        } else {
          callback();
        }
      }
    };
    return {
      usuario: {
        user: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      nombreUsuario: null,
      rulesPassword: {
        password: [
          {
            required: true,
            message: "Indique la contraseña actual",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "Indique la nueva contraseña",
            trigger: "blur",
          },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
            message:
              "Mínimo 8 carácteres, con alguna minúscula, mayúscula y número, no se permiten puntos ni símbolos",
          },
          { validator: validate, trigger: "blur" },
        ],
        confirmPassword: [
          {
            required: true,
            message: "Confirme la contraseña",
            trigger: "blur",
          },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
            message:
              "Mínimo 8 carácteres, con alguna minúscula, mayúscula y número, no se permiten puntos ni símbolos",
          },
          { validator: validate, trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.nombreUsuario = JSON.parse(localStorage.getItem("user"));
  },
  computed: {
    changePassword() {
      return this.$store.state.user.editPass.status;
    },
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
    editedpassword() {
      this.$refs["changePassword"].validate((valid) => {
        if (valid) {
          this.usuario.user = this.nombreUsuario.user;
          this.usuario.userId = this.nombreUsuario.userId;
          this.$store.dispatch("user/changePassword", this.usuario);
        }
      });
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
  },
  watch: {
    changePassword() {
      if (this.$store.state.user.editPass.status.edited) {
        this.notificacion(
          "Correcto!",
          "Tu contraseña ha sido cambiada correctamente!",
          "success"
        );
        setTimeout(() => {
          localStorage.removeItem("user");
          localStorage.removeItem("empleado");
          this.$router.push({ name: "Login" });
        }, 500);
      } else if (this.$store.state.user.editPass.status.error) {
        this.notificacion(
          "Error!",
          this.$store.state.user.editPass.status.error.error,
          "danger"
        );
      }
    },
  },
};
</script>
<style>
.modal-status .modal-container {
  width: 500px !important;
}
</style>