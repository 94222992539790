import createAxios from "./axiosHttpMarconi";
export const estadosService = {
  getEstados,
  saveEstado,
  getEstadoById,
  updateEstado,
  getEstadosExcel,
};

function getEstados(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/estado`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  urlPath += `&sort=codigo&order=ascending`;
  urlPath += getFiltersPath(filters);
  return axios.get(urlPath);
}
function getFiltersPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.codigo) {
    urlPathFilters += `&codigo=${filters.codigo}`;
  }

  if (filters && filters.tipo) {
    urlPathFilters += `&tipo=${filters.tipo}`;
  }

  if (filters && filters.descripcionOxperta) {
    urlPathFilters += `&nombre=${filters.descripcionOxperta}`;
  }

  if (filters && filters.defecto) {
    urlPathFilters += `&defecto=${filters.defecto}`;
  }
  return urlPathFilters;
}

function saveEstado(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/estado`;
  return axios.post(urlPath, body);
}

function getEstadoById(id) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/estado/${id}`;
  return axios.get(urlPath);
}

function updateEstado(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/estado/${body._id}`;
  return axios.put(urlPath, body);
}

function getEstadosExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/estados/excel?limit=2000`;
  urlPath += getFiltersPath(filters);
  return axios.get(urlPath, { responseType: "blob" }).catch(async (error) => {
    const data = await error.text();
    throw JSON.parse(data);
  });
}
