import { createAxios } from "./axiosHttpMarconi";

export const eventoService = {
  getEventos,
  deleteEvento,
  nuevoEvento
};

function getEventos(pagination,filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/mobile/evento`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += "?page=0";
  }
  urlPath+= getUrlPath(filters);
  return axios.get(urlPath);
}

function getUrlPath(filters) {
  let urlPath="";
  if (filters && filters.estado) {
    urlPath += `&estado=${filters.estado}`;
  }
  if (filters && filters.estadoMobile) {
    urlPath += `&estadoMobile=${filters.estadoMobile}`;
  }
  return urlPath;
}


function deleteEvento(eventoId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/mobile/evento/${eventoId}`;
  return axios.delete(urlPath);
}

function nuevoEvento(request) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/mobile/evento`;
  return axios.post(urlPath,request);
}
