import createAxios from "./axiosHttpMarconi";

function getNotificacionesDashboard(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/notificaciones-dashboard`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.order) {
    urlPath += `&sort=createdAt&order=${filters.order}`;
  } else {
    urlPath += `&sort=createdAt&order=descending`;
  }
  if (filters && filters.visible) {
    urlPath += `&visible=${filters.visible}`;
  }
  if (filters && filters.vigente) {
    urlPath += `&vigente=${filters.vigente}`;
  }
  return axios.get(urlPath);
}

function newNotificacion(body, adjunto) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciaId}/notificaciones-dashboard`;
  const formData = new FormData();

  if (adjunto) formData.append("adjunto", adjunto);
  formData.append("datos", JSON.stringify(body));

  return axios.post(urlPath, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export const notificacionesDashboardService = {
  getNotificacionesDashboard,
  newNotificacion,
};
