import { createAxios } from './axiosHttpMarconi';
import { userService } from './user.service';

export const franquiciadoraService = {
  getFranquiciadora, loadFranquiciadora,
  reloadFranquiciadora, getFranquiciadoraData
}


function getFranquiciadora() {
  const axios = createAxios();
  let urlPath = `/franquiciadora`;
  return axios.get(urlPath);
}

function loadFranquiciadora() {
  return new Promise((resolve, reject) => {
    getFranquiciadoraData().then((franquicia) => {
      localStorage.setItem('franquiciadora', JSON.stringify(franquicia));
      resolve(franquicia);
    }).catch((error) => {
      reject(error);
    });
  });
}

function getFranquiciadoraData() {
  return new Promise((resolve, reject) => {
    const axios = createAxios();
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && userService.esFranquiciadora(user)) {
      let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}`;
      return axios.get(urlPath)
        .then((response) => { resolve(response); })
        .catch((reason) => { reject(reason) });
    } else {
      reject(new Error("Usuario no logado"))
    }
  });
}

function reloadFranquiciadora() {
  setInterval(() => { loadFranquiciadora(); }, 3600000);
}
