import { createAxios } from "./axiosHttpMarconi";
export const otrosService = { getOtrosMasivos, getOtrosDownload };

function getOtrosMasivos(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/otros/masivos`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=10";
  }
  if (filters && filters.date) {
    urlPath += `&fechaInicio=${filters.date[0]}`;
    urlPath += `&fechaFin=${filters.date[1]}`;
  }
  if (filters && filters.order) {
    urlPath += `&sort=createdAt&order=${filters.order}`;
  } else {
    urlPath += `&sort=createdAt&order=descending`;
  }
  if (filters && filters.estados) {
    urlPath += `&estados=${filters.estados}`;
  }
  if (filters && filters.tipo) {
    urlPath += `&tipo=${filters.tipo}`;
  }
  if (filters && filters.filename) {
    urlPath += `&filename=${filters.filename}`;
  }
  return axios.get(urlPath);
}

function getOtrosDownload(idOtros) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/otros/masivo/${idOtros}/download`;
  return axios.get(urlPath, {
    responseType: "blob",
  });
}
