var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("auth-layout", { attrs: { pageClass: "register-page" } }, [
    _c("div", { staticClass: "row d-flex justify-content-center" }, [
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "header-text" }, [
          _c("h2", [_vm._v("Vue Light Bootstrap Dashboard PRO")]),
          _c("h4", [
            _vm._v("Register for free and experience the dashboard today"),
          ]),
          _c("hr"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "col-md-5 ml-auto" },
        [
          _c("fade-render-transition", { attrs: { duration: 200 } }, [
            _c("div", { staticClass: "media" }, [
              _c("div", { staticClass: "media-left" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("i", { staticClass: "nc-icon nc-circle-09" }),
                ]),
              ]),
              _c("div", { staticClass: "media-body" }, [
                _c("h4", [_vm._v("Free Account")]),
                _c("p", [
                  _vm._v(
                    "Here you can write a feature description for your dashboard, let the users know what is the value that you give them."
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "fade-render-transition",
            { attrs: { delay: 200, duration: 200 } },
            [
              _c("div", { staticClass: "media" }, [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "icon" }, [
                    _c("i", {
                      staticClass: "nc-icon nc-preferences-circle-rotate",
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "media-body" }, [
                  _c("h4", [_vm._v("Awesome Performances")]),
                  _c("p", [
                    _vm._v(
                      "Here you can write a feature description for your dashboard, let the users know what is the value that you give them."
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "fade-render-transition",
            { attrs: { delay: 400, duration: 200 } },
            [
              _c("div", { staticClass: "media" }, [
                _c("div", { staticClass: "media-left" }, [
                  _c("div", { staticClass: "icon" }, [
                    _c("i", { staticClass: "nc-icon nc-planet" }),
                  ]),
                ]),
                _c("div", { staticClass: "media-body" }, [
                  _c("h4", [_vm._v("Global Support")]),
                  _c("p", [
                    _vm._v(
                      "Here you can write a feature description for your dashboard, let the users know what is the value that you give them."
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "col-md-4 mr-auto" }, [
        _c(
          "form",
          { attrs: { method: "#", action: "#" } },
          [
            _c("card", { staticClass: "card-plain" }, [
              _c(
                "div",
                [
                  _c("fg-input", { attrs: { placeholder: "Your First Name" } }),
                  _c("fg-input", { attrs: { placeholder: "Your Last Name" } }),
                  _c("fg-input", { attrs: { placeholder: "Company" } }),
                  _c("fg-input", {
                    attrs: { placeholder: "Email", type: "email" },
                  }),
                  _c("fg-input", {
                    attrs: { placeholder: "Password", type: "password" },
                  }),
                  _c("fg-input", {
                    attrs: {
                      placeholder: "Password confirmation",
                      type: "password",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-fill btn-default btn-neutral btn-wd",
                    attrs: { type: "button" },
                  },
                  [_vm._v("Create Free Account")]
                ),
              ]),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }