var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xl-3 col-md-6" },
        [
          _c(
            "stats-card",
            { attrs: { title: "150GB", subTitle: "Numbers" } },
            [
              _c(
                "div",
                {
                  staticClass: "icon-warning",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_c("i", { staticClass: "nc-icon nc-chart text-warning" })]
              ),
              _c("template", { slot: "footer" }, [
                _c("i", { staticClass: "fa fa-refresh" }),
                _vm._v("Updated now "),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xl-3 col-md-6" },
        [
          _c(
            "stats-card",
            { attrs: { title: "$ 1,345", subTitle: "Revenue" } },
            [
              _c(
                "div",
                {
                  staticClass: "icon-success",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_c("i", { staticClass: "nc-icon nc-light-3 text-success" })]
              ),
              _c("template", { slot: "footer" }, [
                _c("i", { staticClass: "fa fa-calendar-o" }),
                _vm._v("Last day "),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xl-3 col-md-6" },
        [
          _c(
            "stats-card",
            { attrs: { title: "23", subTitle: "Errors" } },
            [
              _c(
                "div",
                {
                  staticClass: "icon-danger",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [_c("i", { staticClass: "nc-icon nc-vector text-danger" })]
              ),
              _c("template", { slot: "footer" }, [
                _c("i", { staticClass: "fa fa-clock-o" }),
                _vm._v("Last day "),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xl-3 col-md-6" },
        [
          _c(
            "stats-card",
            { attrs: { title: "+45K", subTitle: "Followers" } },
            [
              _c(
                "div",
                {
                  staticClass: "icon-info",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("i", {
                    staticClass: "nc-icon nc-favourite-28 text-primary",
                  }),
                ]
              ),
              _c("template", { slot: "footer" }, [
                _c("i", { staticClass: "fa fa-refresh" }),
                _vm._v("Updated now "),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-4" },
        [
          _c(
            "chart-card",
            { attrs: { "chart-data": _vm.pieChart.data, "chart-type": "Pie" } },
            [
              _c("template", { slot: "header" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Email Statistics"),
                ]),
                _c("p", { staticClass: "card-category" }, [
                  _vm._v("Last Campaign Performance"),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c("div", { staticClass: "legend" }, [
                  _c("i", { staticClass: "fa fa-circle text-info" }),
                  _vm._v(" Open "),
                  _c("i", { staticClass: "fa fa-circle text-danger" }),
                  _vm._v(" Bounce "),
                  _c("i", { staticClass: "fa fa-circle text-warning" }),
                  _vm._v(" Unsubscribe "),
                ]),
                _c("hr"),
                _c("div", { staticClass: "stats" }, [
                  _c("i", { staticClass: "fa fa-clock-o" }),
                  _vm._v(" Campaign sent 2 days ago "),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-8" },
        [
          _c(
            "chart-card",
            {
              attrs: {
                "chart-data": _vm.lineChart.data,
                "chart-options": _vm.lineChart.options,
                "responsive-options": _vm.lineChart.responsiveOptions,
              },
            },
            [
              _c("template", { slot: "header" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Users Behavior"),
                ]),
                _c("p", { staticClass: "card-category" }, [
                  _vm._v("24 Hours performance"),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c("div", { staticClass: "legend" }, [
                  _c("i", { staticClass: "fa fa-circle text-info" }),
                  _vm._v(" Open "),
                  _c("i", { staticClass: "fa fa-circle text-danger" }),
                  _vm._v(" Click "),
                  _c("i", { staticClass: "fa fa-circle text-warning" }),
                  _vm._v(" Click Second Time "),
                ]),
                _c("hr"),
                _c("div", { staticClass: "stats" }, [
                  _c("i", { staticClass: "fa fa-history" }),
                  _vm._v(" Updated 3 minutes ago "),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c(
            "chart-card",
            {
              attrs: {
                "chart-data": _vm.barChart.data,
                "chart-options": _vm.barChart.options,
                "chart-responsive-options": _vm.barChart.responsiveOptions,
                "chart-type": "Bar",
              },
            },
            [
              _c("template", { slot: "header" }, [
                _c("h4", { staticClass: "card-title" }, [_vm._v("2014 Sales")]),
                _c("p", { staticClass: "card-category" }, [
                  _vm._v("All products including Taxes"),
                ]),
              ]),
              _c("template", { slot: "footer" }, [
                _c("div", { staticClass: "legend" }, [
                  _c("i", { staticClass: "fa fa-circle text-info" }),
                  _vm._v(" Tesla Model S "),
                  _c("i", { staticClass: "fa fa-circle text-danger" }),
                  _vm._v(" BMW 5 Series "),
                ]),
                _c("hr"),
                _c("div", { staticClass: "stats" }, [
                  _c("i", { staticClass: "fa fa-check" }),
                  _vm._v(" Data information certified "),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c(
            "card",
            {
              staticClass: "card-tasks",
              attrs: { title: "Tasks", subTitle: "Backend development" },
            },
            [
              _c("l-table", {
                attrs: { data: _vm.tableData.data },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "td",
                          [
                            _c("Checkbox", {
                              model: {
                                value: row.checked,
                                callback: function ($$v) {
                                  _vm.$set(row, "checked", $$v)
                                },
                                expression: "row.checked",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", [_vm._v(_vm._s(row.title))]),
                        _c(
                          "td",
                          {
                            staticClass:
                              "td-actions d-flex justify-content-end",
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.top-center",
                                    value: _vm.editTooltip,
                                    expression: "editTooltip",
                                    modifiers: { "top-center": true },
                                  },
                                ],
                                staticClass: "btn btn-info btn-simple btn-link",
                              },
                              [_c("i", { staticClass: "fa fa-edit" })]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip.top-center",
                                    value: _vm.deleteTooltip,
                                    expression: "deleteTooltip",
                                    modifiers: { "top-center": true },
                                  },
                                ],
                                staticClass:
                                  "btn btn-danger btn-simple btn-link",
                              },
                              [_c("i", { staticClass: "fa fa-times" })]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "div",
                {
                  staticClass: "stats",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("i", { staticClass: "fa fa-history" }),
                  _vm._v(" Updated 3 minutes ago "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }