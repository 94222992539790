<template>
  <div class="container-box ps-3 pe-3">
    <div class="row">
      <div class="col-12 p-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Mobile</li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ $route.name }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <actions @triggerEvent="triggerEvent" :acciones="acciones" />
    </div>
    <div class="row pt-2">
      <div class="col-md-5 col-sm-8 p-0">
        <Pagination @paginationChange="paginationChange" v-model="pagination" />
      </div>
      <div class="col"></div>
      <div class="col-md-3 col-sm-2 p-0">
        <resumen-busqueda :filtrosProp="filtersLimpios" />
      </div>
      <div class="col-md-1 col-sm-8 p-0 d-flex flex-row-reverse">
        <filters @onFilterChange="onFilterChange" v-model="filters" />
      </div>
    </div>
    <div class="row pt-2">
      <el-table
        highlight-current-row
        class="table-eventos"
        @current-change="handleSelectionChange"
        :data="allEventos"
        ref="singleTable"
        v-loading="loading"
      >
        <el-table-column label="Código Mobile" prop="estadoMobile" />
        <el-table-column label="Código Estado" prop="estado" />
        <el-table-column label="Nombre estado" prop="nombreEstado" />
      </el-table>
    </div>
    <nuevo-evento
      v-if="showNuevoEventos"
      @hide="closeNuevoEvento"
      @guardado="getEventos"
    />
    <modal-destruccion
      v-if="showEliminarmodal"
      @accepted="aceptarEliminar"
      @hide="showEliminarmodal = false"
      :titulo="'Eliminar: ' + selection.nombreEstado"
      :mensaje="messageEliminar"
    />
  </div>
</template>
<script>
import { Pagination } from "@/components";
import ResumenBusqueda from "@/components/ResumenBusqueda.vue";
import Filters from "./components/Filters.vue";
import Actions from "@/components/Actions.vue";
import NuevoEvento from "./components/NuevoEvento.vue";
import ModalDestruccion from "../../../components/ModalDestruccion.vue";
export default {
  components: {
    Pagination,
    ResumenBusqueda,
    Filters,
    Actions,
    NuevoEvento,
    ModalDestruccion,
  },
  props: {},
  data() {
    return {
      messageEliminar: "",
      showEliminarmodal: false,
      showNuevoEventos: false,
      loading: true,
      allEventos: null,
      selection: null,
      filters: {
        estado: "",
        estadoMobile: "",
      },
      pagination: {
        actualPagina: 0,
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 30, 40, 50],
        totalPages: 0,
        totalItems: 0,
      },
      acciones: [
        {
          name: "nuevo",
          label: "Nuevo",
          evento: "onNuevoEvento",
          icon: "fa-plus-circle",
          activo: true,
        },
        {
          name: "eliminar",
          label: "Eliminar",
          evento: "onEliminarEvento",
          icon: "fa-times",
          activo: false,
        },
        {
          label: "Refrescar",
          evento: "onReloadTable",
          icon: "fa-rotate-right",
          activo: false,
        },
      ],
      events: {
        onNuevoEvento: this.onNuevoEvento,
        onReloadTable: this.getEventos,
        onEliminarEvento: this.onEliminarEvento,
      },
    };
  },
  created() {},
  mounted() {
    this.getEventos();
  },
  computed: {
    eventosStatus() {
      return this.$store.state.evento.list.status;
    },
    deleteStatus() {
      return this.$store.state.evento.delete.status;
    },
    filtersLimpios() {
      return this.resumeFilters(JSON.parse(JSON.stringify(this.filters)));
    },
  },
  methods: {
    resumeFilters(filters) {
      let filtersCleaned = {};
      if (filters.estado != "") filtersCleaned.estado = filters.estado;
      if (filters.estadoMobile != "")
        filtersCleaned.estadoMobile = filters.estadoMobile;
      return filtersCleaned;
    },
    onNuevoEvento() {
      this.showNuevoEventos = true;
    },
    closeNuevoEvento() {
      this.showNuevoEventos = false;
    },
    handleSelectionChange(val) {
      this.selection = val;
    },
    onFilterChange(value) {
      this.filters = value;
      localStorage.setItem("filtersEventos", JSON.stringify(this.filters));
      this.pagination.actualPagina -= this.pagination.currentPage + 1;
      this.pagination.currentPage = 1;
      setTimeout(() => {
        this.getEventos();
      }, 250);
    },
    getEventos() {
      this.$store.dispatch("evento/getEventos", {
        pagination: this.pagination,
        query: this.resumeFilters(this.filters),
      });
      this.loading = true;
    },
    paginationChange(pagination) {
      this.pagination = pagination;
      this.getEventos();
    },
    triggerEvent(eventKey) {
      this.events[eventKey]();
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
    onEliminarEvento() {
      if (this.selection != null) {
        this.messageEliminar = `¿Estás seguro de realizar esta acción?`;
        this.showEliminarmodal = true;
      } else {
        this.notificacion(
          "Advertencia",
          "Debe seleccionar al menos un registro",
          "warning"
        );
      }
    },
    aceptarEliminar() {
      this.$store.dispatch("evento/deleteEvento", this.selection._id);
      this.loading = true;
    },
  },
  watch: {
    eventosStatus(val) {
      if (val.loaded) {
        if (this.$store.state.evento.list.data) {
          this.allEventos = this.$store.state.evento.list.data.items;
          this.pagination.totalItems = this.$store.state.evento.list.data.total;
        }
        this.loading = false;
      }
    },
    deleteStatus(val) {
      if (val.erased) {
        this.notificacion(
          "Correcto!",
          "Se ha borrado correctamente!",
          "success"
        );
        this.showEliminarmodal = false;
        this.getEventos();
      }
      if (val.error) {
        this.notificacion("Error!", "Ha ocurrido un error!", "danger");
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.table-eventos {
  padding-left: 0px;
  padding-right: 0px;
}

.table-eventos .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.container-box {
  margin-top: -1.5rem;
  margin-bottom: -22px !important;
}
</style>