import { franquiciadoraService } from '@/_services';

export const franquiciadora = {
  namespaced: true,
  state: {
    entity: {
      data: [],
      status: {},
    },
    update:{
      data: [],
      status: {},
    }
  },
  mutations: {
    getFranquiciadoraRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    cancelentityRequest(state, error) {
      state.entity.data = [];
      state.entity.status = {
        error,
      };
    },
    getFranquiciadoraSuccess(state, franquicia) {
      state.entity.data = franquicia;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getFranquiciasById({ commit }, idFranquicia) {
      commit('getFranquiciasByIdRequest');
      franquiciadoraService
        .getFranquiciasById(idFranquicia)
        .then((franquicias) => {
          commit('getFranquiciasByIdSuccess', franquicias);
        })
        .catch((error) => {
          commit('cancelFranquiciasByIdRequest', error);
        });
    },
    getFranquiciadora({ commit }) {
      commit('getFranquiciadoraRequest');
      franquiciadoraService
        .getFranquiciadora()
        .then((franquicias) => {
          commit('getFranquiciadoraSuccess', franquicias);
        })
        .catch((error) => {
          commit('cancelentityRequest', error);
        });
    },
  },
};

