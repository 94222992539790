import { operativaService, } from '@/_services';
export const operativaVistas = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    }
  },
  mutations: {
    getOperativasVistasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelOperativasVistasRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        loading: false,
        error,
      };
    },
    getOperativasVistasSuccess(state, env) {
      state.list.data = env;
      state.list.status = {
        loaded: true,
      };
    },
    updateOperativasVistasRequest(state) {
      state.update.status = {
        loading: true,
      };
    },
    cancelOperativasEnviosVistasRequest(state, error) {
      state.update.data = [];
      state.update.status = {
        loading: false,
        error,
      };
    },
    updateOperativasVistasSuccess(state, env) {
      state.update.data = env;
      state.update.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getOperativasVistas({ commit }) {
      commit('getOperativasVistasRequest');
      operativaService.getOperativasVistas()
        .then((env) => {
          commit('getOperativasVistasSuccess', env);
        })
        .catch((error) => {
          commit('cancelOperativasVistasRequest', error);
        });
    },
    updateOperativasVistas({ commit }, body) {
      commit('updateOperativasVistasRequest');
      operativaService.updateOperativasVistas(body)
        .then((env) => {
          commit('updateOperativasVistasSuccess', env);
        })
        .catch((error) => {
          commit('cancelOperativasEnviosVistasRequest', error);
        });
    },
  },
};