import { createAxios } from "./axiosHttpMarconi";
export const rmaService = { getRmas, getRmaMasivos, getRmaDownload, caducarRevertirRma, sendEnvioRma };

function sendEnvioRma(body) {
  let franquiciadora = JSON.parse(localStorage.getItem("franquiciadora"));
  const axios = createAxios();
  const urlPath = `franquiciadora/${franquiciadora._id}/rma`;
  return axios.post(urlPath, body);
}

function caducarRevertirRma(idRma, body) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("franquiciadora"));
  let urlPath = `/franquiciadora/${franquiciadora._id}/rma/${idRma}`;
  return axios.put(urlPath, body);
}

function getRmaDownload(idRma) {
  const axios = createAxios();
  const franquicia = JSON.parse(localStorage.getItem('franquicia'));
  let urlPath = `/franquiciadora/${franquicia._id}/rma/masivo/${idRma}/download`;
  return axios.get(urlPath, {
    responseType: 'blob'
  });
}

function getRmas(pagination, body) {
  const axios = createAxios();
  let franquiciadora = JSON.parse(localStorage.getItem("franquiciadora"));
  let urlPath = `/franquiciadora/${franquiciadora._id}/rmas`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage
      }`;
  } else {
    urlPath += "?";
  }
  if (body.fechaSolicitud) {
    urlPath += `&dateStart=${body.fechaSolicitud[0]}`;
    urlPath += `&dateEnd=${body.fechaSolicitud[1]}`;
  }
  if (body && body.idCliente) {
    urlPath += `&cliente=${body.idCliente}`;
  }
  if (body.codigo) {
    urlPath += `&codigo=${body.codigo}`;
  }
  if (body.referencia) {
    urlPath += `&referencia=${body.referencia}`;
  }
  if (body.remitente) {
    urlPath += `&remitente=${body.remitente}`;
  }
  if (body.fechaCaducidad) {
    urlPath += `&fechaCaducidad=${body.fechaCaducidad}`;
  }
  if (body && body.idFranquicia) {
    urlPath += `&idFranquicia=${body.idFranquicia}`;
  }
  if (body.estado) {
    urlPath += `&estado=${body.estado}`;
  }
  return axios.get(urlPath, franquiciadora);
}

function getRmaMasivos(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/rma/masivos`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${pagination.perPage}`;
  } else {
    urlPath += '?page=0&limit=10';
  }
  if (filters && filters.date) {
    urlPath += `&fechaInicio=${filters.date[0]}`;
    urlPath += `&fechaFin=${filters.date[1]}`;
  }
  if (filters && filters.idFranquicia) {
    urlPath += `&idFranquicia=${filters.idFranquicia}`;
  }
  if (filters && filters.order) {
    urlPath += `&sort=createdAt&order=${filters.order}`
  } else {
    urlPath += `&sort=createdAt&order=descending`
  }
  if (filters && filters.estados) {
    urlPath += `&estados=${filters.estados}`
  }
  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }
  if (filters && filters.nombreCliente) {
    urlPath += `&nombreCliente=${filters.nombreCliente}`
  }
  if (filters && filters.filename) {
    urlPath += `&filename=${filters.filename}`
  }
  return axios.get(urlPath);
}
