import { envioVistasService, } from '@/_services';

export const enviosVistas = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    getById: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    }
  },
  mutations: {
    getEnviosVistasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelEnviosVistasRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        loading: false,
        error,
      };
    },
    getEnviosVistasSuccess(state, env) {
      state.list.data = env;
      state.list.status = {
        loaded: true,
      };
    },
    getEnviosVistasByIdRequest(state) {
      state.getById.status = {
        loading: true,
      };
    },
    cancelEnviosVistasByIdRequest(state, error) {
      state.getById.data = [];
      state.getById.status = {
        loading: false,
        error,
      };
    },
    getEnviosVistasByIdSuccess(state, env) {
      state.getById.data = env;
      state.getById.status = {
        loaded: true,
      };
    },
    updateEnviosVistasRequest(state) {
      state.update.status = {
        loading: true,
      };
    },
    cancelUpdateEnviosVistasRequest(state, error) {
      state.update.data = [];
      state.update.status = {
        loading: false,
        error,
      };
    },
    updateEnviosVistasSuccess(state, env) {
      state.update.data = env;
      state.update.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getEnviosVistas({ commit }, pagination) {
      commit('getEnviosVistasRequest');
      envioVistasService.getEnviosVistas(pagination)
        .then((env) => {
          commit('getEnviosVistasSuccess', env);
        })
        .catch((error) => {
          commit('cancelEnviosVistasRequest', error);
        });
    },
    getEnviosVistasById({ commit }, idEnvioVista) {
      commit('getEnviosVistasByIdRequest');
      envioVistasService.getEnviosVistasById(idEnvioVista)
        .then((env) => {
          commit('getEnviosVistasByIdSuccess', env);
        })
        .catch((error) => {
          commit('cancelEnviosVistasByIdRequest', error);
        });
    },
    updateEnviosVistas({ commit }, body) {
      commit('updateEnviosVistasRequest');
      envioVistasService.updateEnviosVistas(body)
        .then((env) => {
          commit('updateEnviosVistasSuccess', env);
        })
        .catch((error) => {
          commit('cancelUpdateEnviosVistasRequest', error);
        });
    },
  },
};
