import { fechaCierreService } from '@/_services';

export const fechaCierre = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    nuevaFecha:{
      data:{},
      status:{}
    },
    fecha:{
      data:{},
      status:{}
    },
    update:{
      data:{},
      status:{}
    }
  },
  mutations: {
    getFechaCierreRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getFechaCierreError(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getFechaCierreSuccess(state, fechas) {
      state.list.data = fechas;
      state.list.status = {
        loaded: true,
      };
    },
    nuevaFechaCierreRequest(state) {
      state.nuevaFecha.status = {
        loading: true,
      };
    },
    nuevaFechaCierreError(state, error) {
      state.nuevaFecha.data = [];
      state.nuevaFecha.status = {
        error,
      };
    },
    nuevaFechaCierreSuccess(state, estado) {
      state.nuevaFecha.data = estado;
      state.nuevaFecha.status = {
        loaded: true,
      };
    },
    getFechaByIdRequest(state) {
      state.fecha.status = {
        loading: true,
      };
    },
    getFechaByIdError(state, error) {
      state.fecha.data = {};
      state.fecha.status = {
        error,
      };
    },
    getFechaByIdSuccess(state, fecha) {
      state.fecha.data = fecha;
      state.fecha.status = {
        loaded: true,
      };
    },
    updateFechaCierreRequest(state) {
      state.update.status = {
        loading: true,
      };
    },
    updateFechaCierreError(state, error) {
      state.update.data = {};
      state.update.status = {
        error,
      };
    },
    updateFechaCierreSuccess(state, fecha) {
      state.update.data = fecha;
      state.update.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getFechaCierre({ commit }, req) {
      commit('getFechaCierreRequest');
      fechaCierreService.getFechaCierre(req.pagination,req.filters)
        .then((fechas) => {
          commit('getFechaCierreSuccess', fechas);
        })
        .catch((error) => {
          commit('getFechaCierreError', error);
        });
    },
    nuevaFechaCierre({ commit }, body) {
      commit('nuevaFechaCierreRequest');
      fechaCierreService.nuevaFechaCierre(body)
        .then((fecha) => {
          commit('nuevaFechaCierreSuccess', fecha);
        })
        .catch((error) => {
          commit('nuevaFechaCierreError', error);
        });
    },
    getFechaById({ commit }, id) {
      commit('getFechaByIdRequest');
      fechaCierreService.getFechaById(id)
        .then((estado) => {
          commit('getFechaByIdSuccess', estado);
        })
        .catch((error) => {
          commit('getFechaByIdError', error);
        });
    },
    updateFechaCierre({ commit }, body) {
      commit('updateFechaCierreRequest');
      fechaCierreService.updateFechaCierre(body)
        .then((fecha) => {
          commit('updateFechaCierreSuccess', fecha);
        })
        .catch((error) => {
          commit('updateFechaCierreError', error);
        });
    },
  },
};

