import { medidasService } from "@/_services";
export const medida = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    delete: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getMedidasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getMedidasSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getMedidasError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    deleteMedidaRequest(state) {
      state.delete.status = {
        deleting: true,
      };
    },
    deleteMedidaError(state, error) {
      state.delete.data = null;
      state.delete.status = {
        error,
      };
    },
    deleteMedidaSuccess(state, response) {
      state.delete.data = response;
      state.delete.status = {
        deleted: true,
      };
    },
    postMedidaRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postMedidaError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postMedidaSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateMedidaRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateMedidaError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateMedidaSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getMedidaByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getMedidaByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getMedidaByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getMedidas({ commit }, request) {
      commit("getMedidasRequest");
      medidasService
        .getMedidas(request.pagination, request.query)
        .then((response) => {
          commit("getMedidasSuccess", response);
        })
        .catch((error) => {
          commit("getMedidasError", error);
        });
    },
    deleteMedida({ commit }, id) {
      commit("deleteMedidaRequest");
      medidasService
        .deleteMedida(id)
        .then((res) => {
          commit("deleteMedidaSuccess", res);
        })
        .catch((error) => {
          commit("deleteMedidaError", error);
        });
    },
    postMedida({ commit }, request) {
      commit("postMedidaRequest");
      medidasService
        .postMedida(request)
        .then((res) => {
          commit("postMedidaSuccess", res);
        })
        .catch((error) => {
          commit("postMedidaError", error);
        });
    },
    updateMedida({ commit }, request) {
      commit("updateMedidaRequest");
      medidasService
        .updateMedida(request.id, request.body)
        .then((res) => {
          commit("updateMedidaSuccess", res);
        })
        .catch((error) => {
          commit("updateMedidaError", error);
        });
    },
    getMedidaById({ commit }, id) {
      commit("getMedidaByIdRequest");
      medidasService
        .getMedidaById(id)
        .then((res) => {
          commit("getMedidaByIdSuccess", res);
        })
        .catch((error) => {
          commit("getMedidaByIdError", error);
        });
    },
  },
};
