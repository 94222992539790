<template>
  <div class="container-box ps-3 pe-3">
    <div class="row">
      <div class="col-12 p-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Maestro</li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ $route.name }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <actions @triggerEvent="triggerEvent" :acciones="acciones" />
    </div>
    <div class="row pt-2">
      <div class="col-md-5 col-sm-8 p-0">
        <Pagination @paginationChange="paginationChange" v-model="pagination" />
      </div>
      <div class="col"></div>
      <div class="col-md-3 col-sm-2 p-0">
        <resumen-busqueda :filtrosProp="filtersLimpios" />
      </div>
      <div class="col-md-1 col-sm-8 p-0 d-flex flex-row-reverse">
        <filters @onFilterChange="onFilterChange" v-model="filters" />
      </div>
    </div>
    <div class="row pt-2">
      <el-table
        highlight-current-row
        class="table-transito"
        @current-change="handleSelectionChange"
        :data="allGeograficos"
        ref="singleTable"
        v-loading="loading"
      >
        <el-table-column label="Geográfico" prop="tipo">
          <template v-slot="scope">
            {{ labelSinGuionBajo(scope.row.tipo) }}
          </template>
        </el-table-column>
        <el-table-column label="Servicios" prop="servicios">
          <template v-slot="scope">
            {{ scope.row.servicios.join(";") }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <nuevo-editar-geografico
      v-if="showNuevo"
      :idGeo="idGeo"
      @hide="closeNuevoGeografico"
      @guardado="getGeografico"
    />
    <modal-destruccion
      v-if="showEliminarmodal"
      @accepted="aceptarEliminar"
      @hide="showEliminarmodal = false"
      :titulo="'Eliminar: ' + this.selection.tipo"
      :mensaje="messageEliminar"
    />
  </div>
</template>
<script>
import { Pagination } from "@/components";
import ResumenBusqueda from "@/components/ResumenBusqueda.vue";
import Filters from "./components/Filters.vue";
import Actions from "@/components/Actions.vue";
import NuevoEditarGeografico from "./components/NuevoEditarGeografico.vue";
import ModalDestruccion from "../../../components/ModalDestruccion.vue";
export default {
  components: {
    Pagination,
    ResumenBusqueda,
    Filters,
    Actions,
    NuevoEditarGeografico,
    ModalDestruccion,
  },
  props: {},
  data() {
    return {
      servicio: "",
      idGeo: "new",
      messageEliminar: "",
      showEliminarmodal: false,
      showNuevo: false,
      loading: true,
      allGeograficos: null,
      selection: null,
      filters: {
        geografico: "",
      },
      pagination: {
        actualPagina: 0,
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 30, 40, 50],
        totalPages: 0,
        totalItems: 0,
      },
      acciones: [
        {
          name: "nuevo",
          label: "Nuevo",
          evento: "onNuevo",
          icon: "fa-plus-circle",
          activo: true,
        },
        {
          label: "Modificar",
          evento: "onModificar",
          icon: "fa-edit",
          activo: false,
        },
        {
          name: "eliminar",
          label: "Eliminar",
          evento: "onEliminar",
          icon: "fa-times",
          activo: false,
        },
        {
          label: "Refrescar",
          evento: "onReloadTable",
          icon: "fa-rotate-right",
          activo: false,
        },
      ],
      events: {
        onNuevo: this.onNuevoGeografico,
        onReloadTable: this.getGeografico,
        onModificar: this.onModificarGeografico,
        onEliminar: this.onEliminarGeografico,
      },
    };
  },
  created() {},
  mounted() {
    this.getGeografico();
  },
  computed: {
    geograficoStatus() {
      return this.$store.state.geografico.list.status;
    },
    deleteStatus() {
      return this.$store.state.geografico.delete.status;
    },
    filtersLimpios() {
      return this.resumeFilters(JSON.parse(JSON.stringify(this.filters)));
    },
  },
  methods: {
    labelSinGuionBajo(label) {
      return label.replace(/_/g, " ");
    },
    resumeFilters(filters) {
      let filtersCleaned = {};
      if (filters.geografico != "")
        filtersCleaned.geografico = filters.geografico;
      return filtersCleaned;
    },
    onNuevoGeografico() {
      this.idGeo = "new";
      this.showNuevo = true;
    },
    closeNuevoGeografico() {
      this.showNuevo = false;
    },
    onModificarGeografico() {
      if (this.selection != null) {
        this.idGeo = this.selection._id;
        this.showNuevo = true;
      } else {
        this.notificacion("Error", "Seleccione una fila", "warning");
      }
    },
    handleSelectionChange(val) {
      this.selection = val;
    },
    onFilterChange(value) {
      this.filters = value;
      localStorage.setItem("filtersGeografico", JSON.stringify(this.filters));
      this.pagination.actualPagina -= this.pagination.currentPage + 1;
      this.pagination.currentPage = 1;
      setTimeout(() => {
        this.getGeografico();
      }, 250);
    },
    getGeografico() {
      this.$store.dispatch("geografico/getGeografico", {
        pagination: this.pagination,
        query: this.resumeFilters(this.filters),
      });
      this.loading = true;
    },
    paginationChange(pagination) {
      this.pagination = pagination;
      this.getGeografico();
    },
    triggerEvent(eventKey) {
      this.events[eventKey]();
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
    onEliminarGeografico() {
      if (!this.selection) {
        this.notificacion("Error", "Seleccione un registro", "warning");
        return;
      }
      this.messageEliminar = `¿Está seguro que desea eliminar el geografico: ${this.selection.tipo}?`;
      this.showEliminarmodal = true;
    },
    aceptarEliminar() {
      this.$store.dispatch("geografico/deleteGeografico", this.selection._id);
      this.loading = true;
    },
  },
  watch: {
    geograficoStatus(val) {
      if (val.loaded) {
        if (this.$store.state.geografico.list.data) {
          this.allGeograficos = this.$store.state.geografico.list.data.items;
          this.pagination.totalItems =
            this.$store.state.geografico.list.data.total;
        }
        this.loading = false;
      }
    },
    deleteStatus(val) {
      if (val.erased) {
        this.notificacion(
          "Correcto!",
          "Se ha borrado correctamente!",
          "success"
        );
        this.showEliminarmodal = false;
        this.getGeografico();
      }
      if (val.error) {
        this.notificacion("Error!", val.error.error, "error");
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.table-transito {
  padding-left: 0px;
  padding-right: 0px;
}

.table-transito .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.container-box {
  margin-top: -1.5rem;
  margin-bottom: -22px !important;
}
</style>