var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("filter-base", {
    ref: "filtroBase",
    attrs: { widthFilter: 600 },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "el-form",
              {
                ref: "dataFilter",
                staticClass: "px-4 py-3",
                attrs: { "label-position": "top", model: _vm.localValue },
              },
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col-12",
                        attrs: { label: "Servicios", prop: "servicio" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "select",
                            attrs: {
                              filterable: "",
                              clearable: "",
                              placeholder: "Seleccione una opción...",
                            },
                            model: {
                              value: _vm.localValue.servicio,
                              callback: function ($$v) {
                                _vm.$set(_vm.localValue, "servicio", $$v)
                              },
                              expression: "localValue.servicio",
                            },
                          },
                          _vm._l(_vm.allServicios, function (item) {
                            return _c("el-option", {
                              key: item._id,
                              attrs: {
                                label: _vm.getLabel(item),
                                value: item.codigo,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col-6",
                        attrs: { label: "País de Origen", prop: "paisOrigen" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "select",
                            attrs: {
                              filterable: "",
                              placeholder: "Seleccione una opción...",
                            },
                            model: {
                              value: _vm.localValue.paisOrigen,
                              callback: function ($$v) {
                                _vm.$set(_vm.localValue, "paisOrigen", $$v)
                              },
                              expression: "localValue.paisOrigen",
                            },
                          },
                          _vm._l(_vm.allPaises, function (item) {
                            return _c("el-option", {
                              key: item._id,
                              attrs: {
                                label: item.nombre.es,
                                value: item.iso2,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "col-6",
                        attrs: { label: "País Destino", prop: "paisDestino" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "select",
                            attrs: {
                              filterable: "",
                              placeholder: "Seleccione una opción...",
                            },
                            model: {
                              value: _vm.localValue.paisDestino,
                              callback: function ($$v) {
                                _vm.$set(_vm.localValue, "paisDestino", $$v)
                              },
                              expression: "localValue.paisDestino",
                            },
                          },
                          _vm._l(_vm.allPaises, function (item) {
                            return _c("el-option", {
                              key: item._id,
                              attrs: {
                                label: item.nombre.es,
                                value: item.iso2,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { align: "right" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.search()
                          },
                        },
                      },
                      [_vm._v("Buscar")]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clearFilters()
                          },
                        },
                      },
                      [_vm._v("Limpiar")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }