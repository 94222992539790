import { libretaContactoService } from "@/_services";

export const libretaContacto = {
  namespaced: true,
  state: {
    dirHabit: {
      data: null,
      status: {},
    },
    excel: {
      data: null,
      status: {},
    },
    nuevo: {
      data: [],
      status: {},
    },
  },
  mutations: {
    newContactoDireccionRequest(state) {
      state.nuevo.status = {
        loading: true,
      };
    },
    newContactoDireccionSuccess(state, env) {
      state.nuevo.data = env;
      state.nuevo.status = {
        loaded: true,
      };
    },
    newContactoDireccionError(state, error){
      state.nuevo.data = null
      state.nuevo.status={
        error
      }
    },
    getDirHabitByClienteRequest(state) {
      state.dirHabit.status = {
        loading: true,
      };
    },
    cancelDirHabitByClienteRequest(state, error) {
      state.dirHabit.data = [];
      state.dirHabit.status = {
        error,
      };
    },
    getDirHabitByClienteSuccess(state, franquicia) {
      state.dirHabit.data = franquicia;
      state.dirHabit.status = {
        loaded: true,
      };
    },
    getDireccionHabitualByClienteExcelRequest(state) {
      state.excel.status = {
        loading: true,
      };
    },
    getDireccionHabitualByClienteExcelError(state, error) {
      state.excel.data = [];
      state.excel.status = {
        error,
      };
    },
    getDireccionHabitualByClienteExcelSuccess(state, dirHabit) {
      state.excel.data = dirHabit;
      state.excel.status = {
        loaded: true,
      };
    },
  },
  actions: {
    newContactoDireccion({ commit }, nuevo) {
      commit('newContactoDireccionRequest');
      libretaContactoService.newContactoDireccion(nuevo.clienteId,nuevo.body)
        .then((contact) => {
          commit('newContactoDireccionSuccess', contact);
        })
        .catch((error) => {
          commit('newContactoDireccionError', error);
        });
    },
    getDireccionHabitualByCliente({ commit }, body) {
      commit("getDirHabitByClienteRequest");
      libretaContactoService
        .getDireccionHabitualByCliente(body)
        .then((franquicias) => {
          commit("getDirHabitByClienteSuccess", franquicias);
        })
        .catch((error) => {
          commit("cancelDirHabitByClienteRequest", error);
        });
    },
    getDireccionHabitualByClienteExcel({ commit }, body) {
      commit("getDireccionHabitualByClienteExcelRequest");
      libretaContactoService
        .getDireccionHabitualByClienteExcel(body)
        .then((franquicias) => {
          commit("getDireccionHabitualByClienteExcelSuccess", franquicias);
        })
        .catch((error) => {
          commit("getDireccionHabitualByClienteExcelError", error);
        });
    },
  },
};
