<template>
  <modal
    @hide="hideModal"
    class="modal-status-cexdevolucion"
    :loading="loading"
  >
    <template v-slot:header>
      <h5 class="modal-title">Nuevo</h5>
    </template>
    <template v-slot:body>
      <el-form
        ref="eventoReferencia"
        :model="dataEvento"
        label-position="top"
        :rules="rules"
      >
        <div class="row">
          <el-form-item label="Código" prop="estado" class="col-12">
            <el-select
              class="select"
              v-model="dataEvento.estado"
              filterable
              placeholder="Seleccione una opción..."
            >
              <el-option
                v-for="(item, key) in allEstados"
                :key="key"
                :label="item.descripcionOxperta"
                :value="item.codigo"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Código Mobile" prop="estadoMobile" class="col">
            <el-input
              v-model="dataEvento.estadoMobile"
              placeholder="Código Mobile"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="hideModal">
        Cerrar
      </button>
      <button type="button" class="btn btn-primary" @click="aceptar">
        Aceptar
      </button>
    </template>
  </modal>
</template>
<script>
import Modal from "@/components/ModalHotakauth.vue";

export default {
  components: { Modal },
  props: {},
  data() {
    return {
      loading: false,
      allEstados: [],
      dataEvento: {
        estado: "",
        estadoMobile: "",
        nombreEstado: "",
      },
      rules: {
        estado: [
          { required: true, message: "Seleccione un esatdo!", trigger: "blur" },
        ],
        estadoMobile: [
          {
            required: true,
            message: "Codigo de estado mobile requerido!",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.getEstados();
  },
  computed: {
    nuevoStatus() {
      return this.$store.state.evento.nuevo.status;
    },
    estadosState() {
      return this.$store.state.estado.list.status;
    },
  },
  methods: {
    getEstados() {
      this.$store.dispatch("estado/getEstados", "ESTADO");
    },
    hideModal() {
      this.$emit("hide");
    },
    aceptar() {
      this.$refs["eventoReferencia"].validate((valid) => {
        if (valid) {
          const estado = this.allEstados.find(
            (element) => element.codigo == this.dataEvento.estado
          );
          let req = {
            estado: this.dataEvento.estado,
            estadoMobile: this.dataEvento.estadoMobile,
            nombreEstado: estado.nombre,
          };
          this.loading = true;
          this.$store.dispatch("evento/nuevoEvento", req);
        }
      });
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
  },
  watch: {
    nuevoStatus(val) {
      if (val.loaded) {
        this.notificacion("Correcto!", "Creado correctamente!", "success");
        this.$emit("guardado");
        this.hideModal();
        this.loading = false;
      }
      if (val.error) {
        this.notificacion("Error!", val.error.error, "danger");
        this.loading = false;
      }
    },
    estadosState(val) {
      if (val.loaded) {
        if (this.$store.state.estado.list.data) {
          this.allEstados = this.$store.state.estado.list.data.items;
          if (this.tipo == "ANULAR") {
            this.evento.estado = "19";
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.modal-status-cexdevolucion .modal-container {
  width: 700px !important;
}
.select {
  width: 100% !important;
}
</style>
