import createAxios from './axiosHttpMarconi';
export const fechaCierreService = { getFechaCierre, nuevaFechaCierre, getFechaById, updateFechaCierre};

function getFechaCierre(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/fecha_cierre?sort=fecha&order=ascending`;
  if (pagination) {
    urlPath += `&page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "&page=0&limit=10";
  }

  urlPath += getFiltersPath(filters);
  return axios.get(urlPath);
}
function getFiltersPath(filters) {
  let urlPathFilters = "";
  if (filters && filters.anio) {
    urlPathFilters += `&anio=${filters.anio}`;
  }
  return urlPathFilters;
}

function nuevaFechaCierre(body){
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/fecha_cierre`;
  return axios.post(urlPath, body);
}

function getFechaById(id){
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/fecha_cierre/${id}`;
  return axios.get(urlPath);
}

function updateFechaCierre(body){
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/fecha_cierre/${body._id}`;
  return axios.put(urlPath,body);
}