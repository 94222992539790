import { tarifaService } from "@/_services";

export const tarifa = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    allTarifas: {
      data: [],
      status: {},
    },
    tarifa: {
      data: {},
      status: {},
    },
    tarifasAsExcel: {
      data: [],
      status: {},
    },
    allTarifasPartner: {
      data: [],
      status: {},
    },
    tarifaPartner: {
      data: {},
      status: {},
    },
    tarifasPartnerAsExcel: {
      data: [],
      status: {},
    },
    eliminar: {
      data: {},
      status: {},
    },
    editarNombre: {
      data: {},
      status: {},
    },
    tarifasMaestros: {
      data: {},
      status: {},
    },
    tarifaMaestro: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getTarifasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelTarifasRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getTarifasSuccess(state, tarifas) {
      state.list.data = tarifas;
      state.list.status = {
        loaded: true,
      };
    },
    getAllTarifasRequest(state) {
      state.allTarifas.status = {
        loading: true,
      };
    },
    errorAllTarifasRequest(state, error) {
      state.allTarifas.data = [];
      state.allTarifas.status = {
        error,
      };
    },
    getAllTarifasSuccess(state, tarifas) {
      state.allTarifas.data = tarifas;
      state.allTarifas.status = {
        loaded: true,
      };
    },
    getTarifasByIdRequest(state) {
      state.tarifa.status = {
        loading: true,
      };
    },
    getTarifasByIdError(state, error) {
      state.tarifa.data = {};
      state.tarifa.status = {
        error,
      };
    },
    getTarifasByIdSuccess(state, tarifa) {
      state.tarifa.data = tarifa;
      state.tarifa.status = {
        loaded: true,
      };
    },
    tarifasExcelRequest(state) {
      state.tarifasAsExcel.status = {
        loading: true,
      };
    },
    tarifasExcelSuccess(state, pdf) {
      state.tarifasAsExcel.data = pdf;
      state.tarifasAsExcel.status = {
        loaded: true,
      };
    },
    tarifasExcelError(state, error) {
      state.tarifasAsExcel.data = null;
      state.tarifasAsExcel.status = {
        error,
      };
    },
    getAllTarifasPartnerRequest(state) {
      state.allTarifasPartner.status = {
        loading: true,
      };
    },
    getAllTarifasPartnerError(state, error) {
      state.allTarifasPartner.data = [];
      state.allTarifasPartner.status = {
        error,
      };
    },
    getAllTarifasPartnerSuccess(state, tarifas) {
      state.allTarifasPartner.data = tarifas;
      state.allTarifasPartner.status = {
        loaded: true,
      };
    },
    getTarifasPartnerByIdRequest(state) {
      state.tarifaPartner.status = {
        loading: true,
      };
    },
    getTarifasPartnerByIdError(state, error) {
      state.tarifaPartner.data = {};
      state.tarifaPartner.status = {
        error,
      };
    },
    getTarifasPartnerByIdSuccess(state, tarifaPartner) {
      state.tarifaPartner.data = tarifaPartner;
      state.tarifaPartner.status = {
        loaded: true,
      };
    },
    tarifasPartnerExcelRequest(state) {
      state.tarifasPartnerAsExcel.status = {
        loading: true,
      };
    },
    tarifasPartnerExcelSuccess(state, pdf) {
      state.tarifasPartnerAsExcel.data = pdf;
      state.tarifasPartnerAsExcel.status = {
        loaded: true,
      };
    },
    tarifasPartnerExcelError(state, error) {
      state.tarifasPartnerAsExcel.data = null;
      state.tarifasPartnerAsExcel.status = {
        error,
      };
    },
    eliminarRequest(state) {
      state.eliminar.status = {
        deleting: true,
      };
    },
    eliminarSuccess(state, response) {
      state.eliminar.data = response;
      state.eliminar.status = {
        deleted: true,
      };
    },
    eliminarError(state, error) {
      state.eliminar.data = [];
      state.eliminar.status = {
        error,
      };
    },
    editNombreTarifaRequest(state) {
      state.editarNombre.status = {
        editing: true,
      };
    },
    editNombreTarifaError(state, error) {
      state.editarNombre.data = {};
      state.editarNombre.status = {
        error,
      };
    },
    editNombreTarifaSuccess(state, response) {
      state.editarNombre.data = response;
      state.editarNombre.status = {
        edited: true,
      };
    },
    getTarifasMaestrosRequest(state) {
      state.tarifasMaestros.status = {
        loading: true,
      };
    },
    getTarifasMaestrosError(state, error) {
      state.tarifasMaestros.data = {};
      state.tarifasMaestros.status = {
        error,
      };
    },
    getTarifasMaestrosSuccess(state, response) {
      state.tarifasMaestros.data = response;
      state.tarifasMaestros.status = {
        loaded: true,
      };
    },
    getTarifaMaestroByIdRequest(state) {
      state.tarifaMaestro.status = {
        loading: true,
      };
    },
    getTarifaMaestroByIdError(state, error) {
      state.tarifaMaestro.data = {};
      state.tarifaMaestro.status = {
        error,
      };
    },
    getTarifaMaestroByIdSuccess(state, response) {
      state.tarifaMaestro.data = response;
      state.tarifaMaestro.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getTarifasByFranquicia({ commit }) {
      commit("getTarifasRequest");
      tarifaService
        .getTarifasByFranquicia()
        .then((tarifas) => {
          commit("getTarifasSuccess", tarifas);
        })
        .catch((error) => {
          commit("cancelTarifasRequest", error);
        });
    },
    getAllTarifas({ commit }, req) {
      commit("getAllTarifasRequest");
      tarifaService
        .getAllTarifas(req.pagination, req.filters)
        .then((tarifas) => {
          commit("getAllTarifasSuccess", tarifas);
        })
        .catch((error) => {
          commit("errorAllTarifasRequest", error);
        });
    },
    getTarifasById({ commit }, id) {
      commit("getTarifasByIdRequest");
      tarifaService
        .getTarifasById(id)
        .then((tarifas) => {
          commit("getTarifasByIdSuccess", tarifas);
        })
        .catch((error) => {
          commit("getTarifasByIdError", error);
        });
    },
    getAllTarifasAsExcel({ commit }, request) {
      commit("tarifasExcelRequest");
      tarifaService
        .getAllTarifasAsExcel(request)
        .then((excel) => {
          commit("tarifasExcelSuccess", excel);
        })
        .catch((error) => {
          commit("tarifasExcelError", error);
        });
    },
    getAllTarifasPartner({ commit }, req) {
      commit("getAllTarifasPartnerRequest");
      tarifaService
        .getAllTarifasPartner(req.pagination, req.filters)
        .then((tarifas) => {
          commit("getAllTarifasPartnerSuccess", tarifas);
        })
        .catch((error) => {
          commit("getAllTarifasPartnerError", error);
        });
    },
    getTarifasPartnerById({ commit }, id) {
      commit("getTarifasPartnerByIdRequest");
      tarifaService
        .getTarifasPartnerById(id)
        .then((tarifas) => {
          commit("getTarifasPartnerByIdSuccess", tarifas);
        })
        .catch((error) => {
          commit("getTarifasPartnerByIdError", error);
        });
    },
    getAllTarifasPartnerAsExcel({ commit }, request) {
      commit("tarifasPartnerExcelRequest");
      tarifaService
        .getAllTarifasPartnerAsExcel(request)
        .then((excel) => {
          commit("tarifasPartnerExcelSuccess", excel);
        })
        .catch((error) => {
          commit("tarifasPartnerExcelError", error);
        });
    },
    eliminarTarifas({ commit }, requests) {
      commit("eliminarRequest");
      tarifaService
        .eliminarTarifas(requests)
        .then((suplemento) => {
          commit("eliminarSuccess", suplemento);
        })
        .catch((error) => {
          commit("eliminarError", error);
        });
    },
    getTarifasMaestros({ commit }, req) {
      commit("getTarifasMaestrosRequest");
      tarifaService
        .getTarifasMaestros(req.pagination, req.filters)
        .then((response) => {
          commit("getTarifasMaestrosSuccess", response);
        })
        .catch((error) => {
          commit("getTarifasMaestrosError", error);
        });
    },
    editNombreTarifa({ commit }, req) {
      commit("editNombreTarifaRequest");
      tarifaService
        .editNombreTarifa(req.idTarifa, req.body)
        .then((response) => {
          commit("editNombreTarifaSuccess", response);
        })
        .catch((error) => {
          commit("editNombreTarifaError", error);
        });
    },
    getTarifaMaestroById({ commit }, id) {
      commit("getTarifaMaestroByIdRequest");
      tarifaService
        .getTarifaMaestroById(id)
        .then((response) => {
          commit("getTarifaMaestroByIdSuccess", response);
        })
        .catch((error) => {
          commit("getTarifaMaestroByIdError", error);
        });
    },
  },
};
