import { franquiciaService } from "@/_services";

export const franquicia = {
  namespaced: true,
  state: {
    integracion: {
      data: [],
      status: {},
    },
    datFranquiciaAdicional: {
      data: [],
      status: {},
    },
    newFranquicia: {
      data: [],
      status: {},
    },
    newConfiguracionFranquicia: {
      data: [],
      status: {},
    },
    updateConfiguracionFranquicia: {
      data: [],
      status: {},
    },
    changeStateFranquicia: {
      data: [],
      status: {},
    },
    list: {
      data: [],
      status: {},
    },
    franquiciaById: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    dirHabit: {
      data: null,
      status: {},
    },
    delDirecc: {
      data: null,
      status: {},
    },
    cuenta: {
      data: null,
      status: {},
    },
    banner: {
      data: {},
      status: {},
    },
    deleteBanner: {
      data: {},
      status: {},
    },
    postBanner: {
      data: {},
      status: {},
    },
  },
  mutations: {
    integracionClienteRequest(state) {
      state.integracion.status = {
        editing: true,
      };
    },
    integracionClienteError(state, error) {
      state.integracion.data = null;
      state.integracion.status = {
        error,
      };
    },
    integracionClienteSuccess(state, integracionCliente) {
      state.integracion.data = integracionCliente;
      state.integracion.status = {
        edited: true,
      };
    },
    getFranquiciaDataAdicionalRequest(state) {
      state.datFranquiciaAdicional.status = {
        loading: true,
      };
    },
    cancelFranquiciaDataAdicional(state, error) {
      state.datFranquiciaAdicional.data = [];
      state.datFranquiciaAdicional.status = {
        error,
      };
    },
    getFranquiciaDataAdicionalSuccess(state, franquicia) {
      state.datFranquiciaAdicional.data = franquicia;
      state.datFranquiciaAdicional.status = {
        loaded: true,
      };
    },
    editFranquiciaRequest(state) {
      state.update.status = {
        editing: true,
      };
    },
    editFranquiciaError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    editFranquiciaSuccess(state, franquiciaEditada) {
      state.update.data = franquiciaEditada;
      state.update.status = {
        edited: true,
      };
    },
    newFranquiciaRequest(state) {
      state.newFranquicia.status = {
        creating: true,
      };
    },
    newFranquiciaError(state, error) {
      state.newFranquicia.data = null;
      state.newFranquicia.status = {
        error,
      };
    },
    newFranquiciaSuccess(state, franquiciaCreada) {
      state.newFranquicia.data = franquiciaCreada;
      state.newFranquicia.status = {
        created: true,
      };
    },
    newConfiguracionFranquiciaRequest(state) {
      state.newConfiguracionFranquicia.status = {
        creating: true,
      };
    },
    newConfiguracionFranquiciaError(state, error) {
      state.newConfiguracionFranquicia.data = null;
      state.newConfiguracionFranquicia.status = {
        error,
      };
    },
    newConfiguracionFranquiciaSuccess(state, franquiciaCreada) {
      state.newConfiguracionFranquicia.data = franquiciaCreada;
      state.newConfiguracionFranquicia.status = {
        created: true,
      };
    },
    updateConfiguracionFranquiciaRequest(state) {
      state.updateConfiguracionFranquicia.status = {
        updating: true,
      };
    },
    updateConfiguracionFranquiciaError(state, error) {
      state.updateConfiguracionFranquicia.data = null;
      state.updateConfiguracionFranquicia.status = {
        error,
      };
    },
    updateConfiguracionFranquiciaSuccess(state, franquiciaCreada) {
      state.updateConfiguracionFranquicia.data = franquiciaCreada;
      state.updateConfiguracionFranquicia.status = {
        updated: true,
      };
    },
    getFranquiciaRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelFranquiciaRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getFranquiciaSuccess(state, franquicia) {
      state.list.data = franquicia;
      state.list.status = {
        loaded: true,
      };
    },
    changeStateFranquiciaRequest(state) {
      state.changeStateFranquicia.status = {
        loading: true,
      };
    },
    changeStateFranquiciaError(state, error) {
      state.changeStateFranquicia.data = null;
      state.changeStateFranquicia.status = {
        error,
      };
    },
    changeStateFranquiciaSuccess(state, franquicia) {
      state.changeStateFranquicia.data = franquicia;
      state.changeStateFranquicia.status = {
        loaded: true,
      };
    },
    getDirHabitByFranquiciaRequest(state) {
      state.dirHabit.status = {
        loading: true,
      };
    },
    cancelDirHabitByFranquiciaRequest(state, error) {
      state.dirHabit.data = [];
      state.dirHabit.status = {
        error,
      };
    },
    getDirHabitByFranquiciaSuccess(state, franquicia) {
      state.dirHabit.data = franquicia;
      state.dirHabit.status = {
        loaded: true,
      };
    },
    getFranquiciasByIdRequest(state) {
      state.franquiciaById.status = {
        loading: true,
      };
    },
    cancelFranquiciasByIdRequest(state, error) {
      state.franquiciaById.data = [];
      state.franquiciaById.status = {
        error,
      };
    },
    getFranquiciasByIdSuccess(state, franquicia) {
      state.franquiciaById.data = franquicia;
      state.franquiciaById.status = {
        loaded: true,
      };
    },
    cuentaFranquiciaRequest(state) {
      state.cuenta.status = {
        loading: true,
      };
    },
    cuentaFranquiciaError(state, error) {
      state.cuenta.data = [];
      state.cuenta.status = {
        error,
      };
    },
    cuentaFranquiciaSuccess(state, cuentaFranquicia) {
      state.cuenta.data = cuentaFranquicia;
      state.cuenta.status = {
        loaded: true,
      };
    },
    getBannerFacturaRequest(state) {
      state.banner.status = {
        loading: true,
      };
    },
    getBannerFacturaError(state, error) {
      state.banner.data = [];
      state.banner.status = {
        error,
      };
    },
    getBannerFacturaSuccess(state, bannerFranquicia) {
      state.banner.data = bannerFranquicia;
      state.banner.status = {
        loaded: true,
      };
    },
    deleteBannerFacturaRequest(state) {
      state.deleteBanner.status = {
        deleting: true,
      };
    },
    deleteBannerFacturaError(state, error) {
      state.deleteBanner.data = [];
      state.deleteBanner.status = {
        error,
      };
    },
    deleteBannerFacturaSuccess(state, response) {
      state.deleteBanner.data = response;
      state.deleteBanner.status = {
        deleted: true,
      };
    },
    postBannerFacturaRequest(state) {
      state.postBanner.status = {
        uploading: true,
      };
    },
    postBannerFacturaError(state, error) {
      state.postBanner.data = [];
      state.postBanner.status = {
        error,
      };
    },
    postBannerFacturaSuccess(state, response) {
      state.postBanner.data = response;
      state.postBanner.status = {
        uploaded: true,
      };
    },
  },
  actions: {
    integracionCliente({ commit }, body) {
      commit("integracionClienteRequest");
      franquiciaService
        .integracionCliente(body)
        .then((Res) => {
          commit("integracionClienteSuccess", Res);
        })
        .catch((error) => {
          commit("integracionClienteError", error);
        });
    },
    getDataFranquiciaAdicional({ commit }, franquiciaId) {
      commit("getFranquiciaDataAdicionalRequest");
      franquiciaService
        .getDataFranquiciaAdicional(franquiciaId)
        .then((franquicias) => {
          commit("getFranquiciaDataAdicionalSuccess", franquicias);
        })
        .catch((error) => {
          commit("cancelFranquiciaDataAdicionalRequest", error);
        });
    },
    updateFranquicia({ commit }, body) {
      commit("editFranquiciaRequest");
      franquiciaService
        .updateFranquicia(body)
        .then((Res) => {
          commit("editFranquiciaSuccess", Res);
        })
        .catch((error) => {
          commit("editFranquiciaError", error);
        });
    },
    newFranquicia({ commit }, body) {
      commit("newFranquiciaRequest");
      franquiciaService
        .newFranquicia(body)
        .then((Res) => {
          commit("newFranquiciaSuccess", Res);
        })
        .catch((error) => {
          commit("newFranquiciaError", error);
        });
    },
    newConfiguracionFranquicia({ commit }, body) {
      commit("newConfiguracionFranquiciaRequest");
      franquiciaService
        .newConfiguracionFranquicia(body)
        .then((Res) => {
          commit("newConfiguracionFranquiciaSuccess", Res);
        })
        .catch((error) => {
          commit("newConfiguracionFranquiciaError", error);
        });
    },
    updateConfiguracionFranquicia({ commit }, body) {
      commit("updateConfiguracionFranquiciaRequest");
      franquiciaService
        .updateConfiguracionFranquicia(body)
        .then((Res) => {
          commit("updateConfiguracionFranquiciaSuccess", Res);
        })
        .catch((error) => {
          commit("updateConfiguracionFranquiciaError", error);
        });
    },
    getFranquiciasById({ commit }, idFranquicia) {
      commit("getFranquiciasByIdRequest");
      franquiciaService
        .getFranquiciasById(idFranquicia)
        .then((franquicias) => {
          commit("getFranquiciasByIdSuccess", franquicias);
        })
        .catch((error) => {
          commit("cancelFranquiciasByIdRequest", error);
        });
    },
    getFranquicias({ commit }, req) {
      commit("getFranquiciaRequest");
      franquiciaService
        .getFranquicias(req.pagination, req.filters)
        .then((franquicias) => {
          commit("getFranquiciaSuccess", franquicias);
        })
        .catch((error) => {
          commit("cancelFranquiciaRequest", error);
        });
    },
    activarDesactivarFranquicia({ commit }, user) {
      commit("changeStateFranquiciaRequest");
      franquiciaService
        .activarDesactivarFranquicia(user)
        .then((usuario) => {
          commit("changeStateFranquiciaSuccess", usuario);
        })
        .catch((error) => {
          commit("changeStateFranquiciaError", error);
        });
    },
    cuentaFranquicia({ commit }, request) {
      commit("cuentaFranquiciaRequest");
      franquiciaService
        .cuentaFranquicia(request)
        .then((cuenta) => {
          commit("cuentaFranquiciaSuccess", cuenta);
        })
        .catch((error) => {
          commit("cuentaFranquiciaError", error);
        });
    },
    getBannerFactura({ commit }, id) {
      commit("getBannerFacturaRequest");
      franquiciaService
        .getBannerFactura(id)
        .then((response) => {
          commit("getBannerFacturaSuccess", response);
        })
        .catch((error) => {
          commit("getBannerFacturaError", error);
        });
    },
    deleteBannerFactura({ commit }, id) {
      commit("deleteBannerFacturaRequest");
      franquiciaService
        .deleteBannerFactura(id)
        .then((response) => {
          commit("deleteBannerFacturaSuccess", response);
        })
        .catch((error) => {
          commit("deleteBannerFacturaError", error);
        });
    },
    postBannerFactura({ commit }, req) {
      commit("postBannerFacturaRequest");
      franquiciaService
        .postBannerFactura(req.id, req.body)
        .then((response) => {
          commit("postBannerFacturaSuccess", response);
        })
        .catch((error) => {
          commit("postBannerFacturaError", error);
        });
    },
  },
};
