import { paisService } from '@/_services';

export const pais = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    listInternacionales: {
      data: [],
      status: {},
    }
  },
  mutations: {
    getPaisesRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    cancelPaisesRequest(state, error) {
      state.list.data = [];
      state.list.status = {
        error,
      };
    },
    getPaisesSuccess(state, paises) {
      state.list.data = paises;
      state.list.status = {
        loaded: true,
      };
    },
    getPaisesInternacionalesRequest(state) {
      state.listInternacionales.status = {
        loading: true,
      };
    },
    cancelPaisesInternacionalesRequest(state, error) {
      state.listInternacionales.data = [];
      state.listInternacionales.status = {
        error,
      };
    },
    getPaisesInternacionalesSuccess(state, paises) {
      state.listInternacionales.data = paises;
      state.listInternacionales.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getPaisesNacionales({ commit }, filters) {
      commit('getPaisesRequest');
      paisService
        .getPaises(filters)
        .then((paises) => {
          commit('getPaisesSuccess', paises);
        })
        .catch((error) => {
          commit('cancelPaisesRequest', error);
        });
    },
    getPaisesInternacionales({ commit }, filters) {
      commit('getPaisesInternacionalesRequest');
      paisService
        .getPaises(filters)
        .then((paises) => {
          commit('getPaisesInternacionalesSuccess', paises);
        })
        .catch((error) => {
          commit('cancelPaisesInternacionalesRequest', error);
        });
    },
    getPaises({ commit }, filters) {
      commit('getPaisesRequest');
      paisService
        .getPaises(filters)
        .then((paises) => {
          commit('getPaisesSuccess', paises);
        })
        .catch((error) => {
          commit('cancelPaisesRequest', error);
        });
    },
  },
};

