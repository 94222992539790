var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("auth-layout", { attrs: { pageClass: "lock-page" } }, [
    _c("div", { staticClass: "content" }, [
      _c("form", { attrs: { method: "#", action: "#" } }, [
        _c(
          "div",
          { staticClass: "user-profile" },
          [
            _c("div", { staticClass: "author" }, [
              _c("img", {
                staticClass: "avatar",
                attrs: { src: "static/img/default-avatar.png", alt: "..." },
              }),
            ]),
            _c("h4", [_vm._v("Tania Andrew")]),
            _c("fg-input", {
              attrs: { type: "password", placeholder: "Enter Password" },
            }),
            _c(
              "button",
              {
                staticClass: "btn btn-round btn-info btn-wd",
                attrs: { type: "button" },
              },
              [_vm._v("Unlock")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }