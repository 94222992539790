import { agrupacionesFranquiciaService } from "@/_services";
export const agrupacionesFranquicia = {
  namespaced: true,
  state: {
    list: {
      data: [],
      status: {},
    },
    entity: {
      data: [],
      status: {},
    },
    update: {
      data: [],
      status: {},
    },
    post: {
      data: [],
      status: {},
    },
  },
  mutations: {
    getAgrupacionesFranquiciasRequest(state) {
      state.list.status = {
        loading: true,
      };
    },
    getAgrupacionesFranquiciasSuccess(state, response) {
      state.list.data = response;
      state.list.status = {
        loaded: true,
      };
    },
    getAgrupacionesFranquiciasError(state, error) {
      state.list.data = null;
      state.list.status = {
        error,
      };
    },
    postAgrupacionFranquiciaRequest(state) {
      state.post.status = {
        creating: true,
      };
    },
    postAgrupacionFranquiciaError(state, error) {
      state.post.data = null;
      state.post.status = {
        error,
      };
    },
    postAgrupacionFranquiciaSuccess(state, response) {
      state.post.data = response;
      state.post.status = {
        created: true,
      };
    },
    updateAgrupacionFranquiciaRequest(state) {
      state.update.status = {
        updating: true,
      };
    },
    updateAgrupacionFranquiciaError(state, error) {
      state.update.data = null;
      state.update.status = {
        error,
      };
    },
    updateAgrupacionFranquiciaSuccess(state, response) {
      state.update.data = response;
      state.update.status = {
        updated: true,
      };
    },
    getAgrupacionFranquiciaByIdRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getAgrupacionFranquiciaByIdError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getAgrupacionFranquiciaByIdSuccess(state, response) {
      state.entity.data = response;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getAgrupacionesFranquicias({ commit }, request) {
      commit("getAgrupacionesFranquiciasRequest");
      agrupacionesFranquiciaService
        .getAgrupacionesFranquicias(request.pagination, request.filters)
        .then((response) => {
          commit("getAgrupacionesFranquiciasSuccess", response);
        })
        .catch((error) => {
          commit("getAgrupacionesFranquiciasError", error);
        });
    },
    postAgrupacionFranquicia({ commit }, request) {
      commit("postAgrupacionFranquiciaRequest");
      agrupacionesFranquiciaService
        .postAgrupacionFranquicia(request)
        .then((res) => {
          commit("postAgrupacionFranquiciaSuccess", res);
        })
        .catch((error) => {
          commit("postAgrupacionFranquiciaError", error);
        });
    },
    updateAgrupacionFranquicia({ commit }, request) {
      commit("updateAgrupacionFranquiciaRequest");
      agrupacionesFranquiciaService
        .updateAgrupacionFranquicia(request.id, request.body)
        .then((res) => {
          commit("updateAgrupacionFranquiciaSuccess", res);
        })
        .catch((error) => {
          commit("updateAgrupacionFranquiciaError", error);
        });
    },
    getAgrupacionFranquiciaById({ commit }, id) {
      commit("getAgrupacionFranquiciaByIdRequest");
      agrupacionesFranquiciaService
        .getAgrupacionFranquiciaById(id)
        .then((res) => {
          commit("getAgrupacionFranquiciaByIdSuccess", res);
        })
        .catch((error) => {
          commit("getAgrupacionFranquiciaByIdError", error);
        });
    },
  },
};
