<template>
  <filter-base :widthFilter="600" ref="filtroBase">
    <template v-slot:body>
      <el-form
        label-position="top"
        :model="localValue"
        ref="dataFilter"
        class="px-4 py-3"
      >
        <el-form-item class="input-class" prop="codigo">
          <el-input v-model="localValue.estadoMobile" placeholder="Código Mobile"></el-input>
        </el-form-item>
        <el-form-item class="input-class" prop="codigo">
          <el-input v-model="localValue.estado" placeholder="Código Estado"></el-input>
        </el-form-item>       
        <el-form-item align="right">
          <el-button type="primary" @click="search()">Buscar</el-button>
          <el-button @click="clearFilters()">Limpiar</el-button>
        </el-form-item>
      </el-form>
    </template>
  </filter-base>
</template>
<script>
import FilterBase from "../../../../components/FilterBase.vue";
export default {
  components: { FilterBase },
  props: {
    value: {
      required: true,
      default: () => {
        return {
          estado: "",
          estadoMobile: "",
        };
      },
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    clearFilters() {
      this.localValue = {
        estado: "",
        estadoMobile: "",
      };
      this.$emit("onFilterChange", this.localValue);
      this.closeFiltro();
    },
    search() {
      this.$emit("onFilterChange", this.localValue);
      this.closeFiltro();
    },
    closeFiltro() {
      this.$refs.filtroBase.closeFiltro();
    },
  },
  watch: {},
};
</script>