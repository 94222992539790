import createAxios from './axiosHttpMarconi';
export const estadoService = { getEstados };

function getEstados(tipo) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem('user'));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/estado`;
  if (tipo) {
    urlPath += `?tipo=${tipo}`
  }
  urlPath += `&sort=_id&order=ascending`
  return axios.get(urlPath);
}