import { partnerService } from "@/_services";

export const partner = {
  namespaced: true,
  state: {
    entity: {
      data: {},
      status: {},
    },
    oficinas: {
      data: [],
      status: {},
    },
    solucion: {
      data: {},
      status: {},
    },
    allPartners: {
      data: {},
      status: {},
    },
  },
  mutations: {
    getOficinasRequest(state) {
      state.oficinas.status = {
        loading: true,
      };
    },
    getOficinasError(state, error) {
      state.oficinas.data = null;
      state.oficinas.status = {
        error,
      };
    },
    getOficinasSuccess(state, list) {
      state.oficinas.data = list;
      state.oficinas.status = {
        loaded: true,
      };
    },

    getPartnersRequest(state) {
      state.allPartners.status = {
        loading: true,
      };
    },
    getPartnersError(state, error) {
      state.allPartners.data = null;
      state.allPartners.status = {
        error,
      };
    },
    getPartnersSuccess(state, list) {
      state.allPartners.data = list;
      state.allPartners.status = {
        loaded: true,
      };
    },

    partnerSolucionRequest(state) {
      state.solucion.status = {
        solving: true,
      };
    },
    partnerSolucionError(state, error) {
      state.solucion.data = null;
      state.solucion.status = {
        error,
      };
    },
    partnerSolucionSuccess(state, res) {
      state.solucion.data = res;
      state.solucion.status = {
        solved: true,
      };
    },
    getPartnerByCodeRequest(state) {
      state.entity.status = {
        loading: true,
      };
    },
    getPartnerByCodeError(state, error) {
      state.entity.data = null;
      state.entity.status = {
        error,
      };
    },
    getPartnerByCodeSuccess(state, res) {
      state.entity.data = res;
      state.entity.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getOficinas({ commit }, filters) {
      commit("getOficinasRequest");
      partnerService
        .getOficinas(filters)
        .then((list) => {
          commit("getOficinasSuccess", list);
        })
        .catch((error) => {
          commit("getOficinasError", error);
        });
    },
    getAllPartners({ commit }) {
      commit("getPartnersRequest");
      partnerService
        .getAllPartners()
        .then((list) => {
          commit("getPartnersSuccess", list);
        })
        .catch((error) => {
          commit("getPartnersError", error);
        });
    },
    partnerSolucion({ commit }, req) {
      commit("partnerSolucionRequest");
      partnerService
        .partnerSolucion(req.id, req.body)
        .then((res) => {
          commit("partnerSolucionSuccess", res);
        })
        .catch((error) => {
          commit("partnerSolucionError", error);
        });
    },
    getPartnerByCode({ commit }, code) {
      commit("getPartnerByCodeRequest");
      partnerService
        .getPartnerByCode(code)
        .then((res) => {
          commit("getPartnerByCodeSuccess", res);
        })
        .catch((error) => {
          commit("getPartnerByCodeError", error);
        });
    },
  },
};
