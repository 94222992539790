var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-6" },
      [
        _c(
          "chart-card",
          {
            attrs: {
              "chart-data": _vm.salesChart.data,
              "chart-options": _vm.salesChart.options,
            },
          },
          [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c("h4", { staticClass: "card-title" }, [_vm._v("2015 Sales")]),
              _c("p", { staticClass: "category" }, [
                _vm._v("All products including Taxes"),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "stats",
                attrs: { slot: "footer-title" },
                slot: "footer-title",
              },
              [
                _c("i", { staticClass: "ti-check" }),
                _vm._v(" Data information certified "),
              ]
            ),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c("div", { staticClass: "chart-legend" }, [
                _c("i", { staticClass: "fa fa-circle text-info" }),
                _vm._v(" Tesla Model S "),
                _c("i", { staticClass: "fa fa-circle text-warning" }),
                _vm._v(" BMW 5 Series "),
              ]),
              _c("hr"),
              _c("div", { staticClass: "stats" }, [
                _c("i", { staticClass: "ti-check" }),
                _vm._v(" Data information certified "),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-md-6" },
      [
        _c(
          "chart-card",
          {
            attrs: {
              "chart-data": _vm.usersChart.data,
              "chart-options": _vm.usersChart.options,
            },
          },
          [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Users Behavior"),
              ]),
              _c("p", { staticClass: "category" }, [
                _vm._v("24 hours performance"),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "stats",
                attrs: { slot: "footer-title" },
                slot: "footer-title",
              },
              [
                _c("i", { staticClass: "ti-reload" }),
                _vm._v(" Updated 3 minutes ago "),
              ]
            ),
            _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
              _c("div", { staticClass: "chart-legend" }, [
                _c("i", { staticClass: "fa fa-circle text-info" }),
                _vm._v(" Open "),
                _c("i", { staticClass: "fa fa-circle text-danger" }),
                _vm._v(" Click "),
                _c("i", { staticClass: "fa fa-circle text-warning" }),
                _vm._v(" Click Second Time "),
              ]),
              _c("hr"),
              _c("div", { staticClass: "stats" }, [
                _c("i", { staticClass: "ti-reload" }),
                _vm._v(" Updated 3 minutes ago "),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-lg-6 col-sm-6" },
      [
        _c(
          "chart-card",
          {
            attrs: {
              "chart-data": _vm.subscriptionsChart.data,
              "chart-options": _vm.subscriptionsChart.options,
            },
          },
          [
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("169"),
            ]),
            _c(
              "span",
              {
                staticClass: "label label-danger",
                attrs: { slot: "title-label" },
                slot: "title-label",
              },
              [_vm._v(" -14% ")]
            ),
            _c("h6", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v(" total subscriptions "),
              _c("span", { staticClass: "text-muted" }, [_vm._v("in last")]),
              _vm._v(" 7 days "),
            ]),
            _c(
              "div",
              { attrs: { slot: "footer-title" }, slot: "footer-title" },
              [_vm._v(" View all members ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default btn-fill btn-icon btn-sm",
                attrs: { slot: "footer-right" },
                slot: "footer-right",
              },
              [_c("i", { staticClass: "ti-angle-right" })]
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-lg-6 col-sm-6" },
      [
        _c(
          "chart-card",
          {
            attrs: {
              "chart-data": _vm.downloadsChart.data,
              "chart-options": _vm.downloadsChart.options,
            },
          },
          [
            _c("span", { attrs: { slot: "title" }, slot: "title" }, [
              _vm._v("8,960"),
            ]),
            _c(
              "span",
              {
                staticClass: "label label-warning",
                attrs: { slot: "title-label" },
                slot: "title-label",
              },
              [_vm._v(" ~51% ")]
            ),
            _c("h6", { attrs: { slot: "subtitle" }, slot: "subtitle" }, [
              _vm._v(" total downloads "),
              _c("span", { staticClass: "text-muted" }, [_vm._v("in last")]),
              _vm._v(" 6 years "),
            ]),
            _c(
              "div",
              { attrs: { slot: "footer-title" }, slot: "footer-title" },
              [_vm._v(" View more details ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-success btn-fill btn-icon btn-sm",
                attrs: { slot: "footer-right" },
                slot: "footer-right",
              },
              [_c("i", { staticClass: "ti-info" })]
            ),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "col-md-6" }, [_c("task-list"), _vm._m(0)], 1),
    _c(
      "div",
      { staticClass: "col-md-6" },
      [
        _c(
          "time-line",
          { attrs: { type: "simple" } },
          [
            _c(
              "time-line-item",
              {
                staticClass: "timeline-inverted",
                attrs: { "badge-type": "danger", badgeIcon: "ti-gallery" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "label label-danger",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_vm._v("Some title")]
                ),
                _c("p", { attrs: { slot: "body" }, slot: "body" }, [
                  _vm._v(
                    " Wifey made the best Father's Day meal ever. So thankful so happy so blessed. Thank you for making my family We just had fun with the “future” theme !!! It was a fun night all together ... The always rude Kanye Show at 2am Sold Out Famous viewing @ Figueroa and 12th in downtown."
                  ),
                ]),
                _c("h6", { attrs: { slot: "footer" }, slot: "footer" }, [
                  _c("i", { staticClass: "ti-time" }),
                  _vm._v(" 11 hours ago via Twitter "),
                ]),
              ]
            ),
            _c(
              "time-line-item",
              {
                staticClass: "timeline-inverted",
                attrs: { "badge-type": "success", badgeIcon: "ti-credit-card" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "label label-info",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_vm._v("Another Title")]
                ),
                _c("p", { attrs: { slot: "body" }, slot: "body" }, [
                  _vm._v(
                    " Thank God for the support of my wife and real friends. I also wanted to point out that it’s the first album to go number 1 off of streaming!!! I love you Ellen and also my number one design rule of anything I do from shoes to music to homes is that Kim has to like it...."
                  ),
                ]),
              ]
            ),
            _c(
              "time-line-item",
              {
                staticClass: "timeline-inverted",
                attrs: { "badge-type": "info", badgeIcon: "ti-check-box" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "label label-success",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_vm._v("Another Title")]
                ),
                _c(
                  "div",
                  { attrs: { slot: "body" }, slot: "body" },
                  [
                    _c("p", [
                      _vm._v(
                        " Called I Miss the Old Kanye That’s all it was Kanye And I love you like Kanye loves Kanye Famous viewing @ Figueroa and 12th in downtown LA 11:10PM"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " What if Kanye made a song about Kanye Royère doesn't make a Polar bear bed but the Polar bear couch is my favorite piece of furniture we own It wasn’t any Kanyes Set on his goals Kanye"
                      ),
                    ]),
                    _c("hr"),
                    _c("drop-down", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info dropdown-toggle",
                          attrs: {
                            slot: "title",
                            type: "button",
                            "data-toggle": "dropdown",
                          },
                          slot: "title",
                        },
                        [
                          _c("i", { staticClass: "ti-settings" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "caret" }),
                        ]
                      ),
                      _c("li", [
                        _c("a", { attrs: { href: "#action" } }, [
                          _vm._v("Action"),
                        ]),
                      ]),
                      _c("li", [
                        _c("a", { attrs: { href: "#action" } }, [
                          _vm._v("Another action"),
                        ]),
                      ]),
                      _c("li", [
                        _c("a", { attrs: { href: "#here" } }, [
                          _vm._v("Something else here"),
                        ]),
                      ]),
                      _c("li", { staticClass: "divider" }),
                      _c("li", [
                        _c("a", { attrs: { href: "#link" } }, [
                          _vm._v("Separated link"),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card card-chat" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("h4", { staticClass: "card-title" }, [_vm._v("Chat")]),
        _c("p", { staticClass: "category" }, [_vm._v("With Tania Andrew")]),
      ]),
      _c("div", { staticClass: "card-content" }, [
        _c("ol", { staticClass: "chat" }, [
          _c("li", { staticClass: "other" }, [
            _c("div", { staticClass: "avatar" }, [
              _c("img", {
                attrs: { src: "static/img/faces/face-2.jpg", alt: "crash" },
              }),
            ]),
            _c("div", { staticClass: "msg" }, [
              _c("p", [_vm._v(" Hola! How are you? ")]),
              _c("div", { staticClass: "card-footer" }, [
                _c("i", { staticClass: "ti-check" }),
                _c("h6", [_vm._v("11:20")]),
              ]),
            ]),
          ]),
          _c("li", { staticClass: "self" }, [
            _c("div", { staticClass: "msg" }, [
              _c("p", [_vm._v(" Puff... I'm alright. How are you? ")]),
              _c("div", { staticClass: "card-footer" }, [
                _c("i", { staticClass: "ti-check" }),
                _c("h6", [_vm._v("11:22")]),
              ]),
            ]),
            _c("div", { staticClass: "avatar" }, [
              _c("img", {
                attrs: { src: "static/img/default-avatar.png", alt: "crash" },
              }),
            ]),
          ]),
          _c("li", { staticClass: "other" }, [
            _c("div", { staticClass: "avatar" }, [
              _c("img", {
                attrs: { src: "static/img/faces/face-2.jpg", alt: "crash" },
              }),
            ]),
            _c("div", { staticClass: "msg" }, [
              _c("p", [_vm._v(" I'm ok too! ")]),
              _c("div", { staticClass: "card-footer" }, [
                _c("i", { staticClass: "ti-check" }),
                _c("h6", [_vm._v("11:24")]),
              ]),
            ]),
          ]),
          _c("li", { staticClass: "self" }, [
            _c("div", { staticClass: "msg" }, [
              _c("p", [_vm._v(" Well, it was nice hearing from you. ")]),
              _c("div", { staticClass: "card-footer" }, [
                _c("i", { staticClass: "ti-check" }),
                _c("h6", [_vm._v("11:25")]),
              ]),
            ]),
            _c("div", { staticClass: "no-avatar" }),
          ]),
          _c("li", { staticClass: "self" }, [
            _c("div", { staticClass: "msg" }, [
              _c("p", [_vm._v(" OK, bye-bye See you! ")]),
              _c("div", { staticClass: "card-footer" }, [
                _c("i", { staticClass: "ti-check" }),
                _c("h6", [_vm._v("11:25")]),
              ]),
            ]),
            _c("div", { staticClass: "avatar" }, [
              _c("img", {
                attrs: { src: "static/img/default-avatar.png", alt: "crash" },
              }),
            ]),
          ]),
        ]),
        _c("hr"),
        _c("div", { staticClass: "send-message" }, [
          _c("div", { staticClass: "avatar" }, [
            _c("img", {
              attrs: { src: "static/img/default-avatar.png", alt: "crash" },
            }),
          ]),
          _c("input", {
            staticClass: "form-control textarea",
            attrs: { type: "text", placeholder: "Type here!" },
          }),
          _c("div", { staticClass: "send-button" }, [
            _c("button", { staticClass: "btn btn-primary btn-fill" }, [
              _vm._v("Send"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }