import createAxios from "./axiosHttpMarconi";

function getValoracion(valoracionId) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `franquiciadora/${user.extraData.franquiciadoraId}/valoracion/${valoracionId}`;
  return axios.get(urlPath);
}
function getValoraciones(pagination, filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/valoracion/control`;
  if (pagination) {
    urlPath += `?page=${pagination.currentPage - 1}&limit=${
      pagination.perPage
    }`;
  } else {
    urlPath += "?page=0&limit=20";
  }

  urlPath += getPathFilters(filters);
  return axios.get(urlPath);
}

function getPathFilters(filters) {
  let urlPath = "";
  if (filters && filters.date) {
    urlPath += `&fechaDesde=${filters.date[0]}`;
    urlPath += `&fechaHasta=${filters.date[1]}`;
  }

  if (filters && filters.localizador) {
    urlPath += `&localizador=${filters.localizador}`;
  }

  if (filters && filters.idCliente) {
    urlPath += `&idCliente=${filters.idCliente}`;
  }

  if (filters && filters.idFranquicia) {
    urlPath += `&idFranquicia=${filters.idFranquicia}`;
  }

  if (filters && filters.nombre) {
    urlPath += `&nombre=${filters.nombre}`;
  }

  if (filters && filters.estados && filters.estados.length > 0) {
    urlPath += `&estados=${filters.estados.join()}`;
  }
  if (filters && filters.estadoEnvio && filters.estadoEnvio.length > 0) {
    urlPath += `&estadoEnvio=${filters.estadoEnvio.join()}`;
  }

  if (filters && filters.porcentaje) {
    urlPath += `&porcentaje=${filters.porcentaje}`;
  }

  if (filters && filters.sort && filters.sort.sort) {
    urlPath += `&sort=${filters.sort.sort}`;
  }

  if (filters && filters.sort && filters.sort.order) {
    urlPath += `&order=${filters.sort.order}`;
  }
  return urlPath;
}

function revalorar(body) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));
  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/valoracion/control`;
  return axios.post(urlPath, body);
}
function getValoracionesAsExcel(filters) {
  const axios = createAxios();
  const user = JSON.parse(localStorage.getItem("user"));

  let urlPath = `/franquiciadora/${user.extraData.franquiciadoraId}/valoracion/excel?limit=2000`;
  urlPath += getPathFilters(filters);
  return axios.get(urlPath, {
    responseType: "blob",
  });
}

export const valoracionService = {
  getValoracion,
  getValoraciones,
  revalorar,
  getValoracionesAsExcel,
};
