<template>
  <div class="container-box ps-3 pe-3">
    <div class="row">
      <div class="col-12 p-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Integraciones</li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ $route.name }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <actions @triggerEvent="triggerEvent" :acciones="acciones" />
    </div>
    <div class="row pt-2">
      <div class="col-md-5 col-sm-8 p-0">
        <Pagination @paginationChange="paginationChange" v-model="pagination" />
      </div>
      <div class="col"></div>
      <div class="col-md-3 col-sm-2 p-0">
        <resumen-busqueda :filtrosProp="filtersLimpios" />
      </div>
      <div class="col-md-1 col-sm-8 p-0 d-flex flex-row-reverse">
        <filters @onFilterChange="onFilterChange" v-model="filters" />
      </div>
    </div>
    <div class="row pt-2">
      <el-table
        highlight-current-row
        class="table-servicios-seur"
        @current-change="handleSelectionChange"
        :data="allServiciosSeurs"
        ref="singleTable"
        v-loading="loading"
      >
        <el-table-column label="Servicio" prop="servicio" />
        <el-table-column label="Producto" prop="producto" />
        <el-table-column label="Servicio Seur" prop="nombreServicio" />
        <el-table-column label="Producto Seur" prop="nombreProducto" />
      </el-table>
    </div>
    <nuevo-servicio-seur
      v-if="showNuevoServiciosSeur"
      :transitoSeleccionado="selection"
      @hide="closeNuevoServicioSeur"
      @guardado="getServicioSeur"
    />
    <modal-destruccion
      v-if="showEliminarmodal"
      @accepted="aceptarEliminar"
      @hide="showEliminarmodal = false"
      :titulo="'Eliminar: ' + selection.servicio"
      :mensaje="messageEliminar"
    />
  </div>
</template>
<script>
import { Pagination } from "@/components";
import ResumenBusqueda from "@/components/ResumenBusqueda.vue";
import Filters from "./components/Filters.vue";
import Actions from "@/components/Actions.vue";
import NuevoServicioSeur from "./components/NuevoServicioSeur.vue";
import ModalDestruccion from "../../../components/ModalDestruccion.vue";
export default {
  components: {
    Pagination,
    ResumenBusqueda,
    Filters,
    Actions,
    NuevoServicioSeur,
    ModalDestruccion,
  },
  props: {},
  data() {
    return {
      messageEliminar: "",
      showEliminarmodal: false,
      showNuevoServiciosSeur: false,
      loading: true,
      allServiciosSeurs: null,
      selection: null,
      filters: {
        servicio: "",
        producto: "",
        nombreServicio: "",
        nombreProducto: "",
      },
      pagination: {
        actualPagina: 0,
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 30, 40, 50],
        totalPages: 0,
        totalItems: 0,
      },
      acciones: [
        {
          name: "nuevo",
          label: "Nuevo",
          evento: "onNuevo",
          icon: "fa-plus-circle",
          activo: true,
        },
        {
          name: "eliminar",
          label: "Eliminar",
          evento: "onEliminar",
          icon: "fa-times",
          activo: false,
        },
        {
          label: "Refrescar",
          evento: "onReloadTable",
          icon: "fa-rotate-right",
          activo: false,
        },
      ],
      events: {
        onNuevo: this.onNuevoServicioSeur,
        onReloadTable: this.getServicioSeur,
        onEliminar: this.onEliminarServicioSeur,
      },
    };
  },
  created() {},
  mounted() {
    this.getServicioSeur();
  },
  computed: {
    seurStatus() {
      return this.$store.state.servicioSeur.list.status;
    },
    deleteStatus() {
      return this.$store.state.servicioSeur.delete.status;
    },
    filtersLimpios() {
      return this.resumeFilters(JSON.parse(JSON.stringify(this.filters)));
    },
  },
  methods: {
    resumeFilters(filters) {
      let filtersCleaned = {};
      if (filters.servicio != "")
        filtersCleaned.servicio = filters.servicio;
      if (filters.producto != "")
        filtersCleaned.producto = filters.producto;
      if (filters.nombreServicio != "")
        filtersCleaned.nombreServicio = filters.nombreServicio;
      if (filters.nombreProducto != "")
        filtersCleaned.nombreProducto = filters.nombreProducto;
      return filtersCleaned;
    },
    onNuevoServicioSeur() {
      this.showNuevoServiciosSeur = true;
    },
    closeNuevoServicioSeur() {
      this.showNuevoServiciosSeur = false;
    },
    handleSelectionChange(val) {
      this.selection = val;
    },
    onFilterChange(value) {
      this.filters = value;
      localStorage.setItem(
        "filtersServiciosSeur",
        JSON.stringify(this.filters)
      );
      this.pagination.actualPagina -= this.pagination.currentPage + 1;
      this.pagination.currentPage = 1;
      setTimeout(() => {
        this.getServicioSeur();
      }, 250);
    },
    getServicioSeur() {
      this.$store.dispatch("servicioSeur/getServicioSeur", {
        pagination: this.pagination,
        query: this.resumeFilters(this.filters),
      });
      this.loading = true;
    },
    paginationChange(pagination) {
      this.pagination = pagination;
      this.getServicioSeur();
    },
    triggerEvent(eventKey) {
      this.events[eventKey]();
    },
    notificacion(titulo, msg, tipo) {
      this.$notify({
        title: titulo,
        message: msg,
        type: tipo,
      });
    },
    onEliminarServicioSeur() {
      if (this.selection != null) {
        this.messageEliminar = `¿Estás seguro de realizar esta acción?`;
        this.showEliminarmodal = true;
      } else {
        this.notificacion(
          "Advertencia",
          "Por favor seleccione un registro.",
          "warning"
        );
      }
    },
    aceptarEliminar() {
      this.$store.dispatch(
        "servicioSeur/deleteServicioSeur",
        this.selection._id
      );
      this.loading = true;
    },
  },
  watch: {
    seurStatus(val) {
      if (val.loaded) {
        if (this.$store.state.servicioSeur.list.data) {
          this.allServiciosSeurs =
            this.$store.state.servicioSeur.list.data.items;
          this.pagination.totalItems =
            this.$store.state.servicioSeur.list.data.total;
        }
        this.loading = false;
      }
    },
    deleteStatus(val) {
      if (val.erased) {
        this.notificacion(
          "Correcto!",
          "Se ha borrado correctamente!",
          "success"
        );
        this.showEliminarmodal = false;
        this.getServicioSeur();
      }
      if (val.error) {
        this.notificacion("Error!", val.error.error, "danger");
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.table-transito {
  padding-left: 0px;
  padding-right: 0px;
}

.table-transito .cell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.container-box {
  margin-top: -1.5rem;
  margin-bottom: -22px !important;
}
</style>