var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-box ps-3 pe-3" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 p-0" }, [
          _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
            _c("ol", { staticClass: "breadcrumb" }, [
              _c("li", { staticClass: "breadcrumb-item" }, [
                _vm._v("Integraciones"),
              ]),
              _c(
                "li",
                {
                  staticClass: "breadcrumb-item active",
                  attrs: { "aria-current": "page" },
                },
                [_vm._v(" " + _vm._s(_vm.$route.name) + " ")]
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("actions", {
            attrs: { acciones: _vm.acciones },
            on: { triggerEvent: _vm.triggerEvent },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "row pt-2" }, [
        _c(
          "div",
          { staticClass: "col-md-5 col-sm-8 p-0" },
          [
            _c("Pagination", {
              on: { paginationChange: _vm.paginationChange },
              model: {
                value: _vm.pagination,
                callback: function ($$v) {
                  _vm.pagination = $$v
                },
                expression: "pagination",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col" }),
        _c(
          "div",
          { staticClass: "col-md-3 col-sm-2 p-0" },
          [
            _c("resumen-busqueda", {
              attrs: { filtrosProp: _vm.filtersLimpios },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-1 col-sm-8 p-0 d-flex flex-row-reverse" },
          [
            _c("filters", {
              on: { onFilterChange: _vm.onFilterChange },
              model: {
                value: _vm.filters,
                callback: function ($$v) {
                  _vm.filters = $$v
                },
                expression: "filters",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "row pt-2" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "singleTable",
              staticClass: "table-servicios-seur",
              attrs: {
                "highlight-current-row": "",
                data: _vm.allServiciosSeurs,
              },
              on: { "current-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "Servicio", prop: "servicio" },
              }),
              _c("el-table-column", {
                attrs: { label: "Producto", prop: "producto" },
              }),
              _c("el-table-column", {
                attrs: { label: "Servicio Seur", prop: "nombreServicio" },
              }),
              _c("el-table-column", {
                attrs: { label: "Producto Seur", prop: "nombreProducto" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showNuevoServiciosSeur
        ? _c("nuevo-servicio-seur", {
            attrs: { transitoSeleccionado: _vm.selection },
            on: {
              hide: _vm.closeNuevoServicioSeur,
              guardado: _vm.getServicioSeur,
            },
          })
        : _vm._e(),
      _vm.showEliminarmodal
        ? _c("modal-destruccion", {
            attrs: {
              titulo: "Eliminar: " + _vm.selection.servicio,
              mensaje: _vm.messageEliminar,
            },
            on: {
              accepted: _vm.aceptarEliminar,
              hide: function ($event) {
                _vm.showEliminarmodal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }