var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    [
      _vm._t("badge", function () {
        return [
          _c("div", { staticClass: "timeline-badge", class: _vm.badgeType }, [
            _c("i", { class: _vm.badgeIcon }),
          ]),
        ]
      }),
      _c("div", { staticClass: "timeline-panel" }, [
        _c("div", { staticClass: "timeline-heading" }, [_vm._t("header")], 2),
        _c("div", { staticClass: "timeline-body" }, [_vm._t("body")], 2),
        _c("h6", [_vm._t("footer")], 2),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }