import { ficherosSorterService } from "@/_services";

export const ficherosSorter = {
  namespaced: true,
  state: {
    ficherosSorter: {
      data: {},
      status: {},
    },
    download: {
      data: {},
      status: {}
    }
  },
  mutations: {
    getFicherosSorterDownloadRequest(state) {
      state.download.status = {
        downloading: true,
      };
    },
    getFicherosSorterDownloadError(state, error) {
      state.download.data = {};
      state.download.status = {
        error,
      };
    },
    getFicherosSorterDownloadSuccess(state, response) {
      state.download.data = response;
      state.download.status = {
        downloaded: true,
      };
    },
    getFicherosSorterRequest(state) {
      state.ficherosSorter.status = {
        loading: true,
      };
    },
    getFicherosSorterError(state, error) {
      state.ficherosSorter.data = [];
      state.ficherosSorter.status = {
        error,
      };
    },
    getFicherosSorterSuccess(state, fechas) {
      state.ficherosSorter.data = fechas;
      state.ficherosSorter.status = {
        loaded: true,
      };
    },
  },
  actions: {
    getFicherosSorterDownload({ commit }, idFichero) {
      commit('getFicherosSorterDownloadRequest');
      ficherosSorterService
        .getFicherosSorterDownload(idFichero)
        .then((response) => {
          commit('getFicherosSorterDownloadSuccess', response);
        })
        .catch((error) => {
          commit('getFicherosSorterDownloadError', error);
        });
    },
    getFicherosSorter({ commit }, req) {
      commit("getFicherosSorterRequest");
      ficherosSorterService
        .getFicherosSorter(req.pagination, req.filters)
        .then((envios) => {
          commit("getFicherosSorterSuccess", envios);
        })
        .catch((error) => {
          commit("getFicherosSorterError", error);
        });
    },
  },
};
